<script lang="ts">
  import {onDestroy, onMount} from "svelte";

  import {
    serverlessRoutes,
    translate,
    postWithJwt,
    patchWithJwt,
    formStoreCreate,
    deleteWithJwt,
  } from "lib";

  import {
    alertStore,
    journalMealsStore,
    dialogData,
    mealPlanStore,
    recipesStore
  } from "stores";

  import {ButtonComponent, ImageInputComponent, InputField, TextareaField} from "ui";
  import {SetEditTypeComponent} from "components";
  import type {PartialItemMap, Recipe} from "interfaces";

  let data: any;
  let shouldDelete = false;
  let isUploading = false;

  const form = formStoreCreate({
    name: ["", "NAME"],
    foodType: [[], "NONE"],
    directions: ["", "DESCRIPTION"],
    fileHash: ["", "NONE"]
  });

  const title = data.recipe ? translate("EDIT") : translate("CREATE");

  const onTypes = (event: CustomEvent): void => {
    $form.field.foodType.value = event.detail;
  };

  const editRecipe = async (): Promise<void> => {
    const {recipe} = data;

    const partialRecipeMap: PartialItemMap<Recipe> = {
      [recipe.id]: {
        name: $form.field.name.value,
        foodType: $form.field.foodType.value,
        description: $form.field.directions.value || null,
        fileHash: $form.field.fileHash.value || null,
      },
    };

    const {data: rData, error} = await patchWithJwt(
      serverlessRoutes.RECIPE,
      {partialRecipeMap},
    );

    if (error && !rData) {
      $form.isLoading = false;
      alertStore.show(translate("ERROR_EDITING_RECIPE"), "error");
      return console.error(error);
    }

    const {recipes} = rData;
    const {id, isDefault} = recipe;

    if (isDefault) {
      recipesStore.replace(recipes);
      await recipesStore.fetchIngredients(id);
    } else {
      mealPlanStore.replaceMealRecipes(recipe.mealId, recipes);
      journalMealsStore.replaceMealRecipes(recipe.mealId, recipes);
    }

    alertStore.show(translate("SUCCESSFULLY_EDITED_RECIPE"));

    shouldDelete = false;

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const createRecipe = async (): Promise<void> => {
    const partialRecipe: Partial<Recipe> = {
      name: $form.field.name.value,
      foodType: $form.field.foodType.value,
      description: $form.field.directions.value || null,
      fileHash: $form.field.fileHash.value,
      isDefault: 1,
      isTemplate: 0,
      mealId: null,
    };

    const {data: rData, error} = await postWithJwt(
      serverlessRoutes.RECIPE,
      {partialRecipe}
    );

    if (error && !rData) {
      $form.isLoading = false;
      alertStore.show(translate("ERROR_CREATING_RECIPE"), "error");
      return console.error(error);
    }

    recipesStore.add([rData.recipe]);
    alertStore.show(translate("SUCCESSFULLY_CREATED_RECIPE"));

    shouldDelete = false;

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onSubmit = async (): Promise<void> => {
    $form.isLoading = true;

    if (data.recipe) {
      await editRecipe();
    } else {
      await createRecipe();
    }
  };

  let importedDeleteFn: any;

  onDestroy(async (): Promise<void> => {
    if (shouldDelete && $form.field.fileHash.value) {
      await importedDeleteFn();
    }
  });

  onMount((): void => {
    if (data.recipe) {
      const {name, foodType, description, fileHash} = data.recipe;

      form.setValue("name", name);
      // form.setValue("foodType", foodType);
      form.setValue("directions", description || "");
      form.setValue("fileHash", fileHash);
    }
  });

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {title}
    {translate("RECIPE").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault="{onSubmit}">
    <InputField
      label={translate("NAME")}
      error={$form.field.name.error}
      bind:value={$form.field.name.value}
      on:input={() => form.validate("name")}
    />

    <ImageInputComponent
      isVideoAllowed
      requireSignedURLs="{false}"
      imageUrl={data?.recipe?.fileHash?.includes("imagedelivery") ? data.recipe.fileHash : ""}
      videoUrl={data?.recipe?.fileHash?.includes("cloudflarestream") ? data.recipe.fileHash : ""}
      bind:onDeleteFile={importedDeleteFn}
      bind:value={$form.field.fileHash.value}
      bind:shouldDelete
      bind:isUploading
    />

    <!-- <SetEditTypeComponent
      entity="recipe"
      selectedTypes={data?.recipe?.foodType || []}
      bind:value={$form.field.foodType.value}
      on:types={onTypes}
    /> -->

    <TextareaField
      label={translate("DIRECTIONS")}
      error={translate($form.field.directions.error)}
      bind:value={$form.field.directions.value}
      on:input={() => form.validate("directions")}
    />

    <div class="text-xxs text-justify">
      <span class="text-primary-500">{translate("NOTE")}:</span>
      {translate("CREATE_EDIT_INGREDIENT_NOTE")}
    </div>

    <div class="flex justify-center">
      <ButtonComponent
        type="submit"
        disabled={$form.isDisabled || isUploading}
        isLoading={$form.isLoading}
      >
        {title}
      </ButtonComponent>
    </div>
  </form>
</div>
