<script lang="ts">
  import { translate } from "lib";
  import { dialogData } from "stores";
  import { ButtonComponent } from "ui";

  export let data: any;

  const onDelete = async (): Promise<void> => {
    await data.executeFunction();
    $dialogData = { data: {}, type: "" } as any;
  };

  let stage = 1;
</script>

<div class="flex flex-col justify-center items-center">
  <h3 class="text-lg mt-5 mb-5">
    {translate("DELETE")}
    {data.title.toLowerCase()}
  </h3>
  <div>
    <ButtonComponent
      myClasses="bg-red"
      customColor="bg-red-500 hover:bg-red-800 focus:ring-red-800"
      on:click={onDelete}
    >
      {translate("DELETE")}
    </ButtonComponent>
  </div>
  <div class="mb-5"></div>
</div>
