<script lang="ts">
  import { slide } from "svelte/transition";

  let id: string = "";
  let label: string;
  let placeholder = "";
  let value: string;
  let error: string;

  export { id, label, placeholder, value, error };
</script>

<div class="relative pb-4 flex flex-col">
  <label
    for={id || label}
    class="h-4 ml-1 text-xs flex items-center leading-none font-medium text-gray-900 dark:text-gray-100"
  >
    {label}:
  </label>

  <textarea
    id={id || label}
    class="
      block w-full dark:bg-zinc-700 rounded-md border-0 py-2 px-4 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500 sm:text-sm sm:leading-6
    "
    placeholder={placeholder || label}
    rows={4}
    bind:value
    on:input
  />

  {#if error}
    <div
      in:slide
      out:slide
      class="h-4 ml-1 flex items-center leading-none absolute bottom-0 left-0 text-xs text-red-500"
    >
      {error}
    </div>
  {/if}
</div>
