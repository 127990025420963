<script lang="ts">
  import {onMount} from "svelte";

  import {
    formStoreCreate,
    MealEntityType,
    patchWithJwt,
    postWithJwt,
    serverlessRoutes,
    translate
  } from "lib";

  import {alertStore, dialogData, mealPlanStore} from "stores";
  import {ButtonComponent, InputField, TextareaField} from "ui";
  import type {Meal, PartialItemMap} from "interfaces";

  let data: any;

  const form = formStoreCreate({
    name: ["", "NAME"],
    description: ["", "DESCRIPTION"]
  });

  const dialogType = data.meal ? translate("EDIT") : translate("CREATE");

  const createTextMeal = async (): Promise<void> => {
    const {mealPlanId} = data;

    const partialMeal: Partial<Meal> = {
      name: $form.field.name.value,
      description: $form.field.description.value,
      entityType: MealEntityType.TEXT,
      mealPlanId,
      showMacros: 0,
      position: $mealPlanStore?.meals?.length || 0
    };

    const {data: rData, error} = await postWithJwt(
      serverlessRoutes.MEAL,
      {partialMeal}
    );

    if (error && !rData) {
      $form.isLoading = false;
      alertStore.show(translate("ERROR_CREATING_MEAL"), "error");
      return console.error(error);
    }

    mealPlanStore.addMeals([rData.meal]);
    alertStore.show(translate("SUCCESSFULLY_CREATED_MEAL"));

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const editTextMeal = async (): Promise<void> => {
    const partialMealMap: PartialItemMap<Meal> = {
      [data.meal.id]: {
        name: $form.field.name.value,
        description: $form.field.description.value
      }
    };

    const {data: rData, error} = await patchWithJwt(
      serverlessRoutes.MEAL,
      {partialMealMap}
    );

    if (error && !rData) {
      $form.isLoading = false;
      alertStore.show(translate("ERROR_EDITING_MEAL"), "error");
      return console.error(error);
    }

    mealPlanStore.replaceMeals(rData.meals);
    alertStore.show(translate("SUCCESSFULLY_EDITED_MEAL"));

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onSubmit = async (): Promise<void> => {
    $form.isLoading = true;

    if (data.meal) {
      await editTextMeal();
    } else {
      await createTextMeal();
    }
  };

  onMount((): void => {
    if (data.meal) {
      const {name, description} = data.meal;
      form.setValue("name", name || "");
      form.setValue("description", description || "");
    }
  });

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">

  <div class="text-center font-semibold">
    {dialogType}
    {translate("MEAL").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault="{onSubmit}">
    <InputField
      label="{translate("NAME")}"
      error="{$form.field.name.error}"
      bind:value="{$form.field.name.value}"
      on:input="{() => form.validate("name")}"
    />

    <TextareaField
      label="{translate("DESCRIPTION")}"
      error="{$form.field.description.error}"
      bind:value="{$form.field.description.value}"
      on:input="{() => form.validate("description")}"
    />

    <div class="flex justify-center">
      <ButtonComponent
        disabled="{$form.isDisabled}"
        isLoading="{$form.isLoading}"
        type="submit"
      >
        {dialogType}
      </ButtonComponent>
    </div>
  </form>

</div>
