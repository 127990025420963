<script lang="ts">
  import { onMount } from "svelte";
  import { MealEntityType, patchWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import { alertStore, mealPlanStore, dialogData } from "stores";
  import { ButtonComponent, CheckboxComponent, InputField, TextareaField } from "ui";

  let data: any;

  const form = {
    name: {
      value: "",
      error: ""
    },
    description: {
      value: "",
      error: ""
    },
    showMacros: {
      value: false,
      error: ""
    }
  };

  let disabled = true;
  let isLoading = false;
  const dialogType = data.meal ? translate("EDIT") : translate("CREATE");

  const onInput = (field: keyof typeof form): void => {
    if (field === "name") {
      const { value } = form[field];

      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      const { value } = form[field];

      if (value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some(({error}): boolean => error !== "");
  };

  const createHeaderMeal = async (): Promise<void> => {
    const {mealPlanId} = data;

    const partialMeal = {
      name: form.name.value,
      description: form.description.value,
      entityType: MealEntityType.HEADER,
      mealPlanId,
      showMacros: form.showMacros.value ? 1 : 0,
      position: $mealPlanStore?.meals?.length || 0,
      finishedAt: null
    };

    const response = await postWithJwt(serverlessRoutes.MEAL, {partialMeal});

    if (response.error && !response.data) {
      console.error(response.error);
      alertStore.show(translate("ERROR_CREATING_MEAL"), "error");
      isLoading = false;
      return;
    }

    mealPlanStore.addMeals([response.data.meal]);
    alertStore.show(translate("SUCCESSFULLY_CREATED_MEAL"));

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const editHeaderMeal = async (): Promise<void> => {
    const partialMealMap = {
      [data.meal.id]: {
        name: form.name.value,
        description: form.description.value,
        showMacros: form.showMacros.value ? 1 : 0
      }
    };

    const response = await patchWithJwt(
      serverlessRoutes.MEAL,
      {partialMealMap}
    );

    if (response.error && !response.data) {
      console.error(response.error);
      alertStore.show(translate("ERROR_EDITING_MEAL"), "error");
      isLoading = false;
      return;
    }

    mealPlanStore.replaceMeals(response.data.meals);
    alertStore.show(translate("SUCCESSFULLY_EDITED_MEAL"));

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.meal) {
      await editHeaderMeal();
    } else {
      await createHeaderMeal();
    }
  };

  onMount((): void => {
    if (data.meal) {
      const { name, description, showMacros } = data.meal;

      form.name.value = name || "";
      form.description.value = description || "";
      form.showMacros.value = showMacros;

      onInput("name");
      onInput("description");
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">

  <div class="text-center font-semibold">
    {dialogType}
    {translate("HEADER").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <InputField
      label="{translate("NAME")}"
      error="{form.name.error}"
      bind:value="{form.name.value}"
      on:input="{() => onInput("name")}"/>

    <TextareaField
      label="{translate("DESCRIPTION")}"
      error="{form.description.error}"
      bind:value="{form.description.value}"
      on:input="{() => onInput("description")}"/>

    <CheckboxComponent
      label="{translate("DISPLAY_MACRONUTRIENT_VALUES")}"
      bind:value={form.showMacros.value}/>

    <div class="text-xxs text-justify">
      <span class="text-primary-500">{translate("NOTE")}:</span>
      {translate("CREATE_HEADER_NOTE")}
    </div>

    <div class="flex justify-center">
      <ButtonComponent {disabled} {isLoading} type="submit">
        {dialogType}
      </ButtonComponent>
    </div>
  </form>

</div>
