import { serverlessRoutes } from "../constants";
import { getWithJwt } from "../requests";
import { generateCloudflareImagesUploadUrl } from "./generateUploadUrl";

interface UploadImageCloudflareResult {
  error: string | null;
  imageUrl: string | null;
  fileHash: any;
}

export const uploadAndSignImageCloudflare = async (
  file: File
): Promise<UploadImageCloudflareResult> => {
  const formData = new FormData();
  formData.append("file", file);
  const uploadUrlRes = await generateCloudflareImagesUploadUrl(true);
  if (!uploadUrlRes?.uploadUrl)
    return { imageUrl: null, fileHash: null, error: "Something went wrong." };
  const uploadUrl = uploadUrlRes.uploadUrl;
  const uploadResult = await fetch(uploadUrl, {
    method: "POST",
    body: formData,
  });
  const result = await uploadResult.json();

  if (uploadResult.ok) {
    const fetchUploadUrl = await getWithJwt(
      `${serverlessRoutes.FILE}/sign-cloudflare?url=${result.result.variants[0]}`
    );
    return { imageUrl: fetchUploadUrl.url, fileHash: result.result.variants[0], error: null };
  } else {
    return { imageUrl: null, fileHash: null, error: "Something went wrong." };
  }
};

export const uploadImageCloudflare = async (
  file: File,
  requireSignedURLs: boolean
): Promise<UploadImageCloudflareResult> => {
  const formData = new FormData();
  formData.append("file", file);
  const uploadUrlRes = await generateCloudflareImagesUploadUrl(requireSignedURLs);
  if (!uploadUrlRes?.uploadUrl)
    return { imageUrl: null, fileHash: null, error: "Something went wrong." };
  const uploadUrl = uploadUrlRes.uploadUrl;
  const uploadResult = await fetch(uploadUrl, {
    method: "POST",
    body: formData,
  });
  const result = await uploadResult.json();
  if (uploadResult.ok) {
    return { imageUrl: result.result.variants[0], fileHash: null, error: null };
  } else {
    return { imageUrl: null, fileHash: null, error: "Something went wrong." };
  }
};

export const signCloudflareImage = async (url: string) => {
  const fetchSignedUrl = await getWithJwt(
    `${serverlessRoutes.FILE}/sign-cloudflare?url=${url.slice(15, url.length)}`
  );
  return fetchSignedUrl.url;
};
