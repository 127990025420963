<script lang="ts">
  import {onMount} from "svelte";
  import {
    Button,
    Input,
    Label,
    Textarea,
    Helper,
    Heading,
    P,
    Spinner
  } from "flowbite-svelte";
  import {api, postWithJwt, putWithJwt, translate} from "lib";
  import {
    dialogData,
    showAlert,
    trainerPrograms,
    trainerWorkouts
  } from "stores";
    import { ButtonComponent } from "ui";

  let data: any;

  const form = {
    name:         {value: "", error: ""},
    description:  {value: "", error: ""}
  };

  let disabled = true;
  let isLoading = false;
  const dialogType = data.exercise ? translate("EDIT") : translate("CREATE");

  const onInput = (field: keyof typeof form): void => {
    const {value} = form[field];

    if (field === "name") {
      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some(({error}) => error !== "");
  };

  const editHeaderWorkout = async (): Promise<void> => {
    isLoading = true;

    try {
      const response = await putWithJwt(`${api}/exercise/${data.exerciseId}`, {
        name: form.name.value,
        description: form.description.value,
        isDelimiter: true,
        id: data.exerciseId
      });

      if (data.programId) {
        const _program = $trainerPrograms.find(
          (_program): boolean => _program.id === data.programId
        );

        const _workout = _program.workouts.find(
          (_workout: any): boolean => _workout.id === data.workoutId
        );

        const _exercise = _workout.exercises.find(
          (_exercise: any): boolean => _exercise.id === response.id
        );

        const index = _workout.exercises.indexOf(_exercise);

        _workout.exercises.splice(index, 1, response);

        data.executeFunction(response);
      } else {
        const _workout = $trainerWorkouts.find(
          (_workout): boolean => _workout.id === data.workoutId
        );

        const _exercise = _workout.exercises.find(
          (_exercise: any): boolean => _exercise.id === response.id
        );

        const index = _workout.exercises.indexOf(_exercise);

        _workout.exercises.splice(index, 1, response);
        $trainerWorkouts = $trainerWorkouts;
      }

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")} ${translate("WORKOUT")}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_EDITING_WORKOUT");
    }
  };

  const createHeaderWorkout = async (): Promise<void> => {
    isLoading = true;

    try {
      const response = await postWithJwt(`${api}/exercise`, {
        workoutId: data.workoutId,
        exercise: {
          name: form.name.value,
          description: form.description.value,
          entityType: 1
        }
      });

      if (data.workout.default) { // Workout u workouts
        const _workout = $trainerWorkouts.find(
          (_workout): boolean => _workout.id === data.workoutId
        );

        _workout.exercises.push(response);
        $trainerWorkouts = $trainerWorkouts;
      } else { // Workout u program
        const _program = $trainerPrograms.find(
          (_program): boolean => _program.id === data.workout.programId
        );

        const _workout = _program.workouts.find(
          (_workout: any): boolean => _workout.id === data.workout.id
        );

        _workout.exercises.push(response);
        data.executeFunction();
      }

      $dialogData.type = "";
      $dialogData.data = {};

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")} ${translate("WORKOUT")}
      `;
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_WORKOUT");
    }
  };

  const onSubmit = async (): Promise<void> => {
    if (data.exercise) {
      await editHeaderWorkout();
    } else {
      await createHeaderWorkout();
    }
  };

  onMount((): void => {
    if (data.exercise) {
      const {name, description} = data.exercise;

      if (name) {
        form.name.value = name;
        onInput("name");
      }

      if (description) {
        form.description.value = description;
        onInput("description");
      }
    }
  });

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {dialogType} {translate("HEADER").toLowerCase()}
  </Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault="{onSubmit}">

    <div>
      <Label for="name">{translate("NAME")}:</Label>
      <Input
        id="name"
        placeholder="{translate("NAME")}"
        bind:value="{form.name.value}"
        on:input="{() => onInput("name")}"/>
      <Helper color="red">
        {#if form.name.error}
          {form.name.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div>
      <Label for="description">
        {translate("DESCRIPTION")}:
      </Label>
      <Textarea
        id="description"
        placeholder="{translate("DESCRIPTION")}"
        rows="4"
        bind:value="{form.description.value}"
        on:input="{() => onInput("description")}"/>
      <Helper color="red">
        {#if form.description.error}
          {form.description.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <P size="xs" justify>
      <b>*{translate("NOTE")}:</b> {translate("HEADERS_WORKOUT_NOTE")}
    </P>

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green"/>
      {:else}
        <ButtonComponent type="submit" {disabled}>{dialogType}</ButtonComponent>
      {/if}
    </div>

  </form>
</div>
