<script lang="ts">
  import {scale} from "svelte/transition";
  import {AccordionItem, Accordion, Button, Heading, Helper, Input, Label, Radio, Select, Spinner, Checkbox, Textarea, ButtonGroup, InputAddon, NumberInput} from "flowbite-svelte";
  import {api, dateString, postFormDataWithJwt, translate} from "lib";
  import {dialogData, showAlert} from "stores";
  import DatePicker from "../../../components/UI/DatePicker.svelte";
  import DualSlider from "../../../components/UI/DualSlider.svelte";
  import Svg from "../../../components/UI/Svg.svelte";
  import { ButtonComponent } from "ui";

  let data: any;
  let isLoading = false;
  let disabled = false;

  const form = {
    dateOfDispatch: {value: "", error: ""},
    title:          {value: "", error: ""},
    message:        {value: "", error: ""},
    email:          {value: false, error: ""},
    sms:            {value: false, error: ""},
    inApp:          {value: false, error: ""},
    sendNow:        {value: false, error: ""},
  };

  const getAction = (): number => {
    // action:

    // samo email:	0
    // samo sms:	1
    // samo in-app:	2
    // email + sms:	3
    // email + in-app:	4
    // sms + in-app:	5

    // email_many
    // sms_many
    // message_many
    const {email, sms, inApp} = form;

    if (email.value && !sms.value && !inApp.value) {
      return 0;
    } else if (!email.value && sms.value && !inApp.value) {
      return 1;
    } else if (!email.value && !sms.value && inApp.value) {
      return 2;
    } else if (email.value && sms.value && !inApp.value) {
      return 3;
    } else if (email.value && !sms.value && inApp.value) {
      return 4;
    } else {
      return 5;
    }
  };

  const onInput = (field: keyof typeof form): void => {};

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.ids) {

      // if (data.ids.length > 1) {
      //   const url = `${api}/custom_reminder/many`;
      //   const formData = new FormData();
      //   let action = getAction();

      //   formData.append("customReminder", JSON.stringify({
      //     note: form.title.value !== "" ? form.title.value : null,
      //     date: dateString(new Date(form.dateOfDispatch.value)),
      //     message: form.message.value,
      //     action
      //   }));
      //   formData.append("clientIds", JSON.stringify(data.ids));
      //   formData.append("thumbnail", JSON.stringify(undefined));

      //   const res = await postFormDataWithJwt(url, formData, "POST");
      // } else {
      //   const url = `${api}/custom_reminder`;
      //   const formData = new FormData();
      //   let action = getAction();

      //   formData.append("customReminder", JSON.stringify({
      //     note: form.title.value !== "" ? form.title.value : null,
      //     date: dateString(new Date(form.dateOfDispatch.value)),
      //     message: form.message.value,
      //     action
      //   }));
      //   formData.append("clientId", JSON.stringify(data.ids[0]));
      //   formData.append("thumbnail", JSON.stringify(undefined));

      //   const res = await postFormDataWithJwt(url, formData, "POST");
      // }
      const url = `${api}/user/message_many`;
      const formData = new FormData();

      formData.append("userIds", JSON.stringify(data.ids));
      formData.append("message", form.message.value);
      formData.append("file", JSON.stringify(undefined));

      try {
        const res = await postFormDataWithJwt(url, formData, "POST");

        if (res === "Success") {
          $showAlert.color = "black";
          $showAlert.message = translate("MESSAGES_SENT");

          $dialogData.type = "";
          $dialogData.data = {};
        }
      } catch (error) {
        isLoading = false;
        console.error(error);
      }
    }
  };

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {translate("SEND_MESSAGE_U")}
  </Heading>

  <form on:submit|preventDefault="{onSubmit}">
    <!-- {#if !form.sendNow.value}
      <DatePicker title="DATE_OF_DISPATCH" bind:date="{form.dateOfDispatch.value}"/>
    {/if}

    {#if form.email.value}
      <Label for="title">{translate("TITLE")}:</Label>
      <Input
        id="title"
        placeholder="{translate("TITLE")}"
        bind:value="{form.title.value}"
        on:input="{() => onInput("title")}"/>
      <Helper color="red">
        {#if form.title.error}
          {form.title.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    {/if} -->

    <div>
      <Label for="message">{translate("MESSAGE")}:</Label>
      <Textarea
        id="message"
        placeholder="{translate("MESSAGE")}"
        rows="4"
        bind:value="{form.message.value}"
        on:input="{() => onInput("message")}"/>
      <Helper color="red">
        {#if form.message.error}
          {form.message.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <!-- <Label>{translate("DISPATCH_METHOD")}:</Label>
    <Checkbox bind:checked="{form.email.value}">Email</Checkbox>
    <Checkbox bind:checked="{form.sms.value}">SMS</Checkbox>
    <Checkbox bind:checked="{form.inApp.value}">{translate("IN_APP")}</Checkbox>

    {#if data.ids && data.ids.length > 1}
      <Checkbox bind:checked="{form.sendNow.value}">{translate("SEND_NOW")}</Checkbox>
    {/if} -->

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green"/>
      {:else}
        <ButtonComponent type="submit" {disabled}>{translate("SEND")}</ButtonComponent>
      {/if}
    </div>
  </form>
</div>