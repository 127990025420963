<script lang="ts">
  import { onMount } from "svelte";
  import { deleteWithJwt, getWithJwt, serverlessRoutes, translate } from "lib";
  import { parseDateWithTime } from "../../lib/parseDateWithTime";
  import { Svg } from "ui";
  import { bookmarkedMessages } from "../../stores/bookmarkedMessages";

  // interface BookmarkedMessage {
  //   id: number;
  //   payload: string;
  //   createdAt: string;
  //   sessionId: number;
  //   messageId: number;
  // }

  export let data: any;

  // let bookmarkedMessages: BookmarkedMessage[] = [];

  const fetchBookmarkedMessages = async (): Promise<void> => {
    const url = `${serverlessRoutes.BOOKMARK}?sessionId=${data.sessionId}`;
    try {
      const res = await getWithJwt(url);
      $bookmarkedMessages = res.data;
    } catch (err) {}
  };

  const deleteBookmark = async (id: number): Promise<void> => {
    const url = `${serverlessRoutes.BOOKMARK}?bookmarkId=${id}`;
    try {
      await deleteWithJwt(url);
      $bookmarkedMessages = $bookmarkedMessages.filter(
        (message) => message.id !== id
      );
    } catch (err) {}
  };

  // onMount(fetchBookmarkedMessages);
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="p-4 flex flex-col gap-4">
  {#if $bookmarkedMessages.length}
    {#each $bookmarkedMessages as message}
      <div class="flex flex-col gap-4">

        <div class="text-xs text-center">{parseDateWithTime(message.createdAt)}</div>

        <div class="flex justify-between gap-4">
          <div class="
            w-fit px-2.5 py-1.5 border border-slate-300 dark:border-zinc-600
            text-slate-900 dark:text-slate-100 rounded-2xl text-xs font-sans
            text-justify overflow-hidden
            my-wrap
          "
          >
            {message.payload}
          </div>
          <Svg name="delete" size={24} customColor="bg-red-500" on:click={() => deleteBookmark(message.id)}/>
        </div>

      </div>
    {/each}
  {:else}
    {translate("NO_BOOKMARKED_MESSAGES_YET")}
  {/if}
</div>

<style>
  /* nema tailwind klasa za ovo, ako se pojavi u buduce verzije ovde da se obrise */
  .my-wrap {
    overflow-wrap: break-word;
  }
</style>
