import { writable } from "svelte/store";

interface BookmarkedMessage {
  id: number;
  payload: string;
  createdAt: string;
  sessionId: number;
  messageId: number;
}

const bookmarkedMessages = writable<Array<BookmarkedMessage>>([]);

export {bookmarkedMessages};
