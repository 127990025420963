<script lang="ts">
  import SvgComponent from "./Svg.svelte";
</script>

<div class="tag">
  <div class="tag__clip bg-primary-500 dark:bg-primary-700"></div>
  <div class="tag__icon">
    <SvgComponent myClass="mr-2" name="star" size={14} customColor="bg-white"/>
  </div>
</div>

<style>
  .tag {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 36px;
    width: 36px;
    border-top-left-radius: 4px;
    overflow: hidden;
  }
  .tag__icon {
    position: absolute;
    top: 4px;
    left: 4px;
  }
  .tag__clip {
    height: 36px;
    width: 36px;
    clip-path: polygon(0px 0px, 36px 0px, 0px 36px);
    /* background-color: #6875f5; */
  }
</style>
