<script lang="ts">
  import {Capacitor} from "@capacitor/core";
  import {translate} from "lib";
  import {ButtonComponent} from "ui";

  let data: any;
  let currentVersion = "1.24";
  let newestVersion = "1.25";

  const isNativePlatform = Capacitor.isNativePlatform();

  export {data};
</script>

<div class="p-4 flex flex-col gap-4 leading-none">
  <div class="font-bold text-center text-xxl">
    {translate("UPDATE_AVAILABLE")}
  </div>

  <div class="text-center">
    {translate("CURRENT_VERSION")}:
    <span class="font-bold">{currentVersion}</span>
    <br/>
    {translate("NEWEST_VERSION")}:
    <span class="font-bold">{newestVersion}</span>
  </div>

  {#if isNativePlatform}
    <div class="text-justify">{translate("UPDATE_TO_USE_APP")}</div>
    <div class="flex justify-center gap-4">
      <ButtonComponent isOutline>
        <img class="h-8" src="app-store.webp" alt="App store"/> iOS
      </ButtonComponent>
      <ButtonComponent isOutline>
        <img class="h-8" src="play-store.webp" alt="Play store"/> Android
      </ButtonComponent>
    </div>
  {:else}
    <div class="text-justify">{translate("REFRESH_TO_USE_APP")}</div>
  {/if}
</div>
