const flip = {
  duration: 333,
};

const slide = {
  duration: 333,
};


const animations = {flip, slide};

export {animations};
