<script lang="ts">
  import { translate } from "lib";
  import { dialogData } from "stores";
  import { ButtonComponent } from "ui";

  let data: any;

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <!-- <Heading align="center" tag="h6"></Heading> -->
  <h3 class="flex self-center text-lg">{translate("SEND_TO_ARCHIVE")}</h3>
  <p>
    {translate("ARCHIVE_DESCRIPTION")}
  </p>
  <div class="h-10 flex justify-center">
    <ButtonComponent
      on:click={() => {
        data.executeFunction();
        $dialogData.type = "";
        $dialogData.data = {};
      }}>{translate("ARCHIVE")}</ButtonComponent
    >
  </div>
</div>
