import type { MenuItem } from "interfaces";

const filterMenu = (
  menuItems: Array<MenuItem>,
  titles: Array<string>
): Array<MenuItem> => menuItems.filter(
  ({title}): boolean => titles.includes(title)
);

export {filterMenu};
