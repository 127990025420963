async function post(
  url = "",
  data = {},
  headers?: HeadersInit,
  method = "POST"
) {
  const response = await fetch(url, {
    method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: headers
      ? headers
      : {
          "Content-Type": "application/json",
        },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  if (response.status === 401) {
    throw new Error(`Unauthorized ${location.href}`);
    localStorage.removeItem("authJwt");
    location.href = "";
  }
  return response.json();
}

async function get(url = "", headers?: HeadersInit) {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: headers
      ? headers
      : {
          "Content-Type": "application/json",
        },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
  if (response.status === 401) {
    throw new Error(`Unauthorized ${location.href}`);
    localStorage.removeItem("authJwt");
    location.href = "";
  }
  return response.json();
}

async function postWithJwt(url = "", data = {}) {
  const jwt = localStorage.getItem("authJwt");
  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearerJwt,
    };
    return await post(url, data, headers);
  }
}

async function deleteWithJwt(url = "", data = {}) {
  const jwt = localStorage.getItem("authJwt");
  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearerJwt,
    };
    return await post(url, data, headers, "DELETE");
  }
}

async function putWithJwt(url = "", data = {}) {
  const jwt = localStorage.getItem("authJwt");
  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearerJwt,
    };
    return await post(url, data, headers, "PUT");
  }
}

async function patchWithJwt(url = "", data = {}) {
  const jwt = localStorage.getItem("authJwt");
  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearerJwt,
    };
    return await post(url, data, headers, "PATCH");
  }
}

// async function postFormDataWithJwt(url = "", data, method = "POST") {
//   const jwt = localStorage.getItem("authJwt");
//   if (jwt) {
//     const bearerJwt = `Bearer ${jwt}`;
//     const response = await fetch(url, {
//       method,
//       mode: "cors",
//       cache: "no-cache",
//       credentials: "same-origin",
//       headers: {
//         Authorization: bearerJwt,
//       },
//       redirect: "follow",
//       referrerPolicy: "no-referrer",
//       body: data,
//     });
//     return response.json();
//   }
// }

async function postFormDataWithJwt(
  url = "",
  data,
  method = "POST",
  retries = 3,
  delay = 1000
) {
  const jwt = localStorage.getItem("authJwt");
  if (!jwt) throw new Error("No JWT token found");

  const bearerJwt = `Bearer ${jwt}`;

  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const response = await fetch(url, {
        method,
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          Authorization: bearerJwt,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: data,
      });

      if (!response.ok) throw new Error(`Server error: ${response.status}`);
      return await response.json(); // Success: return the parsed JSON
    } catch (error) {
      if (attempt < retries - 1) {
        console.warn(
          `Attempt ${attempt + 1} failed. Retrying in ${delay} ms...`
        );
        await new Promise((res) => setTimeout(res, delay)); // Wait before retrying
      } else {
        throw error; // Throw error after final attempt
      }
    }
  }
}

async function postFormData(url = "", data, method = "POST") {
  const response = await fetch(url, {
    method,
    body: data,
  });
  return response;
}

async function getWithJwt(url = "") {
  const jwt = localStorage.getItem("authJwt");
  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const headers = {
      "Content-Type": "application/json",
      authorization: bearerJwt,
    };
    return await get(url, headers);
  }
}

async function getPdfWithJwt(url = "") {
  const jwt = localStorage.getItem("authJwt");
  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        authorization: bearerJwt,
        "Content-Type": "application/pdf",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.blob();
  }
}

export {
  get,
  post,
  postWithJwt,
  putWithJwt,
  getWithJwt,
  postFormDataWithJwt,
  getPdfWithJwt,
  postFormData,
  deleteWithJwt,
  patchWithJwt,
};
