import { get, writable } from "svelte/store";
import { getWithJwt, serverlessRoutes } from "lib";
import {storeUtil} from "../lib/createItemsStore";
import { currentClient } from "./currentClient";
import type { Ingredient, ItemsStore, Meal, Recipe } from "interfaces";
import { user } from "./userStore";

interface JournalMealsStore extends ItemsStore<Meal> {
  map: {
    [date: string]: Array<Meal> | null;
  };
  filter: {
    detailedView: boolean;
  }
}

const journalMealsStoreCreate = () => {
  const {set, subscribe, update} = writable<JournalMealsStore>({
    items: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    skip: 0,
    searchTimeout: undefined,
    map: {},
    filter: {
      detailedView: false
    }
  });

  const createUrl = (): string => {
    const {
      skip,
      filter: {detailedView}
    } = get({subscribe});

    const params = new URLSearchParams();

    params.append("take", "8");
    params.append("skip", skip.toString());
    params.append("clientId", (get(currentClient).id || get(user).id).toString());
    params.append("finishedAt", "true");

    return `${serverlessRoutes.MEAL}/client?${params.toString()}`;
  };

  const {
    // add,
    // replace,
    // remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache
  } = storeUtil<Meal, JournalMealsStore>(
    "journalMealsCache", update, createUrl
  );

  const fetchRecipeIngredients = async (mealId: number, id: number): Promise<void> => {
    try {
      const response = await getWithJwt(
        `${serverlessRoutes.RECIPE}/${id}/ingredients`
      );

      addRecipeIngredients(mealId, id, response.data.ingredients);
    } catch (error) {
      console.error(error);
    }
  };

  const generateMealMap = (): void => {
    update((store) => {
      store.map = {};

      store.items.forEach((meal): void => {
        if (!meal.finishedAt) {
          return;
        }

        const date = meal.finishedAt.slice(0, 10);

        if (!store.map[date]) {
          store.map[date] = [meal];
        } else {
          store.map[date].push(meal);
        }
      });

      return store;
    });
  };

  const getMealIdFromRecipeId = (recipeId: number): number | undefined => {
    const {items} = get({subscribe});

    const meal = items.find((meal): boolean => {
      const recipe = meal.recipes?.find(({id}): boolean => id === recipeId);
      return recipe ? true : false;
    });

    return meal?.id;
  };

  const replace = (meals: Array<Meal>): void => {
    update((store) => {
      meals.forEach((meal): void => {
        const mealIndex = store.items.findIndex(
          ({id}): boolean => id === meal.id
        );

        if (mealIndex === -1) {
          return;
        }

        store.items = store.items.with(mealIndex, meal);
      });

      return store;
    });

    generateMealMap();
  };

  const remove = (ids: Array<number>): void => {
    update((store) => {
      store.items = store.items.filter(
        (meal): boolean => !ids.includes(meal.id)
      );

      store.count -= ids.length;
      store.skip -= ids.length;
      store.hasMore = store.items.length < store.count;

      return store;
    });

    generateMealMap();
  };

  const add = (meals: Array<Meal>): void => {
    update((store) => {
      store.items.unshift(...meals);

      store.count += meals.length;
      store.skip += meals.length;
      store.hasMore = store.items.length < store.count;

      return store;
    });

    generateMealMap();
  };

  const calculateMacros = (meal: Meal): void => {
    let ingredientMacros = {
      protein: 0,
      carbs: 0,
      fats: 0,
      calories: 0
    };

    let recipeMacros = {
      protein: 0,
      carbs: 0,
      fats: 0,
      calories: 0
    };

    if (meal.ingredients) {
      ingredientMacros = meal.ingredients.reduce((macro, ingredient) => {
        const {protein, carbs, fats, calories} = ingredient;

        macro.protein += protein || 0;
        macro.carbs += carbs || 0;
        macro.fats += fats || 0;
        macro.calories += calories || 0;

        return macro;
      }, {
        protein: 0,
        carbs: 0,
        fats: 0,
        calories: 0
      });
    }

    if (meal.recipes) {
      recipeMacros = meal.recipes.reduce((macro, recipe) => {
        const {protein, carbs, fats, calories} = recipe;

        macro.protein += protein || 0;
        macro.carbs += carbs || 0;
        macro.fats += fats || 0;
        macro.calories += calories || 0;

        return macro;
      }, {
        protein: 0,
        carbs: 0,
        fats: 0,
        calories: 0
      });
    }

    meal.protein = ingredientMacros.protein + recipeMacros.protein;
    meal.carbs = ingredientMacros.carbs + recipeMacros.carbs;
    meal.fats = ingredientMacros.fats + recipeMacros.fats;
    meal.calories = ingredientMacros.calories + recipeMacros.calories;
  };

  const calculateMacrosMealRecipe = (recipe: Recipe): void => {
    if (!recipe.ingredients) {
      return;
    }

    const ingredientMacros = recipe.ingredients.reduce((macro, ingredient) => {
      const {protein, carbs, fats, calories} = ingredient;

      macro.protein += protein || 0;
      macro.carbs += carbs || 0;
      macro.fats += fats || 0;
      macro.calories += calories || 0;

      return macro;
    }, {
      protein: 0,
      carbs: 0,
      fats: 0,
      calories: 0
    });

    recipe.protein = ingredientMacros.protein;
    recipe.carbs = ingredientMacros.carbs;
    recipe.fats = ingredientMacros.fats;
    recipe.calories = ingredientMacros.calories;
  };

  const addMealIngredients = (
    mealId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      const meal = store.items.find((meal): boolean => meal.id === mealId);

      if (!meal) {
        return store;
      }

      if (meal.ingredients) {
        meal.ingredients.push(...newIngredients);
      } else {
        meal.ingredients = newIngredients;
      }

      calculateMacros(meal);

      return store;
    });
  };

  const replaceMealIngredients = (
    mealId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      const meal = store.items.find((meal): boolean => meal.id === mealId);

      if (!meal || !meal.ingredients) {
        return store;
      }

      const {ingredients} = meal;

      newIngredients.forEach((newIngredient): void => {
        const ingredientIndex = ingredients.findIndex(
          ({id}): boolean => id === newIngredient.id
        );

        if (ingredientIndex === -1) {
          return;
        }

        ingredients.splice(ingredientIndex, 1, newIngredient);
      });

      calculateMacros(meal);

      return store;
    });
  };

  const removeMealIngredients = (
    mealId: number,
    ingredientIds: Array<number>
  ): void => {
    update((store) => {
      const meal = store.items.find(({id}): boolean => id === mealId);

      if (!meal || !meal.ingredients) {
        return store;
      }

      const {ingredients} = meal;

      meal.ingredients = ingredients.filter(
        ({id}): boolean => !ingredientIds.includes(id)
      );

      calculateMacros(meal);

      return store;
    });
  };

  const addMealRecipes = (mealId: number, newRecipes: Array<Recipe>): void => {
    update((store) => {
      const meal = store.items.find(({id}): boolean => id === mealId);

      if (!meal) {
        return store;
      }

      if (meal.recipes) {
        meal.recipes.push(...newRecipes);
      } else {
        meal.recipes = newRecipes;
      }

      calculateMacros(meal);

      return store;
    });
  };

  const replaceMealRecipes = (mealId: number, newRecipes: Array<Recipe>): void => {
    update((store) => {
      const meal = store.items.find(({id}): boolean => id === mealId);

      if (!meal || !meal.recipes) {
        return store;
      }

      const {recipes} = meal;

      newRecipes.forEach((newRecipe): void => {
        const recipeIndex = recipes.findIndex(
          ({id}): boolean => id === newRecipe.id
        );

        if (recipeIndex === -1) {
          return;
        }

        recipes.splice(recipeIndex, 1, newRecipe);
      });

      calculateMacros(meal);

      return store;
    });
  };

  const removeMealRecipes = (mealId: number, recipeIds: Array<number>): void => {
    update((store) => {
      const meal = store.items.find(({id}): boolean => id === mealId);

      if (!meal || !meal.recipes) {
        return store;
      }

      const {recipes} = meal;

      meal.recipes = recipes.filter(({id}): boolean => !recipeIds.includes(id));

      calculateMacros(meal);

      return store;
    });
  };

  const addRecipeIngredients = (
    mealId: number,
    recipeId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      const meal = store.items.find(({id}): boolean => id === mealId);
      const recipe = meal?.recipes?.find(({id}): boolean => id === recipeId);

      if (!meal || !recipe) {
        return store;
      }

      if (recipe.ingredients) {
        recipe.ingredients.push(...newIngredients);
      } else {
        recipe.ingredients = newIngredients;
      }

      calculateMacrosMealRecipe(recipe);
      calculateMacros(meal);

      return store;
    });
  };

  const replaceRecipeIngredients = (
    mealId: number,
    recipeId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      const meal = store.items.find(({id}): boolean => id === mealId);
      const recipe = meal?.recipes?.find(({id}): boolean => id === recipeId);

      if (!meal || !recipe || !recipe.ingredients) {
        return store;
      }

      const {ingredients} = recipe;

      newIngredients.forEach((newIngredient): void => {
        const ingredientIndex = ingredients.findIndex(
          ({id}): boolean => id === newIngredient.id
        );

        if (ingredientIndex === -1) {
          return;
        }

        ingredients.splice(ingredientIndex, 1, newIngredient);
      });

      calculateMacrosMealRecipe(recipe);
      calculateMacros(meal);

      return store;
    });
  };

  const removeRecipeIngredients = (
    mealId: number,
    recipeId: number,
    ingredientIds: Array<number>
  ): void => {
    update((store) => {
      const meal = store.items.find(({id}): boolean => id === mealId);
      const recipe = meal?.recipes?.find(({id}): boolean => id === recipeId);

      if (!meal || !recipe || !recipe.ingredients) {
        return store;
      }

      const {ingredients} = recipe;

      recipe.ingredients = ingredients.filter(
        ({id}): boolean => !ingredientIds.includes(id)
      );

      calculateMacrosMealRecipe(recipe);
      calculateMacros(meal);

      return store;
    });
  };

  return {
    set,
    subscribe,
    update,
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache,
    generateMealMap, // na fetch mora rucno da se zove, zato je exportovano
    fetchRecipeIngredients,

    addMealIngredients,
    replaceMealIngredients,
    removeMealIngredients,
    addMealRecipes,
    replaceMealRecipes,
    removeMealRecipes,
    addRecipeIngredients,
    replaceRecipeIngredients,
    removeRecipeIngredients,

    getMealIdFromRecipeId,
  };
};

const journalMealsStore = journalMealsStoreCreate();

export {journalMealsStore};
