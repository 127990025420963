<script lang="ts">
  import { theme } from "../../stores/themeStore";

  export let height: string = "100vh";

  let color = $theme === "dark" ? "#22897B" : "#319795";
</script>

<div
  class="flex-col center-center"
  style={`height: ${height}; margin-top: -64px`}
>
  <div class="lds-ring" style="--color:{color};">
    <div />
    <div />
    <div />
    <div />
  </div>
</div>

<style>
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #aaa;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
