<script lang="ts">
  import {onDestroy, onMount} from "svelte";
  import {ButtonComponent, InfiniteScroll} from "ui"
  import {alertStore, journalMealsStore, dialogData, ingredientsStore, selectedIdsStore, mealPlanStore, recipesStore} from "stores";
  import {postWithJwt, serverlessRoutes, translate} from "lib";
  import {IngredientComponent, IngredientsToolbarComponent} from "diet";
  import type {Ingredient, PartialItemMap} from "interfaces";

  let data: any;
  let step = 1;
  let isLoading = false;
  let scrollElement: HTMLDivElement;
  let selectedIngredients: Array<Ingredient> = [];

  const unsubscribeSelectedIds = selectedIdsStore.subscribe((store): void => {
    selectedIngredients = $ingredientsStore.items.filter(
      ({id}): boolean => store.includes(id)
    );
  });

  const mountScrollElement = (element: HTMLDivElement): void => {
    scrollElement = element;
  };

  const onStepDown = (): void => {
    step -= 1;
  };

  const onStepUp = (): void => {
    step += 1;
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    const {recipe, meal} = data;
    const partialIngredientMap: PartialItemMap<Ingredient> = {};

    selectedIngredients.forEach((selectedIngredient): void => {
      const {
        id,
        amount,
        originalAmount,
        originalCarbs,
        originalProtein,
        originalFats,
        originalCalories
      } = selectedIngredient;

      partialIngredientMap[id] = {
        amount,
        carbs: (originalCarbs / originalAmount) * amount,
        protein: (originalProtein / originalAmount) * amount,
        fats: (originalFats / originalAmount) * amount,
        calories: (originalCalories / originalAmount) * amount,
        mealId: meal ? meal.id : null,
        recipeId: recipe ? recipe.id : null,
        isTemplate: 0,
        isDefault: 0,
        trainerId: null,
      };
    });

    const {error, data: {ingredients}} = await postWithJwt(
      `${serverlessRoutes.INGREDIENT}/copy`,
      {partialIngredientMap}
    );

    if (error) {
      isLoading = false;
      alertStore.show(translate("ERROR_IMPORTING_INGREDIENTS"), "error");
      return console.error(error);
    }

    if (recipe) {
      const {id, mealId, isDefault} = recipe;

      if (isDefault) {
        recipesStore.addIngredients(id, ingredients);
      } else if (mealId) {
        mealPlanStore.addRecipeIngredients(mealId, id, ingredients);
        journalMealsStore.addRecipeIngredients(mealId, id, ingredients);
      }
    } else if (meal) {
      const {id} = meal;

      mealPlanStore.addMealIngredients(id, ingredients);
      journalMealsStore.addMealIngredients(id, ingredients);
    }

    selectedIdsStore.clear();
    alertStore.show(translate("SUCCESSFULLY_IMPORTED_INGREDIENTS"));

    $dialogData.type = "";
    $dialogData.data = {};
  };

  onDestroy(unsubscribeSelectedIds);
  onMount(ingredientsStore.fetchData);

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">{translate("IMPORT_INGREDIENTS")}</div>

  {#if step === 1}
    <div>1. {translate("SELECT_INGREDIENTS")}</div>

    <IngredientsToolbarComponent/>

    <div
      class="h-72 pr-2 flex flex-col gap-4 overflow-y-scroll"
      use:mountScrollElement>

      {#each $ingredientsStore.items as ingredient}
        <IngredientComponent isSelectable {ingredient}/>
      {/each}

      <InfiniteScroll
        {scrollElement}
        hasMore={$ingredientsStore.hasMore}
        isFetchingMore={$ingredientsStore.isFetchingMore}
        on:loadMore={ingredientsStore.fetchMoreData}/>

    </div>

    <div class="flex justify-center">
      <ButtonComponent disabled={$selectedIdsStore.length === 0} on:click={onStepUp}>
        {translate("NEXT_STEP")}
      </ButtonComponent>
    </div>
  {:else}
    <div>2. {translate("SET_AMOUNT")}</div>

    <div class="flex-col gap-4">
      {#each selectedIngredients as ingredient}
        <IngredientComponent isModifiable2 {ingredient}/>
      {/each}
    </div>

    <div class="flex justify-center gap-4">
      <ButtonComponent on:click={onStepDown}>
        {translate("PREVIOUS_STEP")}
      </ButtonComponent>
      <ButtonComponent {isLoading} on:click={onSubmit}>
        {translate("ADD")}
      </ButtonComponent>
    </div>
  {/if}
</div>
