<script lang="ts">
  import { translate } from "lib";

  export let selectItems = [];
  export let value = "";
  export let label = "";
  export let onOptionChange;
</script>

<form class="max-w-sm mx-auto">
  {#if label !== ""}
    <label
      for="countries"
      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >{translate(label)}:</label
    >
  {/if}
  <select
    bind:value
    on:change={(event) => onOptionChange(event.target.value)}
    id="countries"
    class="block w-full dark:bg-zinc-700 rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500 sm:text-sm sm:leading-6"
  >
    {#each selectItems as item}
      <option selected={item.selected} value={item.name}>{item.name}</option>
    {/each}
  </select>
</form>
