<script lang="ts">
  export let name: string;
  export let size: number;
  export let myClass: string = "";
  export let customColor: string = "";
</script>

<!-- <img
  src={`/svg/${name}.svg`}
  style={`height: ${size + 4}px; color: ${customColor}`}
  alt=""
  class={myClass}
/> -->

<div
  class={`${myClass} ${
    customColor ? customColor : "bg-slate-900 dark:bg-zinc-200"
  }`}
  style={`z-index: 0; min-height: ${size}px; min-width: ${size}px; -webkit-mask: url("/svg/${name}.svg") no-repeat center; mask: url("/svg/${name}.svg") no-repeat center;`}
  on:click
/>

<style>
</style>
