<script lang="ts">
  import {onMount} from "svelte";
  import {Chart} from "chart.js";
  import {calculateMacroPercentages, translate} from "lib";
  import {Badge} from "ui"
  import Macros from "../../Diet/Macros.svelte";
  import type {Ingredient} from "interfaces";

  let data: {ingredient: Ingredient};
  let chartElement: HTMLCanvasElement;

  const {ingredient} = data;

  const {
    name,
    description,
    foodType,
    amount,
    measurementUnit,
    carbs,
    protein,
    fats,
    calories
  } = ingredient;

  const hidden = carbs === 0 && protein === 0 && fats === 0;

  const generateChart = (): void => {
    const {carbs, protein, fats} = calculateMacroPercentages(ingredient);

    const emerald400 = "rgb(52, 211, 153)";
    const sky400 = "rgb(56, 189, 248)";
    const rose400 = "rgb(251, 113, 133)";

    new Chart(chartElement, {
      type: "pie",
      data: {
        labels: [translate("CARBS"), translate("PROTEIN"), translate("FATS")],
        datasets: [{
          label: translate("PERCENT"),
          data: [carbs, protein, fats],
          hoverOffset: 4,
          backgroundColor: [emerald400, sky400, rose400]
        }]
      }
    });
  };

  onMount(generateChart);

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">

  <div class="text-center font-semibold">{name}</div>

  <div>
    <div class="font-semibold">
      {translate("PER")}
      {amount}{measurementUnit.toLowerCase()}:
    </div>
    <Macros {carbs} {protein} {fats} {calories} isCaloriesVisible/>
  </div>

  <div>
    <div class="font-semibold">{translate("INGREDIENT_TYPE")}:</div>
    {#if foodType}
      <div class="flex flex-wrap gap-2">
        {#each foodType.split(",") as name}
          <Badge>{name}</Badge>
        {/each}
      </div>
    {:else}
      <div class="text-justify text-xs">{translate("NO_TYPE")}</div>
    {/if}
  </div>

  <div>
    <div class="font-semibold">{translate("DESCRIPTION")}:</div>
    <div class="text-justify text-xs">
      {#if description}
        {description}
      {:else}
        {translate("NO_DESCRIPTION")}
      {/if}
    </div>
  </div>

  <div class:hidden class="h-[320px] flex justify-center">
    <canvas bind:this="{chartElement}"></canvas>
  </div>

</div>
