<script lang="ts">
  export let data: any;
</script>

<div class="h-[80vh] w-auto">
  <iframe
    class="h-full w-full"
    title="Video player"
    src={data.src}
    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
    allowfullscreen={true}
  ></iframe>
</div>
