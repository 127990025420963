<script lang="ts">
  export let id: string;
  export let fontSize: "xxs" | "xs" | "md" | "xl" | "xxl" = "md";
  export let group: any;
  export let value: any;
</script>

<div class="flex items-center">
  <input
    id={value}
    {value}
    checked
    type="radio"
    class="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-zinc-600 dark:border-gray-400"
    bind:group
    on:change
  />
  <label for="{value}" class="ms-2 text-{fontSize} font-medium text-gray-900 dark:text-gray-300"><slot/></label>
</div>
