<script lang="ts">
  import { ButtonComponent } from "ui";
  import { onMount } from "svelte";
  import {
    api,
    deleteWithJwt,
    getWithJwt,
    postFormDataWithJwt,
    postWithJwt,
    translate,
  } from "lib";
  import Svg from "../UI/Svg.svelte";
  import Divider from "../UI/Divider.svelte";
  import { payload } from "../../stores/payloadStore";
  import { dialogData } from "../../stores/dialogDataStore";
  import { user } from "../../stores/userStore";
  import type { ITemplateMessage } from "../../interfaces/ITemplateMessage";
  import { showAlert } from "stores";

  export let data: any;

  let templateMessages: ITemplateMessage[] = [];
  let deleteMessageId: number | null = null;
  let filter: string | null = null;
  let messageToAdd: string | null = null;
  let fileInput: HTMLElement;
  let file: File;
  let dragItemId: number | null = null;

  const fetchTemplateMessages = async () => {
    try {
      const res = await getWithJwt(
        `${api}/chat/template_message${filter ? `?filterBy=${filter}` : ``}`
      );
      templateMessages = res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteTemplateMessage = async () => {
    try {
      const res = await deleteWithJwt(
        `${api}/chat/template_message/${deleteMessageId}`
      );
      templateMessages = templateMessages.filter(
        (templateMessage) => templateMessage.id !== deleteMessageId
      );
      deleteMessageId = null;
    } catch (error) {
      console.error(error);
      deleteMessageId = null;
    }
  };

  const onFileSelected = (e: any) => {
    file = e.target.files[0];
    addTemplateMessage();
  };

  const addTemplateMessage = async () => {
    try {
      const formData = new FormData();
      if (!messageToAdd) messageToAdd = "";
      formData.append("text", messageToAdd);
      formData.append("trainerId", $user.id);
      if (file) {
        formData.append("image", file);
      }
      const res = await postFormDataWithJwt(
        `${api}/chat/template_message`,
        formData
      );
      fetchTemplateMessages();
      messageToAdd = null;
    } catch (error) {
      console.error(error);
      messageToAdd = null;
    }
  };

  const sendMessageOrFile = async (templateMessage: ITemplateMessage) => {
    if (templateMessage.fileable && templateMessage.imageUrl) {
      const res = await fetch(templateMessage.imageUrl);
      const blob = await res.blob();
      file = new File([blob], "fileeee.jpg");
      $dialogData.data.uploadFile(file);
      // $dialogData.data.sendMessage(file);
    } else $payload = templateMessage.text;
    $dialogData.type = "";
    $dialogData.data = {};
  };

  // const dragFunction = async (data, itemIndex) => {
  //   const firstIndex = templateMessages.findIndex(
  //     (ex) => ex.id === data.itemIndex
  //   );

  //   const secondIndex = templateMessages.findIndex((ex) => ex.id === itemIndex);

  //   const tmpExercise = {
  //     ...templateMessages[firstIndex],
  //   };

  //   templateMessages[firstIndex] = templateMessages[secondIndex];
  //   templateMessages[secondIndex] = tmpExercise;

  //   const tmpPosition = templateMessages[firstIndex].position;

  //   templateMessages[firstIndex].position =
  //     templateMessages[secondIndex].position;
  //   templateMessages[secondIndex].position = tmpPosition;
  //   templateMessages = templateMessages;

  //   await changeOrder();

  //   dragItemId = null;
  // };

  // const onDragenter = (id: number): void => {
  //   dragItemId = id;
  // };

  // const onDragleave = (): void => {
  //   dragItemId = null;
  // };

  // const onDrop = (event: DragEvent, id: number): void => {
  //   drop(event, id, dragFunction);
  // };

  // const onDragstart = (event: DragEvent, id: number): void => {
  //   dragStart(event, id);
  // };

  onMount(() => {
    fetchTemplateMessages();
  });
</script>

<div class="flex flex-col items-center gap-2">
  <div
    class="flex flex-row w-full justify-center sticky inset-0 bg-white dark:bg-zinc-800 p-3 pt-5 z-10"
  >
    <ButtonComponent
      isPill
      on:click={() => {
        filter = "messages";
        fetchTemplateMessages();
      }}
    >
      <Svg name="message" size={19} customColor={"bg-white"} />
    </ButtonComponent>
    <ButtonComponent
      isPill
      on:click={() => {
        filter = "images";
        fetchTemplateMessages();
      }}
    >
      <Svg name="camera" size={19} customColor={"bg-white"} />
    </ButtonComponent>
    <ButtonComponent
      isPill
      on:click={() => {
        filter = "links";
        fetchTemplateMessages();
      }}
    >
      <Svg name="text" size={19} customColor={"bg-white"} />
    </ButtonComponent>
  </div>
  {#if messageToAdd !== null}
    <input
      class="w-4/5 border-none"
      placeholder={translate("TYPE_HERE")}
      type="text"
      bind:value={messageToAdd}
    />
    <Divider width={"80%"} />
  {/if}
  <!-- <div class="flex flex-col gap-2"> -->
  {#each templateMessages as templateMessage}
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <!-- ondragover="return false"
      on:dragenter={() => onDragenter(templateMessage.id)}
      on:dragleave={onDragleave}
      on:drop={(e) => onDrop(e, templateMessage.id)}
      on:dragstart={(e) => onDragstart(e, templateMessage.id)} -->
    <div
      class="flex flex-row w-3/4 gap-3 items-center justify-between"
      draggable={true}
    >
      <Svg name="drag" size={16} />

      {#if templateMessage.fileable}
        <img
          alt="Template Message"
          class="w-20 h-20"
          src={templateMessage.imageUrl}
        />
      {:else}
        <div class="w-48 text-xxs text-justify">
          {templateMessage.text}
        </div>
      {/if}
      <div
        class="flex gap-3"
        class:flex-col={templateMessage.text &&
          templateMessage.text.length > 64}
      >
        <div
          tabindex="0"
          role="button"
          on:click={() => (deleteMessageId = templateMessage.id ?? null)}
          on:keypress={() => (deleteMessageId = templateMessage.id ?? null)}
        >
          <Svg name="delete" size={24} customColor="bg-red-500" />
          <!-- myClass="mr-3" -->
        </div>
        <div
          tabindex="0"
          role="button"
          on:click={() => sendMessageOrFile(templateMessage)}
          on:keypress={() => sendMessageOrFile(templateMessage)}
        >
          <Svg
            name="right-arrow-button"
            size={24}
            customColor="bg-primary-500 dark:bg-primary-500"
          />
        </div>
      </div>
    </div>
    <Divider width={"80%"} />
  {/each}
  <!-- </div> -->
  <div
    class="flex flex-row sticky w-full justify-center inset-0 bg-white dark:bg-zinc-800 p-5"
  >
    {#if deleteMessageId}
      <ButtonComponent
        myClasses="mr-1"
        customColor="bg-red-500"
        on:click={deleteTemplateMessage}
      >
        {translate("DELETE_TEMPLATE_MESSAGE")}</ButtonComponent
      >
      <ButtonComponent
        myClasses="ml-1"
        on:click={() => (deleteMessageId = null)}
      >
        {translate("CANCEL")}</ButtonComponent
      >
    {/if}
    {#if messageToAdd}
      <ButtonComponent on:click={addTemplateMessage}>
        {translate("ADD_TEMPLATE_MESSAGE")}</ButtonComponent
      >
      <ButtonComponent
        customColor="bg-red-500"
        myClasses="ml-1"
        on:click={() => (messageToAdd = null)}
      >
        {translate("CANCEL")}</ButtonComponent
      >
    {/if}
    {#if !deleteMessageId && !messageToAdd}
      <ButtonComponent myClasses="mr-1" on:click={() => (messageToAdd = "")}
        >{translate("ADD_MESSAGE")}</ButtonComponent
      >
      <ButtonComponent myClasses="ml-1" on:click={() => fileInput.click()}
        >{translate("ADD_PHOTO")}</ButtonComponent
      >
      <input
        style="display:none"
        type="file"
        accept=".jpg, .jpeg, .png, .mp4"
        on:change={(e) => onFileSelected(e)}
        bind:this={fileInput}
      />
    {/if}
  </div>
</div>

<style>
  input:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>
