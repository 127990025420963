<script lang="ts">
  import { onMount } from "svelte";
  import { theme } from "../../stores/themeStore";

  let color: string = "#319795";

  onMount(() => {
    // color = $theme === "dark" ? "#6875f5" : "#319795";
  });
</script>

<svg
  viewBox="0 0 800 800"
  width={36}
  height={36}
  xmlns="http://www.w3.org/2000/svg"
>
  <circle
    class="spin"
    cx="400"
    cy="400"
    fill="none"
    r="200"
    stroke-width="50"
    stroke={color}
    stroke-dasharray="700 1400"
    stroke-linecap="round"
  />
</svg>

<style>
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .spin {
    transform-origin: center;
    animation: spin 1s linear infinite;
  }
</style>
