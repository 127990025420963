import type {Ingredient, Recipe} from "interfaces";

interface MacroPercentages {
  carbs: string;
  protein: string;
  fats: string;
}

const calculateMacroPercentages = (item: Ingredient | Recipe): MacroPercentages => {
  const {carbs, protein, fats, calories} = item;

  if (!calories) {
    return {
      carbs: "0",
      protein: "0",
      fats: "0"
    };
  }

  return {
    carbs: carbs ? (((carbs * 4) / calories) * 100).toFixed(0) : "0",
    protein: protein ? (((protein * 4) / calories) * 100).toFixed(0): "0",
    fats: fats ? (((fats * 9) / calories) * 100).toFixed(0): "0"
  };
};

export {calculateMacroPercentages};
