<script lang="ts">
  export let data: any;
</script>

<div class="flex-col" style="align-items: center">
  <img src={data.src} width="100%" alt="Gallery Progress" />
</div>

<style>
</style>
