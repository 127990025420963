import { language } from "../stores/languageStore";
import { get } from "svelte/store";

const options: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const parseDateWithTime = (dateString: string) => {
  const date = new Date(dateString);
  const lang = get(language) === "en" ? "en" : "sr-Latn-RS";
  // if (lang === "sr-Latn-RS") date.setHours(date.getHours() - 2);
  return date.toLocaleDateString(lang, options);
};

export { parseDateWithTime };
