<script lang="ts">
  import { onMount } from "svelte";
  import { postWithJwt, patchWithJwt, serverlessRoutes, translate } from "lib";
  import { currentClient, dialogData, showAlert, trainerClients } from "stores";
  import DatePicker from "../../../components/UI/DatePicker.svelte";
  import Input from "../../../components/UI/Input.svelte";
  import { remindersMap } from "../../../stores/trainerStores";
  import { ButtonComponent, InputField } from "ui";

  let data: any;

  let form = {
    name: { value: "", error: "" },
    notificationDate: { value: "", error: "" },
  };

  let disabled = true;
  let isLoading = false;

  const onInput = (field: keyof typeof form): void => {
    const { value } = form[field];

    if (field === "name") {
      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;
    const reminder = {
      note: form.name.value,
      date: form.notificationDate.value,
      clientId: data.clientId,
      userId: data.clientId,
    };

    if (data.id) editReminder(reminder);
    else createReminder(reminder);
  };

  const createReminder = async (reminder) => {
    try {
      const res = await postWithJwt(`${serverlessRoutes.REMINDERS}`, reminder);

      const insertedReminder = { id: res.data[0], ...reminder };
      if ($remindersMap[insertedReminder.userId]) {
        $remindersMap[insertedReminder.userId] = {
          ...$remindersMap[insertedReminder.userId],
          ...insertedReminder,
        };
      } else {
        $remindersMap[insertedReminder.userId] = [insertedReminder];
      }
      $trainerClients = $trainerClients;
      $dialogData.data = {};
      $dialogData.type = "";
      $showAlert.color = "black";
      $showAlert.message = `${translate("SUCCESSFULLY_CREATED")} ${translate("REMINDER").toLowerCase()}`;
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = `There was an error creating.`;
    }
  };

  const editReminder = async (reminder) => {
    reminder = { ...reminder, id: data.id };
    try {
      const res = await patchWithJwt(`${serverlessRoutes.REMINDERS}`, {
        reminder,
      });

      if (res.data === 1) {
        $remindersMap[$currentClient.id] = $remindersMap[$currentClient.id].map(
          (r) => {
            if (r.id === data.id) {
              return { ...r, ...reminder };
            }
            return r;
          }
        );
        $trainerClients = $trainerClients;
        $dialogData.data = {};
        $dialogData.type = "";
        $showAlert.message = `${translate("SUCCESSFULLY_EDITED")} ${translate("REMINDER").toLowerCase()}`;
      }
    } catch (error) {
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = `There was an error creating.`;
    }
  };

  onMount((): void => {
    if (data.id) {
      form.name.value = data.note;
      form.notificationDate.value = data.date.slice(0, 10);
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <h3 class="text-lg font-semibold text-center">
    {#if data.id}
      {translate("EDIT_NEW_REMINDER")}
    {:else}
      {translate("CREATE_NEW_REMINDER")}
    {/if}
  </h3>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <Input
      name={"name"}
      label={"NAME"}
      placeholder="Uplati ratu"
      bind:value={form.name.value}
      {onInput}
    />
    {#if form.name.error}
      <span class="text-red-500 text-xs">{form.name.error}</span>
    {/if}

    <!-- <div> -->
    <InputField
      label={translate("NOTIFICATION_DATE")}
      type="date"
      error={form.notificationDate.error}
      bind:value={form.notificationDate.value}
      on:input={() => onInput("notificationDate")}
    />
    <!-- <DatePicker
        title="NOTIFICATION_DATE"
        bind:date={form.notificationDate.value}
      /> -->
    <!-- </div> -->

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <!-- <Spinner size="10" color="green" /> -->
      {:else}
        <ButtonComponent on:click={onSubmit} {disabled}>
          {#if data.id}
            {translate("EDIT")}
          {:else}
            {translate("CREATE")}
          {/if}
        </ButtonComponent>
      {/if}
    </div>
  </form>
</div>
