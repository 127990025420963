<script lang="ts">
  import { onMount } from "svelte";
  import { translate } from "lib";

  interface MacroPercentages {
    carbs: string;
    protein: string;
    fats: string;
  }

  let carbs: number;
  let protein: number;
  let fats: number;
  let calories: number;
  let isBarVisible = false;
  let isCaloriesVisible = false;

  let percentages: MacroPercentages = {
    carbs: "0",
    protein: "0",
    fats: "0",
  };

  const calculatePercentages = (): MacroPercentages => {
    if (calories === 0) {
      return {
        carbs: "0",
        protein: "0",
        fats: "0",
      };
    }

    return {
      carbs: (((carbs * 4) / calories) * 100).toFixed(),
      protein: (((protein * 4) / calories) * 100).toFixed(),
      fats: (((fats * 9) / calories) * 100).toFixed(),
    };
  };

  onMount((): void => {
    percentages = calculatePercentages();
  });

  export { carbs, protein, fats, calories, isBarVisible, isCaloriesVisible };
</script>

<div class="flex flex-col gap-1">
  <div
    class="
      flex
      items-center
      justify-evenly
      leading-tight
      text-center
      text-xxs
    "
  >

    <div>
      <div>{translate("CARBS")}</div>
      <div class="text-sm text-emerald-400">
        {carbs ? Math.round(carbs) : 0} ({percentages.carbs !== "NaN" ? percentages.carbs : 0}%)
      </div>
    </div>

    <div>
      <div>{translate("PROTEIN")}</div>
      <div class="text-sm text-sky-400">
        {protein ? Math.round(protein) : 0} ({percentages.protein !== "NaN" ? percentages.protein : 0}%)
      </div>
    </div>

    <div>
      <div>{translate("FATS")}</div>
      <div class="text-sm text-rose-400">
        {fats ? Math.round(fats) : 0} ({percentages.fats !== "NaN" ? percentages.fats : 0}%)
      </div>
    </div>

    {#if isCaloriesVisible}
      <div>
        <div>{translate("CALORIES")}</div>
        <div class="text-sm text-amber-400">
          {calories ? Math.round(calories) : 0}
        </div>
      </div>
    {/if}

  </div>

  {#if isBarVisible}
    <div class="flex">
      <div class="h-[2px] bg-emerald-400" style:flex-basis="{percentages.carbs}%" />
      <div class="h-[2px] bg-sky-400" style:flex-basis="{percentages.protein}%" />
      <div class="h-[2px] bg-rose-400" style:flex-basis="{percentages.fats}%" />
    </div>
  {/if}
</div>
