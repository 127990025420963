import { api } from "./constants";
import { getWithJwt } from "./requests";
import { tags } from "../stores/trainerStores";
import { user } from "stores";
import { get } from "svelte/store";
import { isClient } from "./roles";

export const fetchTags = async () => {
  const currentUser = get(user);
  if (isClient(currentUser)) return;
  const response = await getWithJwt(`${api}/client_tag`);
  tags.set(response);
};
