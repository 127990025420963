<script lang="ts">
  import { deleteWithJwt, dialogTypes, serverlessRoutes } from "lib";
  import { dialogData, selectedIds } from "stores";

  const isCloseableClass = $dialogData.isCloseable
    ? "bg-black/50"
    : "backdrop-blur";

  const onClose = async () => {
    const condition =
      $dialogData.isCloseable != null ? $dialogData.isCloseable : true;

    if (condition) {
      // if ($dialogData.type === dialogTypes.CREATE_EDIT_RECIPE) {
      //   console.log("User closed create recipe dialog");
      //   const {error, data} = await deleteWithJwt(serverlessRoutes.FILE, {
      //     imageId: ""
      //   });
      // }

      $selectedIds = [];

      $dialogData.type = "";
      $dialogData.data = {};
      $dialogData.isCloseable = true;
    }
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  id="overlay"
  class="fixed h-full w-full top-0 left-0 z-[2] {isCloseableClass}"
  on:click={onClose}
></div>
