<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { translate } from "lib";
  import { isClient } from "../../lib/roles";
  import { dialogData, editingSupersetID, user } from "stores";
  import type { Action } from "svelte/action";
    import { Svg } from "ui";

  const dispatch = createEventDispatcher();

  let type;
  let sets;
  let reps;
  let rest;
  let time;
  let distance;
  let isSupersetExercise = false;

  const editMode = {
    time: false,
    sets: false,
    rest: false,
    distance: false,
    reps: false,
  };

  const inputs = {
    time: 0,
    sets: 0,
    rest: 0,
    distance: 0,
    reps: 0,
  };

  const onEnableInput = (key: keyof typeof editMode): void => {
    if ($user && !isClient($user) && $dialogData.type === "") {
      editMode[key] = true;
      dispatch("toggleOverlay", true);
    }
  };

  const onDisableInput = (key: keyof typeof editMode): void => {
    editMode[key] = false;

    dispatch("toggleOverlay", false);

    if (
      $editingSupersetID !== null ||
      inputs[key] === undefined ||
      inputs[key] === null ||
      inputs[key] === "" ||
      (key === "sets" && sets === inputs[key]) ||
      (key === "reps" && reps === inputs[key]) ||
      (key === "rest" && rest === inputs[key]) ||
      (key === "time" && time === inputs[key]) ||
      (key === "distance" && distance === inputs[key])
    ) {
      return;
    }

    let details;

    if (type && type.includes("Timed")) {
      details = {
        time,
        sets,
        rest,
        [key]: inputs[key],
      };
    } else if (!type || type === "Strength" || type === "Endurance") {
      details = {
        sets,
        rest,
        reps,
        [key]: inputs[key],
      };
    } else {
      details = {
        time,
        distance,
        [key]: inputs[key],
      };
    }

    dispatch("updateExerciseDetails", { details });
  };

  const focusOnMount: Action<HTMLInputElement> = (node): void => {
    node.focus();
  };

  onMount((): void => {
    inputs.time = time;
    inputs.sets = sets;
    inputs.rest = rest;
    inputs.distance = distance;
    inputs.reps = reps;
  });

  export { type, sets, reps, rest, time, distance, isSupersetExercise };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="flex items-center justify-around text-center text-xs text-slate-700 dark:text-slate-200"
>
  {#if type?.includes("Timed")}
    {#if time}
      <div class="leading-tight" on:click|stopPropagation={() => onEnableInput("time")}>
        <div class="text-xxs">{translate("TIME")}</div>
        {#if editMode["time"]}
          <input
            use:focusOnMount
            class="z-1001 h-4 w-10 p-0 text-center font-bold border-t-0 border-x-0 border-b-1 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500 dark:bg-zinc-800"
            type="text"
            bind:value={inputs["time"]}
            on:focusout={() => onDisableInput("time")}
          />
        {:else}
          <div class="text-xxs font-bold">{time}</div>
        {/if}
      </div>
    {/if}
    {#if !isSupersetExercise}
      {#if sets}
        <div class="leading-tight" on:click|stopPropagation={() => onEnableInput("sets")}>
          <div class="text-xxs">{translate("SETS")}</div>
          {#if editMode["sets"]}
            <input
              use:focusOnMount
              type="number"
              class="z-1001 h-4 w-10 p-0 text-center font-bold border-t-0 border-x-0 border-b-1 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500 dark:bg-zinc-800"
              bind:value={inputs["sets"]}
              on:focusout={() => onDisableInput("sets")}
            />
          {:else}
            <div class="text-xxs font-bold">{sets}</div>
          {/if}
        </div>
      {/if}
    {/if}
    {#if !isSupersetExercise}
      {#if rest}
        <div class="leading-tight" on:click|stopPropagation={() => onEnableInput("rest")}>
          <div class="text-xxs">{translate("REST")}</div>
          {#if editMode["rest"]}
            <input
              use:focusOnMount
              type="text"
              class="z-1001 h-4 w-10 p-0 text-center font-bold border-t-0 border-x-0 border-b-1 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500 dark:bg-zinc-800"
              bind:value={inputs["rest"]}
              on:focusout={() => onDisableInput("rest")}
            />
          {:else}
            <div class="text-xxs font-bold">{rest}</div>
          {/if}
        </div>
      {/if}
    {/if}
  {:else if type === "Cardio"}
    {#if time}
      <div class="leading-tight" on:click|stopPropagation={() => onEnableInput("time")}>
        <div class="text-xxs">{translate("TIME")}</div>
        {#if editMode["time"]}
          <input
            use:focusOnMount
            class="z-1001 h-4 w-10 p-0 text-center font-bold border-t-0 border-x-0 border-b-1 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500 dark:bg-zinc-800"
            type="text"
            bind:value={inputs["time"]}
            on:focusout={() => onDisableInput("time")}
          />
        {:else}
          <div class="text-xxs font-bold">{time}</div>
        {/if}
      </div>
    {/if}
    {#if distance}
      <div class="leading-tight" on:click|stopPropagation={() => onEnableInput("distance")}>
        <div class="text-xxs">{translate("DISTANCE")}</div>
        {#if editMode["distance"]}
          <input
            use:focusOnMount
            class="z-1001 h-4 w-10 p-0 text-center font-bold border-t-0 border-x-0 border-b-1 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500 dark:bg-zinc-800"
            type="text"
            bind:value={inputs["distance"]}
            on:focusout={() => onDisableInput("distance")}
          />
        {:else}
          <div class="text-xxs font-bold">{distance}</div>
        {/if}
      </div>
    {/if}
  {:else}
    {#if reps}
      <div class="leading-tight" on:click|stopPropagation={() => onEnableInput("reps")}>
        <div class="text-xxs">{translate("REPS")}</div>
        {#if editMode["reps"] || $editingSupersetID !== null}
          <input
            use:focusOnMount
            class="z-1001 h-4 w-10 p-0 text-center font-bold border-t-0 border-x-0 border-b-1 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500 dark:bg-zinc-800"
            type="number"
            bind:value={inputs["reps"]}
            on:focusout={() => onDisableInput("reps")}
          />
        {:else}
          <div class="text-xxs font-bold">{reps}</div>
        {/if}
      </div>
    {/if}
    {#if !isSupersetExercise && $editingSupersetID === null}
      {#if sets}
        <div class="leading-tight" on:click|stopPropagation={() => onEnableInput("sets")}>
          <div class="text-xxs">{translate("SETS")}</div>
          {#if editMode["sets"]}
            <input
              use:focusOnMount
              type="number"
              class="z-1001 h-4 w-10 p-0 text-center font-bold border-t-0 border-x-0 border-b-1 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500 dark:bg-zinc-800"
              bind:value={inputs["sets"]}
              on:focusout={() => onDisableInput("sets")}
            />
          {:else}
            <div class="text-xxs font-bold">{sets}</div>
          {/if}
        </div>
      {/if}
    {/if}
    {#if !isSupersetExercise && $editingSupersetID === null}
      {#if rest}
        <div class="leading-tight" on:click|stopPropagation={() => onEnableInput("rest")}>
          <div class="text-xxs">{translate("REST")}</div>
          {#if editMode["rest"]}
            <input
              use:focusOnMount
              type="text"
              class="z-1001 h-4 w-10 p-0 text-center font-bold border-t-0 border-x-0 border-b-1 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500 dark:bg-zinc-800"
              bind:value={inputs["rest"]}
              on:focusout={() => onDisableInput("rest")}
            />
          {:else}
            <div class="text-xxs font-bold">{rest}</div>
          {/if}
        </div>
      {/if}
    {/if}
  {/if}
</div>

<style>
  input:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>
