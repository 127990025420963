import {writable} from "svelte/store";

const selectedIdsStoreCreate = () => {
  const {set, subscribe, update} = writable<Array<number>>([]);

  const add = (id: number): void => {
    update((store) => {
      store.push(id);
      return store;
    });
  };

  const remove = (id: number): void => {
    update((store) => {
      store = store.filter((_id): boolean => _id !== id);
      return store;
    });
  };

  const clear = (): void => {
    set([]);
  };

  return {set, subscribe, update, add, remove, clear};
};

const selectedIdsStore = selectedIdsStoreCreate();

export {selectedIdsStore};
