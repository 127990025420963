import { getWithJwt } from "lib";

export const fetchCloudflareStreamsVideo = async (uid: string) => {
  const fetchUrl = `https://streams.trainme-api.workers.dev/api/video?videoId=${uid}`;
  try {
    const res = await getWithJwt(fetchUrl);
    return res;
  } catch (err) {
    console.error(err);
  }
};
