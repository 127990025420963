import {writable} from "svelte/store";

interface Filters {
  template: 1 | 0 | undefined;
  sort: string | undefined;
  macroRatio: {carbs: number, protein: number, fats: number} | undefined;
  tags: Array<string>;
}

const filtersStore2 = writable<Filters>({
  template: 1,
  sort: undefined,
  macroRatio: undefined,
  tags: []
});

export {filtersStore2};
