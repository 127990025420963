<script lang="ts">
  import { onMount } from "svelte";
  import { translate } from "../../lib/translate";
  import InsertGalleryEntry from "../Progress/InsertGalleryEntry.svelte";
  export let data: any;
  let selectedDate: string = "";
  let maxDate: string = new Date().toISOString().slice(0, 10);
  onMount(() => {
    selectedDate = data.date ? data.date : "";
  });
</script>

<div class="flex-col" style="align-items: center">
  <h3 class="mt-5 mg-4">
    {translate("ADD_PROGRESS_PHOTOS")}
  </h3>
  <p class="mt-2 mb-4">{translate("PICK_DATE")}</p>
  <input
    type="date"
    id="start"
    name="trip-start"
    bind:value={selectedDate}
    min="2010-01-01"
    max={maxDate}
    class="bg-white dark:bg-slate-800 text-slate-900 dark:text-slate-100"
  />
  <br />
  {#if selectedDate !== ""}
    <InsertGalleryEntry date={selectedDate} />
  {/if}
</div>

<style>
</style>