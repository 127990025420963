<script lang="ts">
  import { onMount } from "svelte";
  import { Button, Heading, Spinner } from "flowbite-svelte";
  import { api, putWithJwt, translate } from "lib";
  import { currentClient, dialogData, showAlert, trainerClients } from "stores";
  import { ButtonComponent, InputField } from "ui";
  import DatePicker from "../../UI/DatePicker.svelte";

  let data: any;

  let form = {
    date: { value: "", error: "" },
  };

  let disabled = false;
  let isLoading = false;

  const onInput = (field: keyof typeof form): void => {
    // disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;
    const url = `${api}/trainer/client/${$currentClient.id}`;

    try {
      const newExpireDate = new Date(form.date.value);
      const res = putWithJwt(url, { user: { expireAt: newExpireDate } });
      $currentClient.expireAt = new Date(form.date.value);
      const client = $trainerClients.find(
        (client): boolean => client.id === $currentClient.id
      );
      if (client) {
        client.expireAt = newExpireDate;
      }
      $trainerClients = $trainerClients;
      $dialogData.data = {};
      $dialogData.type = "";
      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_UPDATED_EXPIRE_DATE");
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = `There was an error creating header.`;
    }
  };

  onMount((): void => {
    const defaultDate = new Date().toISOString().slice(0, 10);

    if (data.client && data.client.expireAt !== null) {
      form.date.value = data.client.expireAt?.split("T")[0] || defaultDate;
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">Promeni datum</Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <!-- <div>
      <DatePicker title="EXPIRATION_DATE" bind:date={form.date.value} />
    </div> -->
    <InputField
      label={translate("EXPIRATION_DATE")}
      type="date"
      error={form.date.error}
      bind:value={form.date.value}
      on:input={() => onInput("date")}
    />

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent type="submit" {disabled}>{translate("EDIT")}</ButtonComponent>
      {/if}
    </div>
  </form>
</div>
