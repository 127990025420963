<script lang="ts">
  import { onMount } from "svelte";
  import { api, postWithJwt, putWithJwt, translate } from "lib";
  import { dialogData, showAlert, user } from "stores";
  import { ButtonComponent, TextareaField } from "ui";

  export let data: any;

  let disabled = true;
  let isLoading = false;

  const form = {
    text: { value: "", error: "" },
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    try {
      const response = await putWithJwt(`${api}/comment/${data.comment.id}`, {
        text: form.text.value
      });

      data.executeFunction(response);

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")}
        ${translate("COMMENT").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      isLoading = false;
      console.error(error);
    }
  };

  const onInput = (key: keyof typeof form): void => {
    if (key === "text") {
      if (form.text.value === "") {
        form.text.error = "FIELD_REQUIRED";
      } else if (form.text.value.length > 320) {
        form.text.error = "FIELD_MAXIMUM_320";
      } else {
        form.text.error = "";
      }
    }

    disabled = Object.values(form).some(({error}) => error !== "");
  };

  onMount((): void => {
    if (data.comment) {
      form.text.value = data.comment.text;
      onInput("text");
    }
  });
</script>

<div class="p-4 flex flex-col gap-4">
  <h3 class="text-xxl font-medium text-center">
    Izmeni komentar
  </h3>
  <TextareaField
    id="text"
    label={translate("TEXT")}
    error={translate(form.text.error)}
    bind:value={form.text.value}
    on:input={() => onInput("text")}
  />
  <div class="flex justify-center">
    <ButtonComponent on:click={onSubmit} {isLoading} {disabled}>
      {translate("SUBMIT")}
    </ButtonComponent>
  </div>
</div>
