<script lang="ts">
  import { api } from "../../lib/constants";
  import { translate } from "../../lib/translate";
  import { showAlert } from "../../stores/showAlertStore";
  import Svg from "../UI/Svg.svelte";
  import { fly } from "svelte/transition";
  import { user } from "../../stores/userStore";
  import { postFormDataWithJwt } from "../../lib/requests";
  import { refetchGallery } from "../../stores/refetchStore";
  import { onMount } from "svelte";
  import { clientGalleryEntries } from "../../stores/clientStores";
  import * as Sentry from "@sentry/browser";
  import Spinner from "../UI/Spinner.svelte";
  import { location } from "svelte-spa-router";

  let unique = {};
  let isLoading: boolean = false;

  interface GalleryEntryFiles {
    front: File;
    back: File;
    side: File;
  }

  export let date;
  let selected = "front";
  let fileInput;
  let files: GalleryEntryFiles = { front: null, back: null, side: null };
  let alreadyUploadedFiles;

  const animate = () => {
    unique = {};
  };

  const onFileSelected = (e, side) => {
    files[side] = new File([e.target.files[0]], selected);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      files[side].htmlRender = fileReader.result;
    };
    fileReader.readAsDataURL(e.target.files[0]);
    uploadPhoto();
  };

  const back = () => {
    animate();
    selected =
      selected === "front" ? "side" : selected === "back" ? "front" : "back";
  };

  const forward = () => {
    animate();
    selected =
      selected === "front" ? "back" : selected === "back" ? "side" : "front";
  };

  const uploadPhoto = async () => {
    isLoading = true;
    const userId = $location.includes("profile")
      ? $location.split("/")[2]
      : $user.id;
    $showAlert.message = `${translate("PHOTO_IS_UPLOADING")}`;
    const url = `${api}/gallery?date="${new Date(
      date
    ).toISOString()}"&clientId=${userId}`;
    const formData = new FormData();
    formData.append("files[]", files[selected]);
    try {
      await postFormDataWithJwt(url, formData);
      let parsedSelect = `${selected.toUpperCase()}_POSE`;
      $showAlert.message = `${translate(parsedSelect)} ${translate(
        "SUCCESSFULLY_UPLOADED"
      ).toLowerCase()}.`;
      $refetchGallery = true;
      isLoading = false;
      forward();
      // fetchUser();
    } catch (err: any) {
      isLoading = false;
      Sentry.captureException(err);
    }
  };

  onMount(() => {
    const alreadyUploaded = $clientGalleryEntries.find(
      (galleryEntry) => galleryEntry.date.slice(0, 10) === date
    );
    if (alreadyUploaded) {
      const front = alreadyUploaded.gallery.files.find(
        (file) => file.name === "front.png"
      );
      const back = alreadyUploaded.gallery.files.find(
        (file) => file.name === "back.png"
      );
      const side = alreadyUploaded.gallery.files.find(
        (file) => file.name === "side.png"
      );
      alreadyUploadedFiles = { front, side, back };
    }
  });
</script>

<div class="flex-col center-center">
  <!-- <span class="mgl-24">{parseDate(date)}</span> -->
  <h3>{translate(`${selected.toUpperCase()}_POSE`)}</h3>
  {#if isLoading}
    <Spinner height={"40vh"} />
  {:else}
    <div class="flex-row center-center mt-3 mgb-16">
      <div on:click={() => back()} on:keypress={() => back()}>
        <Svg name="circle-left" size={32} />
      </div>
      <div
        class="flex-row center-center"
        on:click={() => fileInput.click()}
        on:keypress={() => fileInput.click()}
      >
        <input
          style="display:none"
          type="file"
          on:change={(e) => onFileSelected(e, selected)}
          bind:this={fileInput}
        />
        {#key unique}
          <img
            in:fly={{ x: 1000 }}
            src={alreadyUploadedFiles
              ? alreadyUploadedFiles[selected]?.url
              : files[selected]?.htmlRender
                ? files[selected].htmlRender
                : "/logo.png"}
            on:error={(el) => {
              el.target.src = "/logo.png";
            }}
            alt="Front Pose"
            class="gallery-entry-image"
          />
        {/key}
      </div>
      <div on:click={() => forward()} on:keypress={() => forward()}>
        <Svg name="circle-right" size={32} />
      </div>
    </div>
  {/if}
  <p class="mgb-16 text-smaller">*{translate("GALLERY_TUTORIAL")}</p>
</div>

<style>
  .gallery-entry-image {
    width: 92%;
    max-height: 448px;
  }
</style>
