import { user } from "../stores/userStore";
import { serverlessRoutes } from "./constants";
import { getWithJwt } from "./requests";

export const fetchUser = async () => {
  const url = serverlessRoutes.FETCH_USER;
  try {
    const fetchedUser = await getWithJwt(url);
    user.set(fetchedUser);
    localStorage.setItem("role", fetchedUser.roleId);
    return fetchedUser;
  } catch (err) {}
};
