import type {Ingredient, Meal, PartialItemMap, Recipe} from "interfaces";

type Item = Ingredient | Recipe | Meal;

const sortItems = (
  a: Item,
  b: Item
): number => (a.position || 0) - (b.position || 0);

const getNewPositions = <I extends Item>(items: Array<I>): PartialItemMap<I> | null => {
  const oldPositions = items
    .map(({id, position}) => ({id, position}))
    .sort((a, b) => (a.position || 0) - (b.position || 0));

  const newPositions = items.map(
    ({id}, position) => ({id, position})
  );

  const oldPositionsStr = JSON.stringify(oldPositions);
  const newPositionsStr = JSON.stringify(newPositions);

  if (oldPositionsStr === newPositionsStr) {
    return null;
  }

  const partialItemMap: PartialItemMap<I> = {};

  newPositions.forEach(({id, position}): void => {
    partialItemMap[id] = {position};
  });

  return partialItemMap;
};

export {sortItems, getNewPositions};
