import { language } from "../stores/languageStore";
import { get } from "svelte/store";

const en: object = {
  UPLOADING_FILE: "File is being uploaded...",
  HAS_BIRTHDAY_TODAY: "has birthday today",
  ADD: "Add",
  ADD_CLIENT: "Add client",
  ADD_PROGRAM: "Add program",
  ADD_WORKOUT: "Add workout",
  ADD_EXERCISE: "Add exercise",
  ADD_RECIPE: "Add recipe",
  ADD_RECIPES: "Add recipes",
  ADD_INGREDIENT: "Add ingredient",
  ADD_IMAGE: "Add image",
  AMOUNT: "Amount",
  ADDED: "Added",
  MESSAGES_SENT: "Messages sent",
  COMMITMENT: "Commitment",
  IMAGE: "Photo",
  FILE: "File",
  DELETE_ACCOUNT: "Delete account",
  MESSAGE: "Message",
  CONFIRM: "Confirm",
  SEND_MESSAGE_U: "Send message",
  CLOSE: "Close",
  CREATE_COPY: "Create copy",
  WELCOME_BACK: "Welcome back",
  OR_SIGN_IN_WITH_EMAIL: "Sign in with email",
  SIGN_IN: "Sign in",
  SIGN_OUT: "Sign out",
  SUCCESSFULLY_CREATED: "Successfully created",
  SUCCESSFULLY_SAVED: "Successfully saved",
  SUCCESSFULLY_EDITED: "Successfully edited",
  SUCCESSFULLY_DELETED: "Successfully deleted",
  ERROR_DELETING: "Error deleting",
  SUCCESSFULLY_COPIED: "Successfully copied",
  ERROR_COPYING: "Error copying",
  SUCCESSFULLY_IMPORTED: "Successfully imported",
  SUCCESSFULLY_UPLOADED: "Successfully uploaded",
  SUCCESSFULLY_COMPLETED: "Successfully completed",
  SUCCESSFULLY_UPDATED_VALUE: "Successfully updated value",
  SUCCESSFULLY_DELETED_VALUE: "Successfully deleted value",
  SUCCESSFULLY_CHANGED_ORDER: "Successfully changed order",

  TOTAL_SELECTED_CLEIENTS: "Number of selected clients",
  SELECT_ALL: "Select all",
  SELECT_LOADED: "Select loaded",

  SUCCESSFULLY_SAVED_TO_DATABASE: "Successfully saved to database",
  SUCCESSFULLY_SAVED_TO_JOURNAL: "Successfully saved to journal",

  SUCCESSFULLY_ASSIGNED_MEAL_PLANS:
    "Successfully assigned meal plans to client",
  SUCCESSFULLY_ASSIGNED_TRAINING_PROGRAM:
    "Successfully assigned training programs to client",

  SUCCESSFULLY_IMPORTED_RECIPES: "Successfully imported recipes",

  SUCCESSFULLY_CREATED_INGREDIENT: "Successfully created ingredient",
  SUCCESSFULLY_CREATED_RECIPE: "Successfully created recipe",
  SUCCESSFULLY_CREATED_MEAL: "Successfully created meal",
  SUCCESSFULLY_CREATED_MEAL_PLAN: "Successfully created meal plan",

  SUCCESSFULLY_COPIED_INGREDIENT: "Successfully copied ingredient",
  SUCCESSFULLY_COPIED_RECIPE: "Successfully copied recipe",
  SUCCESSFULLY_COPIED_MEAL: "Successfully copied meal",
  SUCCESSFULLY_COPIED_MEAL_PLAN: "Successfully copied meal plan",
  SUCCESSFULLY_COPIED_EXERCISE: "Successfully copied exercise",

  SUCCESSFULLY_EDITED_INGREDIENT: "Successfully edited ingredient",
  SUCCESSFULLY_EDITED_RECIPE: "Successfully edited recipe",
  SUCCESSFULLY_EDITED_MEAL: "Successfully edited meal",
  SUCCESSFULLY_EDITED_MEAL_PLAN: "Successfully edited meal plan",

  SUCCESSFULLY_DELETED_INGREDIENT: "Successfully deleted ingredient",
  SUCCESSFULLY_DELETED_RECIPE: "Successfully deleted recipe",
  SUCCESSFULLY_DELETED_MEAL: "Successfully deleted meal",
  SUCCESSFULLY_DELETED_MEAL_PLAN: "Successfully deleted meal plan",
  SUCCESSFULLY_DELETED_EXERCISE: "Successfully deleted exercise",

  SUCCESSFULLY_COPIED_TRAINING_PROGRAM:
    "Successfully created a copy of training program",

  JOINED_THE_CHAT: "joined the chat",
  DELETE: "Delete",
  DOWNLOAD: "Download",
  BACK: "Back",
  LOAD_MORE: "Load more",
  SELECT_INGREDIENTS: "Select ingredients",
  SET_AMOUNT: "Set amount",
  DARK: "Dark",
  LIGHT: "Light",
  PERCENT: "Percent",
  TYPE_HERE: "Type here...",
  WELCOME: "Welcome",
  ACCOUNT_EXPIRES_TOMORROW: "Your account expires tomorrow.",
  ASSIGNED_YOU_A_MEAL_PLAN: "assigned you a meal plan",
  ASSIGNED_YOU_A_WORKOUT_PLAN: "assigned you a training program",
  WELCOME_TO_APP_CLIENT:
    "Welcome! Click on the chat icon to contact your trainer.",
  NUMBER_OF_PROGRAMS: "Number of programs",
  NUMBER_OF_WORKOUTS: "Number of workouts",
  TOTAL_EXERCISES_COUNT: "Number of exercises",
  NUMBER_OF_TRAININGS: "Number of trainings",
  NUMBER_OF_ITEMS: "Number of items",
  PROFILE: "Profile",
  NOTIFICATIONS: "Notifications",
  CANCEL: "Cancel",
  LANGUAGE: "Language",
  THEME: "Theme",
  REPORT_A_BUG_SUGGESTION: "Report a bug/suggestion",
  HELP: "Help",
  TRAINING_PLAN: "Training plan",
  TRAINING_JOURNAL: "Training journal",
  EXPORT_TO_PDF: "Export to PDF",
  HABIT: "Habit",
  HABIT_U: "Habit",
  HABITS: "Habits",
  DEFAULT_HABITS: "Default habits",
  DEFAULT_MEASUREMENTS: "Default measurements",
  MEASUREMENTS: "Measurements",
  MEASUREMENT: "Measurement",
  MEASUREMENT_U: "Measurement",
  GOALS: "Goals",
  GALLERY: "Gallery",
  MEAL_PLAN: "Meal plan",
  MEAL_PLAN_TYPES: "Meal plan types",
  DIET_JOURNAL: "Diet journal",
  IF_TRACKING: "IF Tracking",
  REST: "Rest",
  SETS: "Sets",
  REPS: "Reps",
  WEIGHT: "Weight",
  CLIENT: "Client",
  CLIENTS: "Clients",
  CLIENTS_E: "Clients",
  CLIENTS_BY_DAY: "Clients by day",
  ALL_CLIENTS: "All clients",
  PAYMENT: "Uplata",
  PAYMENT_U: "Uplatu",
  PAYMENTS: "Payments",
  GROUPS: "Groups",
  GROUP: "Group",
  GROUP_U: "Group",
  GROUP_A: "Group",
  TRAINERS: "Trainers",
  REGISTERED: "Registered",
  SEARCH: "Search",
  MEASUREMENT_UNIT: "Measurement unit",
  FILTER: "Filter",
  SELECT: "Select",
  PROGRAMS: "Programs",
  COMPARE_PHOTOS: "Compare photos",
  SELECT_TWO_PHOTOS: "Select two photos",
  SELECT_ONE_MORE_PHOTO: "Select one more photo",
  COMPARE_PHOTOS_NOTE: "Selected photos will be displayed here",
  MY_PROGRAMS: "My programs",
  MY_EXERCISES: "My exercises",
  WORKOUTS: "Workout",
  EXERCISE: "Exercise",
  EXERCISES: "Exercises",
  EXERCISE_E: "Exercise",
  EXERCISE_U: "Exercise",
  TRAINING_PROGRAM: "Training program",
  TRAINING_PROGRAMS: "Training programs",
  MEAL_PLANS: "Meal plans",
  RECIPES: "Recipes",
  RECIPES_E: "Recipes",
  INGREDIENTS: "Ingredients",
  INGREDIENT_A: "Ingredient",
  INGREDIENT_U: "Ingredient",
  NOT_SET: "Not set",
  CARBS: "Carbs",
  FATS: "Fats",
  PROTEIN: "Protein",
  CALORIES: "Calories",
  GLASS: "Glass",
  NAME: "Name",
  EMAIL: "Email",
  MEAL: "Meal",
  PASSWORD: "Password",
  HABITU: "habit",
  EDIT_HABIT: "Edit habit",
  REPEAT_PASSWORD: "Repeat password",
  REPEAT_WORKOUT: "Repeat workout",
  UPDATE: "Update",
  CLICK_ON_IMAGE_TO_CHANGE: "Click on the image to change it",
  BREAK_SUPERSET: "Break superset",
  SUPERSET_BROKEN_SUCCESSFULLY: "Superset broken successfully",
  FINISH_WORKOUT: "Finish workout",
  COMMENT_HERE: "Type comment here",
  EXERCISE_COMMENTS: "Exercise comments",
  WORKOUT_COMMENTS: "Workout comments",
  EXPIRY: "Expiry",
  MEAL_COMMENTS: "Meal comments",
  TAKE_A_PHOTO: "Take a photo",
  ADD_PHOTOS: "Add photos",
  NO_NOTIFICATIONS: "No notifications available",
  TYPE_NOT_ASSIGNED: "Type not assigned",
  SUCCESSFULLY_UPDATED_TAGS: "Successfully updated tags",
  DESCRIPTION: "Description",
  ADD_NEW_PAYMENT: "Add new payment",
  REPORT_BUG_SUGGESTION: "Report a bug/suggestion",
  BUG_SUGGESTION: "Bug/suggestion report",
  NO_BOOKMARKED_MESSAGES_YET: "You haven't bookmarked any messages yet.",
  NO_CANT_DO_EXERCISES_YET: "You haven't added any can't do exercises yet.",
  SUBMIT: "Submit",
  BUG_SUGGESTION_EXAMPLE: `Can't upload a photo. \nUsing iPhone 12 Pro.`,
  SUGGESTED_MACROS: "Recommended daily intake",
  CREATE_GOAL: "Create goal",
  CREATE_TRAINER: "Create trainer",
  EDIT_GOAL: "Edit goal",
  SAVE_TO_JOURNAL: "Save to the jorunal",
  SAVE_TO_DATABASE: "Save to database",
  SAVED_TO_DATABASE: "Saved to database",
  NEXT_STEP: "Next step",
  ADD_PROGRESS_PHOTOS: "Add progress photos",
  PICK_DATE: "Select the date",
  PREVIOUS_STEP: "Previous step",
  SUCCESSFULLY_FINISHED_WORKOUT: "Workout finished successfully",
  PHOTO_IS_UPLOADING: "Uploading photo, please wait.",
  FINISH_WORKOUT_FAILED: "Finish workout failed",
  ITEM_COUNT: "Item count",
  IN: "in",
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
  SUNDAY: "Sunday",
  WEEK: "Week",
  MONTH: "Month",
  CHAT_HELPER: "Chat helper",
  SHOW_CHAT_HELPER: "Show chat helper",
  CLIENT_NOTES: "Client notes",
  THREE_MONTHS: "Three Months",
  SHARED_CONTENT: "Shared content",
  FRONT_POSE: "Front pose",
  BACK_POSE: "Back pose",
  SIDE_POSE: "Side pose",
  G: "g",
  HANDFULL: "handfulls",
  ML: "ml",
  ONEOF: "oneof",
  PINCH: "pinch of",
  TABLESPOON: "tablespoons",
  TEASPOON: "teaspons",
  GALLERY_TUTORIAL: "Change/update photo by clicking on logo/photo.",
  RATE_WORKOUT: "Rate workout",
  NO_EFFORT: "No effort",
  VERY_EASY: "Very easy",
  SOMEWHAT_EASY: "Somewhat easy",
  EASY: "Easy",
  MEDIUM: "Moderate",
  SOMEWHAT_HARD: "Somewhat hard",
  ORDER_CHANGED_SUCCESSFULLY: "Order changed successfully",
  HARD: "Hard",
  HAS_FINISHED_ALL_WORKOUTS: "has finished all workouts",
  ACCOUNT: "Account",
  SUCCESSFULLY_BOOKMARKED_MESSAGE: "Successfully bookmarked message",
  TRAINING: "Training",
  SUPERSET_DELETED_SUCCESSFULLY: "Superset deleted successfully",
  VERY_HARD: "Really hard",
  TOO_HARD: "Very hard",
  ADVANCED_SETTINGS: "Advanced settings",
  EXTREMELY_HARD: "Extremely hard",
  EXERCISE_HISTORY: "Exercise history",
  HABIT_UPDATED_SUCCESSFULLY: "Habit updated successfully",
  EDIT: "Edit",
  EDIT_WORKOUT: "Edit workout",
  START_DATE: "Start date",
  ADD_MESSAGE: "Add message",
  END_DATE: "End date",
  ADD_MEAL: "Add meal",
  ADD_GROUP: "Add group",
  DINNER: "Dinner",
  CREATE_MEAL: "Create meal",
  RESET: "Reset",
  RESET_PASSWORD: "Reset password",
  CREATE_GROUP: "Create group",
  CREATE_TYPE: "Create type",
  EDIT_GROUP: "Edit group",
  TITLE: "Title",
  DATE: "Date",
  CREATE: "Create",
  ADD_PHOTO: "Add photo",
  ENTER_RESULTS: "Enter results",
  ENTER_RESULTS_FOR: "Enter results for",
  RESULTS_ENTERED_SUCCESSFULLY: "Results entered successfully",
  RECORD_DATE: "Record date",
  PERSONAL_RECORD: "Personal record",
  HISTORY: "History",
  NO_EXERCISE_HISTORY: "No history for this exercise",
  WORKOUT_HISTORY: "Workout history",
  WORKOUT: "Workout",
  PHOTO: "Fotografija",
  SELECT_MEASUREMENT: "Select measurement",

  SELECT_CLIENTS: "Select clients",
  CLIENTS_PREVIEW: "Clients preview",
  USER_IS_TRAINER: "User is a trainer",
  GROUP_SETTINGS: "Group settings",
  CLIENT_SETTINGS: "Client settings",
  NOTE_UPDATED_SUCCESSFULLY: "Note updated successfully",
  NOTIFICATIONS_SETTINGS: "Notifications settings",
  NOTE_CREATED_SUCCESSFULLY: "Note created successfully",
  CLIENTS_CAN_POST: "Clients can post",
  AUTO_POST_FINISHED_WORKOUT: "Auto post finished workout",
  MEASUREMENT_DELETED_SUCCESSFULLY: "Measurement deleted successfully",
  ALWAYS: "Always",
  ON_MENTION_ONLY: "On mention only",
  NEVER: "Never",
  DATE_FROM: "Date from",
  DATE_TO: "Date to",
  SUCCESSFULLY_CREATED_GROUP: "Successfully created group",

  GOAL: "Goal",
  CURRENTLY: "Currently",
  START_FASTING: "Start fasting",
  STOP_FASTING: "Stop fasting",
  HOW_LONG_DO_YOU_PLAN_TO_FAST_FOR: "How long do you plan to fast for",
  START: "Start",
  YOU_STARTED: "You started",
  TOTAL_FASTS: "Total fasts",
  RETURN_FROM_ARCHIVE: "Unarhive",
  AVERAGE: "Average",
  ACCOUNT_EXPIRES_TODAY: "account expires today",
  THE_LONGEST_FAST: "The longest fast",
  NUMBER_OF_CLIENTS: "Number of clients",
  GENERATE_WORKOUT: "Generate workout",
  THE_LONGEST_STREAK: "The longest streak",
  CURRENT_STREAK: "Current streak",
  DETAILED_VIEW: "Detailed view",
  DETAILS: "Details",
  TEMPLATE_RECIPE: "Template recipe",
  TEMPLATE_INGREDIENT: "Template ingredient",
  TEMPLATE_NOTE: `
    Created by the application admin. You have the option of creating a copy and
    reusing it as your own.
  `,
  LEGS_WORKOUT: "Legs workout",
  GALLERY_ENTRY: "Gallery entry",
  CLIENT_A: "Client",
  JANUARY: "January",
  FEBRUARY: "February",
  MARCH: "March",
  APRIL: "April",
  MAY: "May",
  JUNE: "June",
  JULY: "July",
  CLIENT_TAGS: "Client tags",
  AUGUST: "August",
  SEPTEMBER: "September",
  OCTOBER: "October",
  NOVEMBER: "November",
  SET_GOAL: "Set goal",
  SET_TAG: "Set tag",
  CURRENT: "Current",
  REMINDER: "Reminder",
  CREATE_AUTO_MESSAGE: "Create auto message",
  CREATE_NEW_REMINDER: "Set new reminder",
  EDIT_NEW_REMINDER: "Edit reminder",
  EDIT_EXPIRY_DATE: "Change expiration date",
  DECEMBER: "December",
  PROGRAM: "Program",
  PLANS: "Plans",
  SELECT_ACTION: "Select action",
  TOTAL_NUMBER_OF_CLIENTS: "Total number of clients",
  TOTAL_NUMBER_OF_GROUPS: "Total number of groups",
  CREATE_NEW_PAYMENT: "Create new payment",
  ACCOUNT_DELETED: "Account deleted",
  WAIST_CIRCUMFERENCE: "Waist circumference",
  CREATE_NEW_MEASUREMENT: "Create new measurement",
  EDIT_MEASUREMENT: "Edit measurement",
  TRANSFER_TO_ANOTHER_TRAINIER: "Transfer to another trainer",
  SUCCESSFULLY_RESET_PASSWORD: "Successfully reset password",
  ASSIGN_TRAINING_PROGRAM: "Assign training plan",
  CHAT_HELPERS: "Chat helpers",
  SHOW_AI_SUMMARY: "Show AI summary button",
  ASSIGN_MEAL_PLAN: "Assign meal plan",
  ARCHIVE: "Archive",
  RECIPE: "Recipe",
  USER_NOT_FOUND: "User not found",
  USER_IS_BUGGED: "User is faulty",
  ASSIGN_TO_CLIENTS: "Assign to clients",
  ASSIGN_TO_ANOTHER_CLIENT: "Assign to another client",
  ASSIGN_MEAL_PLAN_TO_CLIENT: "Assign meal plan to client",
  BOOKMARKED_MESSAGES: "Bookmarked messages",
  ASSIGN_MEAL_PLAN_TO_GROUP: "Assign meal plan to group",
  ASSIGN_WORKOUT_PLAN: "Assign workout plan",
  ASSIGN_WORKOUT_PLAN_TO_CLIENT: "Assign workout plan to client",
  ASSIGN_WORKOUT_PLAN_TO_GROUP: "Assign workout plan to group",
  CLIENT_UNARCHIVED_SUCCESSFULLY: "Client unarchived successfully",
  TRANSFER_TO_ANOTHER_TRAINER: "Transfer to another trainer",
  // krle
  SKIP: "Skip",
  EMAIL_COPIED_TO_CLIPBOARD: "Email copied to clipboard",
  LOGIN_COPIED_TO_CLIPBOARD: "Login link copied to clipboard",

  ACTIVE: "Active",
  DIARY: "Diary",
  CREATE_NEW_HABIT: "Track new habit",
  STEPS: "Steps",

  SAVE: "Save",
  PER: "Per",
  WORKOUT_FINISHED: "Workout finished",
  IMPORT: "Import",
  CHOOSE: "Choose",
  CURRENCY: "Currency",
  CURRENCY_AMOUNT: "Amount",
  HEADERS_WORKOUT_NOTE: "Headers divide exercises visually",
  CLIENT_DELETED_SUCCESSFULLY: "Client deleted successfully",
  CLIENT_UNDELETED_SUCCESSFULLY: "Client restored successfully",
  RESTORE: "Restore",
  ACCOUNT_EXPIRATION_DATE: "Account expiration date",
  PAYMENT_DATE: "Payment date",
  EXPIRATION_DATE: "Expiration date",
  NOTIFICATION_DATE: "Notification date",
  GENDER: "Gender",
  MALE: "Male",
  FEMALE: "Female",
  DATE_OF_BIRTH: "Date of birth",
  HEIGHT: "Height",
  SEND: "Send",
  MEASUREMENTU: "Measurement",
  AREA_CODE: "Area code",
  DAILY_MACRO_GOALS: "Daily macro goals",
  BY_CALORIES: "By calories",
  BY_GRAMS_OF_MACRONUTRIENTS: "By grams of macronutrients",
  TAGS: "Tags",
  CLIENT_INTERACTIONS: "Client interactions",
  COMMENTING: "Commenting",
  CREATING_GROCERIES: "Creating groceries",
  CREATING_EXERCISES: "Creating exercises",
  EXPIRING: "Expiring",
  TOMORROW: "Tomorrow",
  TODAY: "Today",
  TRACK_NEW_MEASUREMENT: "Track new measurement",
  TRACK_NEW_HABIT: "Track new habit",
  CHATTING: "Chatting",
  CHAT_WITH_CLIENT: "Chat with client",
  EXPIRY_VIEW: "Expiration view",
  REMINDERS: "Reminders",
  DELETE_FROM_GROUP: "Remove from group",
  AUTOMATED_MESSAGE: "Automated message",
  AUTOMATED_U_MESSAGE: "Automated message",
  DATE_OF_DISPATCH: "Date of dispatch",
  DISPATCH_METHOD: "Dispatch method",
  COMPLIANCE: "Compliance",
  TIMELINE: "Timeline",
  IN_APP: "In app",
  MY_MEAL_PLANS: "My meal plans",
  LIBRARY: "Library",
  NUMBER_OF_MEAL_PLANS: "Total meal plan count",
  HABIT_SUCCESSFULLY_ADDED: "Successfully added habit",

  SUCCESS_ADDING_EXERCISE: "Successfully added exercise",
  SUCCESS_DELETING_EXERCISE: "Successfully deleted exercise",
  ERROR_ADDING_EXERCISE: "There was an error while adding exercise",

  ERROR_ALREADY_ADDED_EXERCISE: "Exercise is already added",

  CONNECT: "Connect",
  CONNECT_WITH_PARENT_EXERCISE: "Connect with parent exercise",
  SUCCESS_CONNECTED_WITH_PARENT_EXERCISE:
    "Successfully connected with parent exercise.",
  ERROR_CONNECTED_WITH_PARENT_EXERCISE:
    "There was an error connecting with parent exercise.",
  OUR_RECOMMENDATIONS: "Our recommendations",
  OUR_RECOMMENDATION_NOTE:
    "If none of the exercises suit you, feel free to search on your own.",
  YOUVE_SELECTED_EXERCISE: "You've selected exercise",

  SEARCH_MEAL_PLANS: "Search meal plans...",
  SEARCH_RECIPES: "Search recipes...",
  SEARCH_INGREDIENTS: "Search ingredients...",
  SEARCH_TRAINING_PROGRAMS: "Search training programs...",
  SEARCH_WORKOUTS: "Search workouts...",
  SEARCH_EXERCISES: "Search exercises...",

  SEARCH_PROGRAM: "Search program...",
  SEARCH_CLIENT: "Search client...",
  SEARCH_CLIENTS: "Search clients...",
  SEARCH_GROUPS: "Search groups...",
  DURATION: "Duration",
  DURATION_IN_DAYS: "Duration in days",
  DAYS: "Days",
  DEFAULT_IMAGE_LOADED_AUTOMATICALLY:
    "The default image has been loaded automatically",
  NOTE: "Note",
  PHONE_NUMBER: "Phone number",
  OMIT_FROM_AUTOMATIC_MESSAGE: "Omit from automatic message",
  REMINDER_DELETED_SUCCESSFULLY: "Successfully deleted reminder",

  SETTINGS_CLIENT_TAGS_NOTE: `
    Create your custom tag here and then use it to tag your clients. You can
    filter your clients by your tags later on.
  `,
  SETTINGS_DEFAULT_HABITS_NOTE: `
    These habits will automatically be assigned to every new client you create.
  `,
  SETTINGS_DEFAULT_MEASUREMENTS_NOTE: `
    These measurements will automatically be assinged to every new client you
    create.
  `,
  SETTINGS_INGREDIENT_TYPES_NOTE: `
    Create your custom grocery types, assign them to a grocery in the grocery
    tab and then use them for easy filtering.
  `,
  SETTINGS_RECIPE_TYPES_NOTE: `
    Create your custom recipe types, assign them to a recipe in the recipe tab
    and then use them for easy filtering.
  `,
  SETTINGS_MEAL_TYPES_NOTE: `
    Create your custom meal plan types, assign them to a meal plan in the meal
    plan tab and then use them for easy filtering.
  `,
  SETTINGS_CLIENTS_NOTE: `
    Give your new clients default password to access the app! Welcome them with
    a custom email message.
  `,

  ADD_CREATE_MEAL_PLANS_NOTE:
    "By entering the duration, a counter is initiated when the plan is assigned to a client. In case you set the expiration date manually, the duration value is ignored. The only required field is meal plan's name. Inserting the type will ease the later search and filter processes.",
  TYPE: "Type",
  MEAL_PLAN_TYPE: "Meal plan type",
  TAG: "Tag",
  TAG_U: "Tag",
  SET_TYPE: "Set type",
  EDIT_TYPE: "Edit type",
  CLIENT_MEAL_PLAN_EXPIRE_TOMORROW: "meal plan expires tomorrow",
  HAS_FINISHED_MEAL: "has finished meal",
  NEW_TYPE: "New type",
  NEW_TYPE_TITLE: "New type title",
  NEW_TAG: "New tag",
  NEW_TAG_NAME: "New tag name",
  NEW_TAG_COLOR: "New tag color",
  NEW_TAG_COLOR_HINT: "Click on the icon to choose tag color",
  NEW_CLIENT_DEFAULT_PASSWORD: "New client default password",
  WELCOMING_EMAIL: "Welcoming email",
  CREATE_TAG: "Create tag",
  EDIT_TAG: "Edit tag",
  CREATE_HABIT: "Create habit",
  CREATE_MEASUREMENT: "Create measurement",
  ASSIGN: "Assign",
  TEN_MINUTE_WALK: "Ten minute walk",
  // CreateAMealDialog
  CREATE_A_MEAL: "Create a meal",
  ACCOUNT_EXPIRED_CONTACT_TRAINER:
    "Your account has expired, contact trainer for more information.",
  TEXT: "Text",
  // CreateHeaderDialog
  HEADER: "Header",
  CREATE_HEADER: "Create header",
  DISPLAY_MACRONUTRIENT_VALUES: "Display macronutrient values",
  CREATE_HEADER_NOTE:
    "Headers are used to sum up the macronutrient values of contained meals",
  // UploadPDFMeal
  UPLOAD_PDF_MEAL: "Upload PDF meal",
  // MealPlan
  TYPE_TEXTUALLY: "Type textually",
  UPLOAD_PDF: "Upload PDF",
  ADD_HEADER: "Add header",
  TOTAL_MEAL_COUNT: "Total meal count",

  // Input validation
  MIN_LENGTH: "Minimum field length is 2",
  FIELD_IS_REQUIRED: "Field is required",
  // Ingredient
  INGREDIENT: "Ingredient",
  SHOW_ACTIVE: "Show active",
  TOTAL_INGREDIENT_COUNT: "Total ingredient count",
  TOTAL_RECIPE_COUNT: "Total recipe count",
  TOTAL_PROGRAMS_COUNT: "Total programs count",
  MEASUREMENT_UPDATED_SUCCESSFULLY: "Measurement updated successfully",
  INGREDIENT_TYPE: "Ingredient type",
  INGREDIENT_TYPES: "Ingredient types",
  DIRECTIONS: "Directions",
  FOOD_TYPE: "Food type",

  NO_DESCRIPTION: "No description",
  NO_DIRECTIONS: "No directions",
  NO_FOOD_TYPE: "No food type",
  NO_TYPE: "No type",
  NO_RECIPES: "No recipes",
  NO_INGREDIENTS: "No ingredients",

  AMOUNT_PER_UNIT: "Amount on unit",
  HABIT_SUCCESSFULLY_DELETED: "Habit successfully deleted",
  CALCULATE_CALORIES_AUTOMATICALLY: "Calculate calories automatically",
  CREATE_EDIT_INGREDIENT_NOTE: `
    None of the fields (except for name) are neccessary but filling
    them will ease the later search and filter processes.
  `,

  FULL_NAME: "Full name",
  RECIPE_TYPE: "Recipe type",
  RECIPE_TYPES: "Recipe types",
  CREATE_INGREDIENT: "Create ingredient",
  IMPORT_INGREDIENTS: "Import ingredients",
  IMPORT_WORKOUT: "Import workout",
  ADD_EDIT_RECIPE_NOTE: `
    Calories are automatically recalculated, if you have defined your own value,
    it will be overriden. You can change it later.
  `,
  CANT_DO_EXERCISE: "Can't do exercise",
  CAN_DO_EXERCISE: "Can do exercise",
  CANT_DO_EXERCISES: "Can't do exercises",
  RECIPE_MACRONUTRIENT_STATS: "Recipe macronutrient stats",
  RECIPE_UPDATED_SUCCESSFULLY: "Recipe updated successfully",
  RECIPE_UPDATED_FAILED: "There was an error updating recipe",
  // Forms
  FIELD_MINIMUM_AMOUNT_0: "Minimum 0",
  FIELD_MINIMUM_AMOUNT_1: "Minimum 1",
  FIELD_MINIMUM_AMOUNT_500: "Minimum 500",
  FIELD_MAXIMUM_AMOUNT_5: "Maximum 5",
  FIELD_MAXIMUM_AMOUNT_200: "Maximum 200",
  FIELD_MAXIMUM_AMOUNT_1000: "Maximum 1000",
  FIELD_MAXIMUM_AMOUNT_5000: "Maximum 5000",
  FIELD_MAXIMUM_AMOUNT_10000: "Maximum 10000",
  FIELD_MAXIMUM_AMOUNT_17000: "Maximum 17000",
  FIELD_MINIMUM_2: "Minimum field length is 2",
  FIELD_MINIMUM_4: "Minimum field length is 4",
  FIELD_MAXIMUM_10: "Maximum field length is 10",
  FIELD_MAXIMUM_15: "Maximum field length is 15",
  FIELD_MAXIMUM_20: "Maximum field length is 20",
  FIELD_MAXIMUM_40: "Maximum field length is 40",
  FIELD_MAXIMUM_320: "Maximum field length is 320",
  FIELD_MAXIMUM_5000: "Maximum field length is 5000",
  FIELD_THREE_DIGITS: "Field must contain three digits",
  FIELD_REQUIRED: "Field is required",
  YOUTUBE_LINK_INVALID: "Youtube link invalid",
  EMAIL_INVALID: "Email invalid",
  EMAIL_TAKEN: "Email taken",
  PHONE_NUMBER_INVALID: "Phone number invalid",

  REPETITION: "Repetition",
  REPETITION_A: "Repetition",
  SERIES: "Series",
  BREAK: "Break",
  TIME: "Time",
  DISTANCE: "Distance",
  YOUTUBE_LINK: "Youtube link",

  ENDURANCE: "Endurance (Reps, e.g. Burpees)",
  STRENGTH: "Strength (Reps x Weights, e.g. Squats)",
  CARDIO: "Cardio (e.g. Running)",
  TIMED_LONGER_BETTER: "Timed Longer Better (e.g. Planks)",
  TIMED_FASTER_BETTER: "Timed Faster Better (e.g. Sprints)",
  TIMED_STRENGTH: "Timed Strength (Seconds x Weights)",

  DONT_SHOW_ANYMORE: "Don't show anymore",
  UPDATE_AVAILABLE: "Update available",
  UPDATE_TO_USE_APP: "Update to the latest version to continue using the app",
  REFRESH_TO_USE_APP: "Refresh the page (F5) to continue using the app",
  CURRENT_VERSION: "Current version",
  NEWEST_VERSION: "Newest version",

  // FILTERI
  // Core Filters
  ALL: "All",
  MY: "My",

  MEAL_TITLE: "Meal title",
  SHOW_ARCHIVED: "Show archived",
  SHOW_ALL: "Show all",
  SHOW_DELETED: "Show deleted",
  TEMPLATE_MESSAGE: "Template message",
  TEMPLATE_MESSAGE_U: "Template message",
  TEMPLATE_MESSAGES: "Template messages",
  TEMPLATE_MESSAGE_DELETED_SUCCESSFULLY:
    "Template message deleted successfully",
  CREATE_TEMPLATE_MESSAGE: "Create template message",
  EDIT_TEMPLATE_MESSAGE: "Edit template message",

  NAME_ASC: "By name ascending",
  CLIENT_ARCHIVED_SUCCESSFULLY: "Client archived successfully",
  NAME_DESC: "By name descending",
  DATE_ASC: "By date added ascending",
  DATE_DESC: "By date added descending",
  LAST_LOGIN_ASC: "By last login ascending",
  LAST_LOGIN_DESC: "By last login descending",
  CLIENT_PROFILE: "Client profile",
  NEVER_LOGIN_ASC: "Never logged in ascending",
  NEVER_LOGIN_DESC: "Never logged in descending",
  INACTIVE_CHAT_ASC: "Inactive chat ascending",
  INACTIVE_CHAT_DESC: "Inactive chat descending",
  SUCCESSFULLY_ADDED_EMAIL_EXCEPTION:
    "Email successfully added to exception list.",
  SUCCESSFULLY_DELETED_EMAIL_EXCEPTION:
    "Email successfully removed from exception list.",

  // Foods Filters
  BAR: "Macro filter",

  // Exercises Filters
  ABS: "Abs",
  BICEPS: "Biceps",
  CALVES: "Calves",
  CHEST: "Chest",
  FOREARM: "Forearm",
  GLUTE: "Glute",
  HAMSTRINGS: "Hamstrings",
  LOWER_BACK: "Lower back",
  QUADS: "Quads",
  SHOULDERS: "Shoulders",
  TRICEPS: "Triceps",
  UPPER_BACK: "Upper back",

  PROTOCOL: "Protocol",

  SELECT_MORE: "Select more",
  CREATE_REST_DAY: "Create rest day",
  ACCOUNT_ARCHIVED: "Account archived",
  ACCOUNT_EXPIRED: "Account expired",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_ACCOUNT:
    "If you are sure you want to delete your account, type your email address below:",
  ARE_YOU_SURE: "Are you sure",
  SUCCESSFULLY_DELETED_ACCOUNT: "Successfully deleted account",
  SUCCESSFULLY_UPDATED_EXPIRE_DATE: "Expire date successfully updated",
  REST_DAY: "Rest day",
  // SUCCESS
  SUCCESSFULLY_SENT_SMS: "Successfully sent SMS message",
  // ERRORS
  ERROR_SENDING_SMS: "There was an error sending SMS",
  ERROR_CHANGING_ORDER: "There was an error changing order",

  ERROR_ASSIGNING_MEAL_PLAN: "There was an error assigning meal plan",
  ERROR_ASSIGNING_TRAINING_PROGRAM:
    "There was an error assigning training program",

  ERROR_COPYING_INGREDIENT: "There was an error copying ingredient",
  ERROR_COPYING_RECIPE: "There was an error copying recipe",
  ERROR_COPYING_MEAL_PLAN: "There was an error copying meal plan",
  ERROR_COPYING_MEAL: "There was an error copying meal",
  ERROR_COPYING_EXERCISE: "There was an error copying exercise",
  ERROR_COPYING_TRAINING_PROGRAM: "There was an error copying training program",
  ERROR_COPYING_WORKOUT: "There was an error copying workout",
  ADDED_NEW_GALLERY_ENTRY: "added new gallery entry",

  ERROR_CREATING_INGREDIENT: "There was an error creating ingredient",
  ERROR_CREATING_RECIPE: "There was an error creating recipe",
  ERROR_CREATING_MEAL: "There was an error creating meal",
  ERROR_CREATING_MEAL_PLAN: "There was an error creating meal plan",

  ERROR_CREATING_EXERCISE: "There was an error creating exercise",
  ERROR_CREATING_REST_DAY: "There was an error creating rest day",
  ERROR_CREATING_WORKOUT: "There was an error creating workout",
  ERROR_CREATING_TRAINING_PROGRAM:
    "There was an error creating training program",
  ERROR_CREATING_GROUP: "There was an error creating group",
  ERROR_CREATING_CLIENT: "There was an error creating client",
  ERROR_CREATING_PAYMENT: "There was an error creating payment",
  ERROR_CREATING_GOAL: "There was an error creating goal",

  ERROR_DELETING_INGREDIENT: "There was an error deleting ingredient",
  ERROR_DELETING_RECIPE: "There was an error deleting recipe",
  ERROR_DELETING_MEAL_PLAN: "There was an error deleting meal plan",
  ERROR_DELETING_TRAINING_PROGRAM:
    "There was an error deleting training program",
  ERROR_DELETING_EXERCISE: "There was an error deleting exercise",
  ERROR_DELETING_WORKOUT: "There was an error deleting workout",
  ERROR_DELETING_GROUP: "There was an error deleting group",

  ERROR_EDITING_INGREDIENT: "There was an error editing ingredient",
  ERROR_EDITING_RECIPE: "There was an error editing recipe",
  ERROR_EDITING_MEAL: "There was an error editing meal",
  ERROR_EDITING_MEAL_PLAN: "There was an error editing meal plan",

  ERROR_EDITING_EXERCISE: "There was an error editing exercise",
  ERROR_EDITING_REST_DAY: "There was an error editing rest day",
  ERROR_EDITING_WORKOUT: "There was an error editing workout",
  ERROR_EDITING_TRAINING_PROGRAM: "There was an error editing training program",
  ERROR_EDITING_GROUP: "There was an error editing group",
  ERROR_EDITING_GOAL: "There was an error editing goal",

  ERROR_IMPORTING_INGREDIENTS: "There was an error importing ingredients",
  ERROR_IMPORTING_RECIPES: "There was an error importing recipes",
  ERROR_IMPORTING_WORKOUT: "There was an error importing workout",
  // Notifications
  MEAL_PLAN_EXPIRES_TODAY: "meal plan expires today",
  PROGRAM_EXPIRES_TODAY: "training program expires today",
  PROGRAM_EXPIRES_TOMORROW: "training program expires tomorrow",
  HAS_FINISHED_WORKOUT: "has finished workout",
  DELETE_TEMPLATE_MESSAGE: "Delete template message",
  ADD_TEMPLATE_MESSAGE: "Add template message",
  COMMENT: "Comment",
  COMMENTED_MEAL: "commented meal",
  COMMENTED_EXERCISE: "commented exercise",
  COMMENTED_WORKOUT: "commented workout",
  IS_TRACKING_A_NEW_MEASUREMENT: "is tracking a new measurement",
  SHOW_ONLY_UNREAD: "Show only unread",
  SHOW_ONLY_COMMENTS: "Show only comments",
  ALL_NOTIFICATIONS_MARKED_AS_READ:
    "All notifications marked as read successfully",
  SEND_TO_ARCHIVE: "Send to archive",
  SEND_NOW: "Send now",
  NO_CHAT_SESSIONS: "Message list is empty",
  ARCHIVE_DESCRIPTION:
    "By archiving clients, their accounts are disabled but not deleted. You can access them by checking the 'Display archived' in the filters",
};
// end of en

const sr = {
  UPLOADING_FILE: "Datoteka se otprema...",
  HAS_BIRTHDAY_TODAY: "ima rodjendan danas",
  ASSIGN_TO_CLIENTS: "Dodeli klijentima",
  ASSIGN_TO_ANOTHER_CLIENT: "Dodeli drugom klijentu",
  ADD: "Dodaj",
  RECIPE: "Recept",
  ASSIGN_MEAL_PLAN: "Dodeli plan ishrane",
  ASSIGN_TRAINING_PROGRAM: "Dodeli trening program",
  TRANSFER_TO_ANOTHER_TRAINIER: "Prebaci drugom treneru",
  CREATE_NEW_PAYMENT: "Kreiraj novu uplatu",
  CREATE_NEW_REMINDER: "Kreiraj nov podsetnik",
  EDIT_NEW_REMINDER: "Izmeni podsetnik",
  EDIT_EXPIRY_DATE: "Izmeni datum isteka",
  ARCHIVE: "Arhiviraj",
  CREATE_AUTO_MESSAGE: "Kreiraj automatsku poruku",
  SET_TAG: "Postavi oznaku",
  CURRENT: "Trenutno",
  SET_GOAL: "Postavi cilj",
  ADD_CLIENT: "Dodaj klijenta",
  ADD_PROGRAM: "Dodaj program",
  ADD_WORKOUT: "Dodaj trening",
  ADD_EXERCISE: "Dodaj vežbu",
  ADD_RECIPE: "Dodaj recept",
  ADD_RECIPES: "Dodaj recepte",
  ADD_INGREDIENT: "Dodaj namirnicu",
  ADD_IMAGE: "Dodaj fotografiju",
  CREATE_COPY: "Kreiraj kopiju",
  CLOSE: "Zatvori",
  COMMITMENT: "Posvećenost",
  IMAGE: "Slika",
  FILE: "Fajl",

  DELETE_ACCOUNT: "Obriši nalog",
  WELCOME_BACK: "Dobrodošli nazad",
  OR_SIGN_IN_WITH_EMAIL: "Logujte se email adresom",
  SUCCESSFULLY_CREATED: "Uspešno kreiran/a",
  SUCCESSFULLY_EDITED: "Uspešno izmenjen/a",
  SUCCESSFULLY_DELETED: "Uspešno obrisan/a",
  ERROR_DELETING: "Greška pri brisanju",
  SUCCESSFULLY_SAVED: "Uspešno sačuvan",
  SUCCESSFULLY_COPIED: "Uspešno iskopiran/a",
  ERROR_COPYING: "Greška pri kopiranju",
  SUCCESSFULLY_IMPORTED: "Uspešno uvezeni/e",
  SUCCESSFULLY_UPLOADED: "Uspešno otpremljena",
  SUCCESSFULLY_COMPLETED: "Uspešno odrađen",
  SUCCESSFULLY_UPDATED_VALUE: "Uspešno ažurirana vrednost",
  SUCCESSFULLY_DELETED_VALUE: "Uspešno obrisana vrednost",
  SUCCESSFULLY_CHANGED_ORDER: "Redosled uspešno izmenjen",

  SUCCESSFULLY_SAVED_TO_DATABASE: "Uspešno sačuvan u bazu",
  SUCCESSFULLY_SAVED_TO_JOURNAL: "Uspešno sačuvan u dnevnik",

  SUCCESSFULLY_ASSIGNED_MEAL_PLANS:
    "Uspešno dodeljeni planovi ishrane klijentu",
  SUCCESSFULLY_ASSIGNED_TRAINING_PROGRAM:
    "Uspešno dodeljeni programi treninga klijentu",

  SUCCESSFULLY_IMPORTED_INGREDIENTS: "Uspešno uvezene namirnice",
  SUCCESSFULLY_IMPORTED_RECIPES: "Uspešno uvezeni recepti",

  SUCCESSFULLY_CREATED_INGREDIENT: "Uspešno kreirana namirnica",
  SUCCESSFULLY_CREATED_RECIPE: "Uspešno kreiran recept",
  SUCCESSFULLY_CREATED_MEAL: "Uspešno kreiran obrok",
  SUCCESSFULLY_CREATED_MEAL_PLAN: "Uspešno kreiran plan ishrane",

  SUCCESSFULLY_COPIED_INGREDIENT: "Uspešno kreirana kopija namirnice",
  SUCCESSFULLY_COPIED_RECIPE: "Uspešno kreirana kopija recepta",
  SUCCESSFULLY_COPIED_MEAL: "Uspešno kreirana kopija obroka",
  SUCCESSFULLY_COPIED_MEAL_PLAN: "Uspešno kreirana kopija plana ishrane",
  SUCCESSFULLY_COPIED_EXERCISE: "Uspešno kreirana kopija vežbe",

  SUCCESSFULLY_EDITED_INGREDIENT: "Uspešno izmenjena namirnica",
  SUCCESSFULLY_EDITED_RECIPE: "Uspešno izmenjen recept",
  SUCCESSFULLY_EDITED_MEAL: "Uspešno izmenjen obrok",
  SUCCESSFULLY_EDITED_MEAL_PLAN: "Uspešno izmenjen plan ishrane",

  SUCCESSFULLY_DELETED_INGREDIENT: "Uspešno obrisana namirnica",
  SUCCESSFULLY_DELETED_RECIPE: "Uspešno obrisan recept",
  SUCCESSFULLY_DELETED_MEAL: "Uspešno obrisan obrok",
  SUCCESSFULLY_DELETED_MEAL_PLAN: "Uspešno obrisan plan ishrane",
  SUCCESSFULLY_DELETED_EXERCISE: "Uspešno obrisana vežba",

  SUCCESSFULLY_COPIED_TRAINING_PROGRAM:
    "Uspešno kreirana kopija programa treninga",

  JOINED_THE_CHAT: "se priključio/la",
  SIGN_IN: "Prijavi se",
  SIGN_OUT: "Odjavi se",
  DARK: "Tamna",
  MESSAGE: "Poruka/u",
  CONFIRM: "Potvrdi",
  SEND_MESSAGE_U: "Pošalji poruku",
  PROGRAM: "Program",
  DELETE_TEMPLATE_MESSAGE: "Obriši šablonsku poruku",
  ADD_TEMPLATE_MESSAGE: "Dodaj šablonsku poruku",
  PLANS: "Planovi",
  DELETE: "Obriši",
  DOWNLOAD: "Preuzmi",
  BACK: "Nazad",
  LOAD_MORE: "Učitaj još",
  SELECT_INGREDIENTS: "Izaberi namirnice",
  SET_AMOUNT: "Podesi količine",
  LIGHT: "Svetla",
  PERCENT: "Procenat",
  TYPE_HERE: "Kucaj ovde...",
  WELCOME: "Dobrodošli",
  ACCOUNT_EXPIRES_TOMORROW: "nalog ističe sutra.",
  ASSIGNED_YOU_A_MEAL_PLAN: "ti je dodelio/la plan ishrane",
  ASSIGNED_YOU_A_WORKOUT_PLAN: "ti je dodelio/la plan treninga",
  WELCOME_TO_APP_CLIENT:
    "Dobrodošao/la! Klikni na ikonicu za ćaskanje kako bi stupio/la u kontakt sa trenerom.",
  NUMBER_OF_PROGRAMS: "Ukupan broj programa",
  NUMBER_OF_WORKOUTS: "Ukupan broj treninga",
  TOTAL_EXERCISES_COUNT: "Ukupan broj vežbi",
  NUMBER_OF_TRAININGS: "Ukupan broj treninga",
  NUMBER_OF_ITEMS: "Broj stavki",
  PROFILE: "Profil",
  NOTIFICATIONS: "Notifikacije",
  LANGUAGE: "Jezik",
  MEAL: "Obrok",
  THEME: "Tema",
  REPORT_A_BUG_SUGGESTION: "Prijavi grešku/predlog",
  HELP: "Pomoć",
  TRAINING_PLAN: "Plan treninga",
  TRAINING_JOURNAL: "Dnevnik treninga",
  EXPORT_TO_PDF: "Eksportuj u PDF",
  HABIT: "Navika",
  HABIT_U: "Naviku",
  HABITS: "Navike",
  DEFAULT_HABITS: "Podrazumevane navike",
  DEFAULT_MEASUREMENTS: "Podrazumevane mere",
  MEASUREMENTS: "Mere",
  MEASUREMENT: "Mera",
  MEASUREMENT_U: "Meru",
  GOALS: "Ciljevi",
  GALLERY: "Galerija",
  MEAL_PLAN: "Plan ishrane",
  MEAL_PLAN_TYPES: "Tipovi planova ishrane",
  DIET_JOURNAL: "Dnevnik ishrane",
  IF_TRACKING: "IF Praćenje",
  REST: "Odmor",
  SETS: "Serije",
  REPS: "Ponavljanja",
  WEIGHT: "Težina",
  CLIENT: "Klijent",
  CLIENTS: "Klijenti",
  CLIENTS_E: "Klijenti",
  CLIENTS_BY_DAY: "Klijenti po danima",
  ALL_CLIENTS: "Svi klijenti",
  PAYMENT: "Uplata",
  PAYMENT_U: "Uplatu",
  PAYMENTS: "Uplate",
  TIMELINE: "Tajmlajn",
  GROUPS: "Grupe",
  GROUP: "Grupa",
  GROUP_U: "Grupu",
  GROUP_A: "Grupa",
  TRAINERS: "Treneri",
  REGISTERED: "Registrovan",
  ACCOUNT_ARCHIVED: "Nalog arhiviran",
  ACCOUNT_EXPIRED: "Nalog istekao",
  CLIENT_A: "Klijenta",
  SEARCH: "Pretraži",
  FILTER: "Filtriraj",
  SELECT: "Izaberi",
  PROGRAMS: "Programi",
  MY_PROGRAMS: "Moji programi",
  MY_EXERCISES: "Moje vežbe",
  WORKOUTS: "Treninzi",
  EXERCISE: "Vežba",
  EXERCISES: "Vežbe",
  EXERCISE_E: "Vežbe",
  EXERCISE_U: "Vežbu",
  MEAL_PLANS: "Planovi ishrane",
  RECIPES: "Recepti",
  RECIPES_E: "Recepte",
  INGREDIENTS: "Namirnice",
  INGREDIENT_A: "Namirnica",
  INGREDIENT_U: "Namirnicu",
  MEASUREMENT_UNIT: "Merna jedinica",
  NOT_SET: "Nije postavljeno",
  CARBS: "Ug. hidr.",
  FATS: "Masti",
  PROTEIN: "Protein",
  CALORIES: "Kalorije",
  GLASS: "Čaša",
  NAME: "Naziv", // tako bilo na stari sajt
  EMAIL: "Email",
  PASSWORD: "Lozinka",
  REPEAT_PASSWORD: "Ponovi lozinku",
  REPEAT_WORKOUT: "Ponovi trening",
  TRACK_NEW_HABIT: "Prati novu naviku",
  SUCCESSFULLY_UPDATED_EXPIRE_DATE: "Uspešno izmenjen datum isteka",
  UPDATE: "Ažuriraj",
  CLICK_ON_IMAGE_TO_CHANGE: "Klikni na sliku za promenu",
  FINISH_WORKOUT: "Završi trening",
  COMMENT_HERE: "Unesi komentar ovde",
  EXERCISE_COMMENTS: "Komentari vežbe",
  WORKOUT_COMMENTS: "Komentari treninga",
  MEAL_COMMENTS: "Komentari obroka",
  COMMENTS: "Komentari",
  TAKE_A_PHOTO: "Uslikaj se iz aplikacije",
  ADD_PHOTOS: "Dodaj fotografije",
  NO_NOTIFICATIONS: "Nemaš nijednu notifikaciju",
  TYPE_NOT_ASSIGNED: "Tip nije naznačen",
  DESCRIPTION: "Opis",
  REPORT_BUG_SUGGESTION: "Prijavi grešku/predlog",
  BUG_SUGGESTION: "Prijava greške/predloga",
  NO_BOOKMARKED_MESSAGES_YET: "Još uvek niste bookmarkovali ni jednu poruku.",
  NO_CANT_DO_EXERCISES_YET: "Još uvek niste dodali ni jednu vežbu.",
  SUBMIT: "Pošalji",
  BUG_SUGGESTION_EXAMPLE: `Ne mogu da pošaljem sliku u čatu. \nKoristim iPhone 12 Pro.`,
  SUGGESTED_MACROS: "Preporučeni dnevni unos",
  CREATE_GOAL: "Postavi cilj",
  CREATE_TRAINER: "Kreiraj trenera",
  EDIT_GOAL: "Izmeni cilj",
  SAVE_TO_JOURNAL: "Sačuvaj u dnevnik",
  SAVE_TO_DATABASE: "Sačuvaj u bazu",
  SAVED_TO_DATABASE: "Sačuvan u bazu",
  NEXT_STEP: "Sledeći korak",
  ADD_PROGRESS_PHOTOS: "Dodaj fotografije napretka",
  PICK_DATE: "Odaberi datum",
  PREVIOUS_STEP: "Prethodni korak",
  SUCCESSFULLY_FINISHED_WORKOUT: "Uspešno završen trening",
  PHOTO_IS_UPLOADING: "Slika se uploaduje, molim vas sačekajte",
  SHARED_CONTENT: "Deljeni sadržaj",
  FINISH_WORKOUT_FAILED: "Završavanje treninga nije uspelo",
  MONDAY: "Ponedeljak",
  TRAINING_PROGRAM: "Program treninga",
  TRAINING_PROGRAMS: "Programe treninga",
  AMOUNT: "Količina",
  ADDED: "Dodat",
  MESSAGES_SENT: "Poruke poslate",
  TUESDAY: "Utorak",
  WEDNESDAY: "Sreda",
  THURSDAY: "Četvrtak",
  FRIDAY: "Petak",
  SATURDAY: "Subota",
  SUNDAY: "Nedelja",
  WEEK: "Nedelja",
  MONTH: "Mesec",
  THREE_MONTHS: "Tri Meseca",
  FRONT_POSE: "Slika napred",
  BACK_POSE: "Slika nazad",
  SIDE_POSE: "Slika sa strane",
  G: "g",
  HANDFULL: "šaka",
  ML: "ml",
  ONEOF: "komad/a",
  PINCH: "prstohvat",
  TABLESPOON: "kašika",
  TEASPOON: "kašičica",
  GALLERY_TUTORIAL: "Klikni na logo/sliku da bi je dodao/izmenio.",
  RATE_WORKOUT: "Oceni trening",
  NO_EFFORT: "Bez napora",
  VERY_EASY: "Veoma lako",
  ADD_MESSAGE: "Dodaj poruku",
  SOMEWHAT_EASY: "Pomalo lako",
  EASY: "Lako",
  MEDIUM: "Umereno",
  SOMEWHAT_HARD: "Pomalo teško",
  HARD: "Teško",
  VERY_HARD: "Veoma teško",
  TOO_HARD: "Izuzetno teško",
  EXTREMELY_HARD: "Nepodnošljivo teško",
  EXERCISE_HISTORY: "Istorija vežbe",
  EDIT: "Izmeni",
  EDIT_WORKOUT: "Izmeni trening",
  START_DATE: "Datum početka",
  END_DATE: "Završni datum",
  ADD_MEAL: "Dodaj obrok",
  ADD_GROUP: "Dodaj grupu",
  DINNER: "Večera",
  CREATE_MEAL: "Kreiraj obrok",
  CREATE_GROUP: "Kreiraj grupu",
  CREATE_TYPE: "Kreiraj tip",
  EDIT_GROUP: "Izmeni grupu",
  TITLE: "Naziv",
  DATE: "Datum",
  CREATE: "Kreiraj",
  ADD_PHOTO: "Dodaj fotografiju",
  ENTER_RESULTS: "Unesi rezultate",
  ENTER_RESULTS_FOR: "Unesi rezultate za",
  RESULTS_ENTERED_SUCCESSFULLY: "Rezultati uspešno uneseni",
  RECORD_DATE: "Postavljen datuma",
  PERSONAL_RECORD: "Lični rekord",
  HISTORY: "Istorija",
  NO_EXERCISE_HISTORY: "Nema istorije za ovu vežbu",
  WORKOUT_HISTORY: "Istorija treninga",
  WORKOUT: "Trening",
  PHOTO: "Fotografija",
  SELECT_MEASUREMENT: "Izaberi meru",

  SELECT_CLIENTS: "Izaberi klijente",
  CLIENTS_PREVIEW: "Pregled klijenta",
  GROUP_SETTINGS: "Podešavanja grupe",
  CLIENT_SETTINGS: "Podešavanje klijenata",
  NOTIFICATIONS_SETTINGS: "Podešavanje notifikacija",
  CLIENTS_CAN_POST: "Klijenti mogu da šalju poruke",
  AUTO_POST_FINISHED_WORKOUT: "Automatsko postavljanje završenog treninga",
  ALWAYS: "Uvek",
  ON_MENTION_ONLY: "Samo na pomen",
  NEVER: "Nikad",
  DATE_FROM: "Datum od",
  DATE_TO: "Datum do",
  SUCCESSFULLY_CREATED_GROUP: "Uspešno kreirana grupa",

  GOAL: "Cilj",
  CURRENTLY: "Trenutno",
  START_FASTING: "Započni fast",
  STOP_FASTING: "Prekini fast",
  HOW_LONG_DO_YOU_PLAN_TO_FAST_FOR: "Koliko dugo planiraš da fastuješ",
  START: "Započni",
  YOU_STARTED: "Započeli ste",
  TOTAL_FASTS: "Ukupno fastinga",
  AVERAGE: "Prosek",
  THE_LONGEST_FAST: "Najduži fast",
  THE_LONGEST_STREAK: "Najviše uzastupno",
  CURRENT_STREAK: "Trenutno uzastupno",
  DETAILED_VIEW: "Detaljni pregled",
  DETAILS: "Detalji",
  TEMPLATE_RECIPE: "Recept iz biblioteke",
  TEMPLATE_INGREDIENT: "Namirnica iz biblioteke",
  TEMPLATE_NOTE: `
    Kreiran od strane administratora aplikacije. Možeš ga kopirati i dalje
    koristiti kao sopstveni.
  `,
  LEGS_WORKOUT: "Trening nogu",
  GALLERY_ENTRY: "Unos u galeriju",
  JANUARY: "Januar",
  FEBRUARY: "Februar",
  MARCH: "Mart",
  APRIL: "April",
  MAY: "Maj",
  JUNE: "Jun",
  JULY: "Jul",
  AUGUST: "Avgust",
  STEPS: "Broj koraka",
  TEN_MINUTE_WALK: "Deset minuta šetnje",
  SEPTEMBER: "Septembar",
  OCTOBER: "Octobar",
  NOVEMBER: "Novembar",
  DECEMBER: "Decembar",
  TOTAL_NUMBER_OF_CLIENTS: "Ukupan broj klijenata",
  TOTAL_NUMBER_OF_GROUPS: "Ukupan broj grupa",
  ASSIGN_MEAL_PLAN_TO_CLIENT: "Dodeli plan ishrane klijentu",
  ASSIGN_MEAL_PLAN_TO_GROUP: "Dodeli plan ishrane grupi",
  ASSIGN_WORKOUT_PLAN: "Dodeli plan treninga",
  ASSIGN_WORKOUT_PLAN_TO_CLIENT: "Dodeli plan treninga klijentu",
  ASSIGN_WORKOUT_PLAN_TO_GROUP: "Dodeli plan treninga grupi",
  TRANSFER_TO_ANOTHER_TRAINER: "Dodeli drugom treneru",
  NOTE_UPDATED_SUCCESSFULLY: "Beleška uspešno ažurirana",
  CANCEL: "Otkaži",

  // krle
  SKIP: "Preskoči",
  EMAIL_COPIED_TO_CLIPBOARD: "Email je iskopiran",
  LOGIN_COPIED_TO_CLIPBOARD: "Login link je iskopiran",

  ACTIVE: "Aktivni",
  DIARY: "Dnevnik",

  SAVE: "Sačuvaj",
  PER: "Na",
  IMPORT: "Uvezi",
  CHOOSE: "Izaberi",
  CURRENCY: "Valuta",
  CURRENCY_AMOUNT: "Iznos",
  HEADERS_WORKOUT_NOTE: "Zaglavlja vizuelno odvajaju vežbe",
  ACCOUNT_EXPIRATION_DATE: "Datum isteka naloga",
  PAYMENT_DATE: "Datum uplate",
  EXPIRATION_DATE: "Datum isteka",
  NOTIFICATION_DATE: "Datum notifikacije",
  GENDER: "Pol",
  MALE: "Muški",
  SHOW_ACTIVE: "Prikaži aktivne",
  FEMALE: "Ženski",
  DATE_OF_BIRTH: "Datum rođenja",
  HEIGHT: "Visina",
  SEND: "Pošalji",
  AREA_CODE: "Pozivni broj",
  DAILY_MACRO_GOALS: "Dnevni makro ciljevi",
  SELECT_ACTION: "Izaberi akciju",
  BY_CALORIES: "Po kalorijama",
  RESTORE: "Vrati",
  BY_GRAMS_OF_MACRONUTRIENTS: "Po gramaži makronutrienta",
  EXPIRING: "Ističe",
  TOMORROW: "Sutra",
  TODAY: "Danas",
  TAGS: "Oznake",
  CLIENT_INTERACTIONS: "Klijentove interakcije",
  SELECT_ALL: "Izaberi sve",
  SELECT_LOADED: "Izaberi učitane",
  COMMENTING: "Komentarisanje",
  CREATING_GROCERIES: "Kreiranje namirnica",
  CREATING_EXERCISES: "Kreiranje vežbi",
  ACCOUNT_EXPIRED_CONTACT_TRAINER:
    "Vaš nalog je istekao, kontaktirajte trenera za više informacija.",
  CHATTING: "Slanje poruka",
  CHAT_WITH_CLIENT: "Chat sa klijentom",
  DELETE_FROM_GROUP: "Izbaci iz grupe",
  AUTOMATED_MESSAGE: "Automatska poruka",
  AUTOMATED_U_MESSAGE: "Automatsku poruku",
  DATE_OF_DISPATCH: "Datum otpremanja",
  DISPATCH_METHOD: "Način otpremanja",
  IN_APP: "U aplikaciji",
  MY_MEAL_PLANS: "Moji planovi ishrane",
  LIBRARY: "Biblioteka",
  NUMBER_OF_MEAL_PLANS: "Ukupan broj planova ishrane",

  SEARCH_MEAL_PLANS: "Pretraži planove ishrane...",
  SEARCH_RECIPES: "Pretraži recepte...",
  SEARCH_INGREDIENTS: "Pretraži namirnice...",
  SEARCH_TRAINING_PROGRAMS: "Pretraži programe treninga...",
  SEARCH_WORKOUTS: "Pretraži treninge...",
  SEARCH_EXERCISES: "Pretraži vežbe...",

  SEARCH_PROGRAM: "Pronađi program...",
  SEARCH_CLIENT: "Pronađi klijenta...",
  SEARCH_CLIENTS: "Pretraži klijente...",
  SEARCH_GROUPS: "Pretraži grupe...",
  DURATION: "Trajanje",
  CLIENT_NOTES: "Beleške o klijentu",
  ITEM_COUNT: "Broj stavki",
  DURATION_IN_DAYS: "Trajanje u danima",
  DAYS: "Dana",
  DEFAULT_IMAGE_LOADED_AUTOMATICALLY:
    "Podrazumevana slika je učitana automatski",
  NOTE: "Napomena",
  PHONE_NUMBER: "Broj telefona",
  OMIT_FROM_AUTOMATIC_MESSAGE: "Izostavi iz automatske poruke",

  SETTINGS_CLIENT_TAGS_NOTE: `
    Napravi svoje oznake ovde a onda ih koristi da označiš klijente. Nakon toga
    možeš da filtriraš klijente po svojim oznakama.
  `,
  SETTINGS_DEFAULT_HABITS_NOTE: `
    Ove navike će automatski biti dodeljene svakom novom klijentu koga kreiraš.
  `,
  SETTINGS_DEFAULT_MEASUREMENTS_NOTE: `
    Ove mere će automatski biti dodeljene svakom novom klijentu koga kreiraš.
  `,
  SETTINGS_INGREDIENT_TYPES_NOTE: `
    Kreiraj svoj tip namirnica, dodeli tip nekoj namirnici a onda lakše
    filtriraj namirnice po tipovima.
  `,
  SETTINGS_RECIPE_TYPES_NOTE: `
    Kreiraj svoj tip recepta, dodeli tip nekom receptu a onda lakše filtriraj
    recepte po tipovima.
  `,
  SETTINGS_MEAL_TYPES_NOTE: `
    Kreiraj svoj tip plana ishrane, dodeli tip nekom planu ishrane a onda lakše
    filtriraj planove po tipovima.
  `,
  SETTINGS_CLIENTS_NOTE: `
    Ovde unesi lozinku koja će biti postavljena svakom tvom novom klijentu koga
    napraviš! Poželi im dobrodošlicu porukom na njihov email.
  `,

  ADD_CREATE_MEAL_PLANS_NOTE:
    "Unošenjem trajanja, brojač se pokreće odmah nakon dodele plana nekom klijentu. Ukoliko sam/a postaviš datum isteka, vrednost postavljenog trajanja se ignoriše. Jedino obavezno polje je naziv plana ishrane. Unosom tipa ishrane olakšavaš kasniju pretragu i filtriranje planova.",
  MEAL_PLAN_TYPE: "Tip plana ishrane",
  TYPE: "Tip",
  TAG: "Oznaka",
  TAG_U: "Oznaku",
  SET_TYPE: "Postavi tip",
  EDIT_TYPE: "Izmeni tip",
  NEW_TYPE: "Novi tip",
  NEW_TYPE_TITLE: "Naziv novog tipa",
  NEW_TAG: "Nova oznaka",
  NEW_TAG_NAME: "Naziv nove oznake",
  NEW_TAG_COLOR: "Boja nove oznake",
  NEW_TAG_COLOR_HINT: "Klikni na ikonicu kako bi odabrao/la boju oznake",
  NEW_CLIENT_DEFAULT_PASSWORD: "Podrazumevana lozinka novog klijenta",
  WELCOMING_EMAIL: "Email dobrodošlice",
  CREATE_TAG: "Kreiraj oznaku",
  EDIT_TAG: "Izmeni oznaku",
  CREATE_HABIT: "Kreiraj naviku",
  CREATE_MEASUREMENT: "Kreiraj meru",
  ASSIGN: "Dodeli",
  // CreateAMealDialog
  CREATE_A_MEAL: "Kreiraj obrok",
  TEXT: "Tekst",
  // CreateHeaderDialog
  HEADER: "Zaglavlje",
  CREATE_HEADER: "Kreiraj zaglavlje",
  DISPLAY_MACRONUTRIENT_VALUES: "Prikaži makronutritivne vrednosti",
  CREATE_HEADER_NOTE:
    "Zaglavlja sumiraju makronutritivne vrednosti sadržanih obroka ",
  // UploadPDFMeal
  UPLOAD_PDF_MEAL: "Uvezi PDF obrok",
  NOTE_CREATED_SUCCESSFULLY: "Beleška uspešno kreirana",
  ADVANCED_SETTINGS: "Napredna podešavanja",
  // MealPlan
  TYPE_TEXTUALLY: "Unesi tekstualno",
  UPLOAD_PDF: "Uvezi PDF",
  ADD_HEADER: "Dodaj zaglavlje",
  TOTAL_MEAL_COUNT: "Ukupan broj obroka",

  // Input validation
  MIN_LENGTH: "Minimalna dužina polja je 2",
  FIELD_IS_REQUIRED: "Polje je obavezno",
  // Ingredient
  CLIENT_PROFILE: "Profil klijenta",
  INGREDIENT: "Namirnica",
  TOTAL_INGREDIENT_COUNT: "Ukupan broj namirnica",
  TOTAL_RECIPE_COUNT: "Ukupan broj recepata",
  TOTAL_PROGRAMS_COUNT: "Ukupan broj programa",
  INGREDIENT_TYPE: "Tip namirnice",
  INGREDIENT_TYPES: "Tipovi namirnica",
  FOOD_TYPE: "Tip ishrane",
  DIRECTIONS: "Uputstvo",

  NO_DESCRIPTION: "Nema opis",
  NO_DIRECTIONS: "Ne postoji uputstvo",
  NO_FOOD_TYPE: "Nema tip",
  NO_TYPE: "Nema tip",
  NO_RECIPES: "Nema recepata",
  NO_INGREDIENTS: "Nema namirnica",

  AMOUNT_PER_UNIT: "Količina po jedinici",
  CALCULATE_CALORIES_AUTOMATICALLY: "Računaj kalorije automatski",
  CREATE_EDIT_INGREDIENT_NOTE: `
    Nijedno od polja (osim naziva) nije neophodno ali, popunjavanjem
    istih, olakšavaš kasniju pretragu i filtriranje.
  `,
  // Recipe
  FULL_NAME: "Puno ime",
  RECIPE_TYPE: "Tip recepta",
  RECIPE_TYPES: "Tipovi recepata",
  CREATE_INGREDIENT: "Kreiraj namirnicu",
  IMPORT_INGREDIENTS: "Uvezi namirnice",
  IMPORT_WORKOUT: "Uvezi trening",
  ADD_EDIT_RECIPE_NOTE: `
    Kalorije se automatski preračunavaju. Ukoliko ste sami definisali vrednost,
    biće preklopljena novom. Kasnije je možete ručno izmeniti.
  `,
  CANT_DO_EXERCISE: "Ne može vežbu",
  CAN_DO_EXERCISE: "Može vežbu",
  CANT_DO_EXERCISES: "Vežbe koje ne može",
  RECIPE_MACRONUTRIENT_STATS: "Makronutritivne vrednosti recepta",
  RECIPE_UPDATED_SUCCESSFULLY: "Recept uspešno ažuriran",
  RECIPE_UPDATED_FAILED: "Došlo je do greške prilikom ažuriranja recepta",
  // Forms
  FIELD_MINIMUM_AMOUNT_0: "Najmanje 0",
  FIELD_MINIMUM_AMOUNT_1: "Najmanje 1",
  FIELD_MINIMUM_AMOUNT_500: "Najmanje 500",
  FIELD_MAXIMUM_AMOUNT_5: "Najviše 5",
  FIELD_MAXIMUM_AMOUNT_200: "Najviše 200",
  FIELD_MAXIMUM_AMOUNT_1000: "Najviše 1000",
  FIELD_MAXIMUM_AMOUNT_5000: "Najviše 5000",
  FIELD_MAXIMUM_AMOUNT_10000: "Najviše 10000",
  FIELD_MAXIMUM_AMOUNT_17000: "Najviše 17000",
  FIELD_MINIMUM_2: "Minimalna dužina polja je 2",
  FIELD_MINIMUM_4: "Minimalna dužina polja je 4",
  FIELD_MAXIMUM_10: "Maksimalna dužina polja je 10",
  FIELD_MAXIMUM_15: "Maksimalna dužina polja je 15",
  FIELD_MAXIMUM_20: "Maksimalna dužina polja je 20",
  FIELD_MAXIMUM_40: "Maksimalna dužina polja je 40",
  FIELD_MAXIMUM_320: "Maksimalna dužina polja je 320",
  FIELD_MAXIMUM_5000: "Maksimalna dužina polja je 5000",
  FIELD_THREE_DIGITS: "Polje mora da sadrži tri cifre",
  FIELD_REQUIRED: "Polje je obavezno",
  YOUTUBE_LINK_INVALID: "Youtube link nije validan",
  EMAIL_INVALID: "Email nije validan",
  EMAIL_TAKEN: "Email je zauzet",
  PHONE_NUMBER_INVALID: "Broj telefona nije validan",

  REPETITION: "Ponavljanje",
  REPETITION_A: "Ponavljanja",
  SERIES: "Serije",
  BREAK: "Odmor",
  TIME: "Vreme",
  DISTANCE: "Distanca",
  YOUTUBE_LINK: "YouTube link",

  ENDURANCE: "Izdržljivost (Ponavljanja, npr. Burpee)",
  STRENGTH: "Snaga (Ponavljanja x Težine, npr. Čučanj)",
  CARDIO: "Kardio (npr. Trčanje)",
  TIMED_LONGER_BETTER: "Vremenski duže bolje (npr. Plank)",
  TIMED_FASTER_BETTER: "Vremenski brže bolje (npr. Sprint)",
  TIMED_STRENGTH: "Vremenski snaga (Sekunde x Težine)",

  DONT_SHOW_ANYMORE: "Ne prikazuj više",
  UPDATE_AVAILABLE: "Dostupno ažuriranje",
  UPDATE_TO_USE_APP:
    "Ažurirajte na najnoviju verziju da biste nastavili da koristite aplikaciju",
  REFRESH_TO_USE_APP:
    "Osvežite stranicu (F5) da biste nastavili da koristite aplikaciju",
  CURRENT_VERSION: "Trenutna verzija",
  NEWEST_VERSION: "Najnovija verzija",

  // FILTERI
  // Core Filters
  ALL: "Svi",
  MY: "Moji",

  SHOW_ARCHIVED: "Prikaži arhivirane",
  SHOW_ALL: "Prikaži sve",
  SHOW_DELETED: "Prikaži obrisane",

  NAME_ASC: "Po imenu rastuće",
  NAME_DESC: "Po imenu opadajuće",
  RETURN_FROM_ARCHIVE: "Vrati iz arhive",
  DATE_ASC: "Po datumu kreiranja rastuće",
  DATE_DESC: "Po datumu kreiranja opadajuće",
  LAST_LOGIN_ASC: "Po poslednjem logovanju rastuće",
  LAST_LOGIN_DESC: "Po poslednjem logovanju opadajuće",
  NEVER_LOGIN_ASC: "Nikad ulogovan/a rastuće",
  NEVER_LOGIN_DESC: "Nikad ulogovan/a opadajuće",
  INACTIVE_CHAT_ASC: "Neaktivan chat rastuće",
  INACTIVE_CHAT_DESC: "Neaktivan chat opadajuce",

  // Foods Filters
  BAR: "Makro filter",

  // Exercises Filters
  ABS: "Trbuh",
  BICEPS: "Biceps",
  CALVES: "Listovi",
  CHEST: "Grudi",
  FOREARM: "Podlaktica",
  GLUTE: "Zadnjica",
  HAMSTRINGS: "Zadnja loža",
  LOWER_BACK: "Donja leđa",
  QUADS: "Prednja loža",
  SHOULDERS: "Ramena",
  TRICEPS: "Triceps",
  UPPER_BACK: "Gornja leđa",

  PROTOCOL: "Protokol",

  SELECT_MORE: "Izaberi više",
  CREATE_REST_DAY: "Kreiraj dan odmora",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_ACCOUNT:
    "Ako ste sigurni da želite da obrišete nalog, unesite svoju email adresu ispod:",
  ARE_YOU_SURE: "Da li ste sigurni",
  SUCCESSFULLY_DELETED_ACCOUNT: "Uspešno obrisan nalog",
  REST_DAY: "Dan odmora",
  // SUCCESS
  SUCCESSFULLY_SENT_SMS: "SMS poruka je uspešno poslata",
  // ERRORS
  ERROR_SENDING_SMS: "Došlo je do greške pri slanju SMS poruke",
  ERROR_CHANGING_ORDER: "Došlo je do greške pri menjanju redosleda",

  ERROR_ASSIGNING_MEAL_PLAN:
    "Došlo je do greške pri dodeljivanju plana ishrane",
  ERROR_ASSIGNING_TRAINING_PROGRAM:
    "Došlo je do greške pri dodeljivanju trening programa",
  CREATE_NEW_MEASUREMENT: "Dodaj novu meru",
  EDIT_MEASUREMENT: "Izmeni meru",
  HAS_FINISHED_ALL_WORKOUTS: "je završio/la sve treninge",
  SUPERSET_BROKEN_SUCCESSFULLY: "Superset je uspešno rasformiran",
  SUPERSET_DELETED_SUCCESSFULLY: "Superset je uspešno obrisan",

  ERROR_COPYING_INGREDIENT: "Došlo je do greške pri kopiranju namirnice",
  ERROR_COPYING_RECIPE: "Došlo je do greške pri kopiranju recepta",
  ERROR_COPYING_MEAL: "Došlo je do greške pri kopiranju obroka",
  ERROR_COPYING_MEAL_PLAN: "Došlo je do greške pri kopiranju plana ishrane",
  ERROR_COPYING_EXERCISE: "Došlo je do greške pri kopiranju vežbe",
  HAS_FINISHED_MEAL: "je završio/la obrok",

  ERROR_COPYING_TRAINING_PROGRAM:
    "Došlo je do greške pri kopiranju programa treninga",
  ERROR_COPYING_WORKOUT: "Došlo je do greške pri kopiranju treninga",

  ERROR_CREATING_INGREDIENT: "Došlo je do greške pri kreiranju namirnice",
  ERROR_CREATING_RECIPE: "Došlo je do greške pri kreiranju recepta",
  ERROR_CREATING_MEAL: "Došlo je do greške pri kreiranju obroka",
  ERROR_CREATING_MEAL_PLAN: "Došlo je do greške pri kreiranju plana ishrane",

  ERROR_CREATING_EXERCISE: "Došlo je do greške pri kreiranju vežbe",
  ERROR_CREATING_REST_DAY: "Došlo je do greške pri kreiranju dana odmora",
  ERROR_CREATING_WORKOUT: "Došlo je do greške pri kreiranju treninga",
  ERROR_CREATING_TRAINING_PROGRAM:
    "Došlo je do greške pri kreiranju programa treninga",
  ERROR_CREATING_GROUP: "Došlo je do greške pri kreiranju grupe",
  ERROR_CREATING_CLIENT: "Došlo je do greške pri kreiranju klijenta",
  ERROR_CREATING_PAYMENT: "Došlo je do greške pri kreiranju uplate",
  ERROR_CREATING_GOAL: "Došlo je do greške pri kreiranju cilja",
  CLIENT_MEAL_PLAN_EXPIRE_TOMORROW: "plan ishrane ističe sutra",

  ERROR_DELETING_INGREDIENT: "Došlo je do greške pri brisanju namirnice",
  ERROR_DELETING_RECIPE: "Došlo je do greške pri brisanju recepta",
  ERROR_DELETING_MEAL_PLAN: "Došlo je do greške pri brisanju plana ishrane",
  ERROR_DELETING_TRAINING_PROGRAM:
    "Došlo je do greške pri brisanju programa treninga",
  SUCCESSFULLY_UPDATED_TAGS: "Uspešno ažurirane oznake",
  GENERATE_WORKOUT: "Generiši trening",
  ERROR_DELETING_EXERCISE: "Došlo je do greške pri brisanju vežbe",
  ERROR_DELETING_WORKOUT: "Došlo je do greške pri brisanju treninga",
  ERROR_DELETING_GROUP: "Došlo je do greške pri brisanju grupe",

  ERROR_EDITING_INGREDIENT: "Došlo je do greške pri menjanju namirnice",
  ERROR_EDITING_RECIPE: "Došlo je do greške pri menjanju recepta",
  ERROR_EDITING_MEAL: "Došlo je do greške pri menjanju obroka",
  ERROR_EDITING_MEAL_PLAN: "Došlo je do greške pri menjanju plana ishrane",

  ERROR_EDITING_EXERCISE: "Došlo je do greške pri menjanju vežbe",
  ERROR_EDITING_REST_DAY: "Došlo je do greške pri menjanju dana odmora",
  ERROR_EDITING_WORKOUT: "Došlo je do greške pri menjanju treninga",
  ERROR_EDITING_TRAINING_PROGRAM:
    "Došlo je do greške pri menjanju programa treninga",
  ERROR_EDITING_GROUP: "Došlo je do greške pri menjanju grupe",
  ERROR_EDITING_GOAL: "Došlo je do greške pri menjanju cilja",

  ERROR_IMPORTING_RECIPES: "Došlo je do greške pri uvozu recepata",
  ERROR_IMPORTING_WORKOUT: "Došlo je do greške pri uvozu treninga",
  // Notifications
  MEAL_PLAN_EXPIRES_TODAY: "plan ishrane ističe danas",
  PROGRAM_EXPIRES_TODAY: "plan treninga ističe danas",
  PROGRAM_EXPIRES_TOMORROW: "plan treninga ističe sutra",
  HAS_FINISHED_WORKOUT: "je završio trening",
  COMMENT: "Komentar",
  COMMENTED_MEAL: "je komentarsiao/la obrok",
  SHOW_AI_SUMMARY: "Prikaži dugme za AI sažetak",
  SHOW_AI_SUGGESTION: "Prikaži dugme za AI predlog",
  COMMENTED_EXERCISE: "je komentarisao/la vežbu",
  COMMENTED_WORKOUT: "je komentarisao/la trening",
  IS_TRACKING_A_NEW_MEASUREMENT: "je počeo/la da prati novu meru",
  TRACK_NEW_MEASUREMENT: "Dodaj novu meru",
  SHOW_ONLY_UNREAD: "Prikaži samo nepročitane",
  SHOW_ONLY_COMMENTS: "Prikaži samo komentare",
  ALL_NOTIFICATIONS_MARKED_AS_READ:
    "Sve notifikacije su označene kao pročitane",
  TEMPLATE_MESSAGE: "Šablonska poruka",
  TEMPLATE_MESSAGE_U: "Šablonsku poruku",
  TEMPLATE_MESSAGES: "Šablonske poruke",
  TEMPLATE_MESSAGE_DELETED_SUCCESSFULLY: "Uspešno obrisana šablonska poruka",
  CREATE_TEMPLATE_MESSAGE: "Kreiraj šablonsku poruku",
  EDIT_TEMPLATE_MESSAGE: "Izmeni šablonsku poruku",
  SEND_TO_ARCHIVE: "Arhiviraj klijenta",
  SEND_NOW: "Pošalji odmah",
  CHAT_HELPER: "Chat pomagalo",
  SHOW_CHAT_HELPER: "Prikaži chat pomagalo za ime",
  SUCCESSFULLY_ADDED_EMAIL_EXCEPTION: "Email uspešno dodat u listu izuzetaka",
  SUCCESSFULLY_DELETED_EMAIL_EXCEPTION:
    "Email uspešno uklonjen iz liste izuzetaka",
  HABIT_SUCCESSFULLY_ADDED: "Uspešno dodata navika",

  SUCCESS_ADDING_EXERCISE: "Uspešno dodata vežba",
  SUCCESS_DELETING_EXERCISE: "Uspešno obrisana vežba",
  ERROR_ADDING_EXERCISE: "Došlo je do greške prilikom dodavanja vežbe",

  ERROR_ALREADY_ADDED_EXERCISE: "Vežba je već dotada",

  CONNECT: "Poveži",
  CONNECT_WITH_PARENT_EXERCISE: "Poveži s glavnom vežbom",
  SUCCESS_CONNECTED_WITH_PARENT_EXERCISE: "Uspešno povezana sa glavnom vežbom.",
  ERROR_CONNECTED_WITH_PARENT_EXERCISE:
    "Došlo je do greške prilikom povezivanja sa glavnom vežbom.",
  OUR_RECOMMENDATIONS: "Naši predlozi",
  OUR_RECOMMENDATION_NOTE:
    "Ako nijedna vežba ne odgovara, slobodno možete sami pretražiti.",
  YOUVE_SELECTED_EXERCISE: "Izabrali ste vežbu",

  CREATE_NEW_HABIT: "Prati novu naviku",
  CLIENT_TAGS: "Oznake klijenta",
  NUMBER_OF_CLIENTS: "Broj klijenata",
  HABIT_SUCCESSFULLY_DELETED: "Uspešno obrisana navika",
  HABITU: "naviku",
  EDIT_HABIT: "Izmeni naviku",
  ADD_NEW_PAYMENT: "Dodaj novu uplatu",
  COMPARE_PHOTOS: "Uporedi fotografije",
  SELECT_TWO_PHOTOS: "Izaberi dve fotografije",
  SELECT_ONE_MORE_PHOTO: "Izaberi još jednu fotografiju",
  COMPARE_PHOTOS_NOTE: "Izabrane fotografije biće prikazane ovde",
  ACCOUNT_EXPIRES_TODAY: "nalog ističe danas",
  IN: "za",
  EXPIRY: "Istek",
  ACCOUNT: "Nalog",
  TRAINING: "Trening",
  REMINDER: "Podsetnik",
  RESET: "Resetuj",
  RESET_PASSWORD: "Resetuj lozinku",
  EXPIRY_VIEW: "Pregled isteka",
  REMINDERS: "Podsetnici",
  HABIT_UPDATED_SUCCESSFULLY: "Navika uspešno izmenjena",
  REMINDER_DELETED_SUCCESSFULLY: "Podsetnik uspešno obrisan",
  WAIST_CIRCUMFERENCE: "Obim struka",
  ORDER_CHANGED_SUCCESSFULLY: "Redosled uspešno izmenjen",
  MEASUREMENT_DELETED_SUCCESSFULLY: "Mera uspešno obrisana",
  CLIENT_UNARCHIVED_SUCCESSFULLY: "Klijent uspešno vraćen iz arhive",
  USER_IS_TRAINER: "Korisnik je trener",
  MEASUREMENTU: "Meru",
  ACCOUNT_DELETED: "Nalog obrisan",
  CLIENT_DELETED_SUCCESSFULLY: "Klijent uspešno obrisan",
  CLIENT_UNDELETED_SUCCESSFULLY: "Klijent uspešno vraćen",
  USER_NOT_FOUND: "Korisnik nije pronađen",
  MEASUREMENT_UPDATED_SUCCESSFULLY: "Mera uspešno ažurirana",
  CLIENT_ARCHIVED_SUCCESSFULLY: "Klijent uspešno arhiviran",
  COMPLIANCE: "Pridržavanje",
  BOOKMARKED_MESSAGES: "Bookmarkovane poruke",
  SUCCESSFULLY_BOOKMARKED_MESSAGE: "Poruka uspešno bookmarkovana",
  ADDED_NEW_GALLERY_ENTRY: "je dodao/la novi unos u galeriju",
  USER_IS_BUGGED: "Korisnik ima grešku",
  BREAK_SUPERSET: "Rasformiraj superset",
  MEAL_TITLE: "Naziv obroka",
  WORKOUT_FINISHED: "Trening završen",
  CHAT_HELPERS: "Chat pomagala",
  TOTAL_SELECTED_CLEIENTS: "Ukupan broj izabranih",
  SUCCESSFULLY_RESET_PASSWORD: "Uspešno resetovana lozinka",
  ARCHIVE_DESCRIPTION:
    "Arhiviranjem deaktiviraš klijenta ali ga ne brišeš. Arhiviranim klijentima možeš pristupiti odabirom opcije 'Prikaži arhivirane' u filterima",
};

const translate = (str: string): string => {
  const lang = get(language);
  if (!lang) return (sr as any)[str];
  return lang === "en" ? (en as any)[str] : (sr as any)[str];
};

export { translate };
