<script lang="ts">
  import {onMount} from "svelte";
  import {postWithJwt, serverlessRoutes, translate} from "lib";
  import {alertStore, journalMealsStore, dialogData, selectedIdsStore, mealPlanStore, recipesStore} from "stores";
  import {ButtonComponent, InfiniteScroll} from "ui";
  import {RecipeComponent, RecipesToolbarComponent} from "diet";
  import type {PartialItemMap, Recipe} from "interfaces";

  let data: any;
  let scrollElement: HTMLDivElement;
  let isLoading = false;

  $: disabled = !$selectedIdsStore.length;

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    const {meal} = data;
    const mealId = meal.id;
    const partialRecipeMap: PartialItemMap<Recipe> = {};

    $selectedIdsStore.forEach((id): void => {
      partialRecipeMap[id] = {
        mealId,
        isDefault: 0,
        isTemplate: 0,
        trainerId: null
      };
    });

    const {data: rData, error} = await postWithJwt(
      `${serverlessRoutes.RECIPE}/copy`,
      {partialRecipeMap}
    );

    if (error && !rData) {
      isLoading = false;
      alertStore.show(translate("ERROR_IMPORTING_RECIPES"), "error");
      return console.error(error);
    }

    const {recipes} = rData;

    if (meal.finishedAt) {
      journalMealsStore.addMealRecipes(mealId, recipes);
    } else {
      mealPlanStore.addMealRecipes(mealId, recipes);
    }

    selectedIdsStore.clear();
    alertStore.show(translate("SUCCESSFULLY_IMPORTED_RECIPES"));

    $dialogData.data = {};
    $dialogData.type = "";
  };

  const mountScrollElement = (element: HTMLDivElement): void => {
    scrollElement = element;
  };

  onMount(recipesStore.fetchData);

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">
  <h3 class="text-xxl font-semibold text-center">{translate("ADD_RECIPES")}</h3>

  <RecipesToolbarComponent/>

  <div
    class="h-80 pr-2 flex flex-col gap-4 overflow-y-scroll"
    use:mountScrollElement
  >

    {#each $recipesStore.items as recipe}
      <RecipeComponent isSelectable {recipe}/>
    {/each}
  </div>

  <InfiniteScroll
    {scrollElement}
    hasMore="{$recipesStore.hasMore}"
    isFetchingMore="{$recipesStore.isFetchingMore}"
    on:loadMore="{recipesStore.fetchMoreData}"/>

  <div class="flex justify-center">
    <ButtonComponent {disabled} {isLoading} on:click="{onSubmit}">
      {translate("ADD")}
    </ButtonComponent>
  </div>
</div>
