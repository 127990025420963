import type { ExerciseSet } from "../interfaces/FinishWorkoutStats";

const generateFinishExerciseArray = (exercise, workoutId) => {
  const s: any = [];
  const exerciseId = exercise.id;
  if (exercise.stats.length > 0) {
    exercise.stats.forEach((row) => {
      const reps = row.reps;
      const rest = row.rest;
      const weight = row.weight;
      const parentExerciseId = row.parentExerciseId;
      const workoutId = row.workoutId;
      const set: ExerciseSet = {
        reps,
        rest,
        weight,
        exerciseId,
        parentExerciseId,
        workoutId,
      };
      s.push(set);
    });
  } else if (exercise.details?.sets) {
    Array.apply(null, Array(exercise.details.sets)).forEach((row) => {
      const reps =
        typeof exercise.details.reps === "string"
          ? +exercise.details.reps.replace(/\D/g, "")
          : exercise.details.reps;
      const rest =
        typeof exercise.details.rest === "string"
          ? +exercise.details.rest.replace(/\D/g, "")
          : exercise.details.rest;
      const weight = null;
      const set: ExerciseSet = {
        reps,
        rest,
        weight,
        exerciseId,
        workoutId,
        parentExerciseId: exercise.parentExerciseId,
      };
      s.push(set);
    });
  } else {
    for (let i = 0; i < 3; i++) {
      s.push({
        reps: 10,
        rest: 2,
        weight: 0,
        exerciseId,
        parentExerciseId: exercise.parentExerciseId,
        workoutId: exercise.workoutId,
      });
    }
  }
  return s;
};

export { generateFinishExerciseArray };
