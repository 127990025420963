<script lang="ts">
  import { onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { api, dateString, getWithJwt, translate } from "lib";
  import { clientGalleryEntries, clientGalleryEntriesCount, currentClient, user } from "stores";
  import { ButtonComponent, InfiniteScroll, MiniSpinner } from "ui";
  import GalleryEntry from "../Progress/GalleryEntry.svelte";

  let data: any;

  let selectedImages: Array<{src: string, date: string}> = [];
  let drawElements: Array<HTMLImageElement> = [];

  let canvasElement: HTMLCanvasElement;
  let galleriyEntriesElement: HTMLDivElement;
  let ctx: CanvasRenderingContext2D;

  let drew = 0;
  let take = 4;
  let skip = 0;
  let isLoading = false;
  let isDownloading = false;

  const fetchData = async (): Promise<void> => {
    if (!isLoading && $user && skip <= $clientGalleryEntriesCount) {
      isLoading = true;
      const clientId = $currentClient?.id || $user.id;
      const url = `${api}/gallery/all?take=${take}&skip=${skip}&clientId=${clientId}`;
      try {
        const data = await getWithJwt(url);
        $clientGalleryEntries = data.data;
        $clientGalleryEntriesCount = data.count;
        isLoading = false;
      } catch (err) {}
    }
  };
  const loadMoreData = async (): Promise<void> => {
    if (!isLoading && $user && skip <= $clientGalleryEntriesCount) {
      isLoading = true;
      const clientId = $currentClient?.id || $user.id;
      skip += take;
      const url = `${api}/gallery/all?take=${take}&skip=${skip}&clientId=${clientId}`;
      try {
        const data = await getWithJwt(url);
        $clientGalleryEntries = [...$clientGalleryEntries, ...data.data] as any;
        isLoading = false;
      } catch (err) {}
    }
  };

  const daysPassed = (): number => {
    const date1 = Date.parse(selectedImages[0].date);
    const date2 = Date.parse(selectedImages[1].date);
    const msInDay = 86400000;

    return Math.abs(Math.floor((date1 - date2) / msInDay));
  };

  const scrollElementLoaded = (element: HTMLDivElement): void => {
    galleriyEntriesElement = element;
  };

  const onSelect = (event: CustomEvent<{src: string, date: string}>): void => {
    const {src, date} = event.detail;
    const img = new Image();

    img.crossOrigin = "anonymous";
    img.src = src;

    selectedImages = [...selectedImages, {src, date}];
    drawElements = [...drawElements, img];
  };

  const onReset = (): void => {
    selectedImages = [];
    drawElements = [];
    drew = 0;
    ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
  };

  const onDownload = (): void => {
    isDownloading = true;

    canvasElement.width = drawElements[0].width + drawElements[1].width;
    canvasElement.height = drawElements[0].height > drawElements[1].height ?
      drawElements[0].height :
      drawElements[1].height;

    ctx.globalAlpha = 1;

    ctx.drawImage(drawElements[0], 0, 0);
    ctx.drawImage(drawElements[1], drawElements[0].width, 0);

    const link = document.createElement("a");
    const dataUrl = canvasElement.toDataURL("image/jpg");

    link.download = "compare.jpg";
    link.href = dataUrl;

    link.click();

    isDownloading = false;
  };

  onMount(async (): Promise<void> => {
    const context = canvasElement.getContext("2d");

    if (context) {
      ctx = context;
    }

    await fetchData();
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <canvas class="hidden" bind:this={canvasElement}></canvas>

  <div class="h-8 text-xxl text-center font-semibold">
    {translate("COMPARE_PHOTOS")}
  </div>

  <div
    class="flex flex-col gap-4 grow h-96 overflow-y-scroll"
    class:justify-center={selectedImages.length > 1}
    use:scrollElementLoaded
  >
    <div
      class="flex border rounded-md"
      class:items-center={!selectedImages.length}
      class:justify-center={!selectedImages.length}
    >
      {#if selectedImages.length}
        {#each selectedImages as selectedImage}
          <div class="relative basis-1/2 flex items-center justify-center">
            <img src={selectedImage.src} alt="Compare" />
          </div>
        {/each}
      {:else}
        <div class="text-center">{translate("COMPARE_PHOTOS_NOTE")}</div>
      {/if}
    </div>

    {#if selectedImages.length > 1}
      <div class="text-center">
        <div class="flex">
          <div class="basis-1/2">
            {dateString(new Date(selectedImages[0].date))}
          </div>
          <div class="basis-1/2">
            {dateString(new Date(selectedImages[1].date))}
          </div>
        </div>
        <div>{daysPassed()} dana</div>
      </div>
    {/if}

    {#if selectedImages.length < 2}
      <div in:slide out:slide>
        {#each $clientGalleryEntries as entry}
          <GalleryEntry isSelectMode {entry} on:select={onSelect} />
        {/each}
        <InfiniteScroll
          scrollElement={galleriyEntriesElement}
          hasMore={$clientGalleryEntries.length < $clientGalleryEntriesCount}
          on:loadMore={loadMoreData}
        />
        {#if isLoading}
          <div class="flex justify-center"><MiniSpinner/></div>
        {/if}
      </div>
    {/if}
  </div>

  <div class="text-center font-bold">
    {#if !selectedImages.length}
      {translate("SELECT_TWO_PHOTOS")}
    {:else if selectedImages.length < 2}
      {translate("SELECT_ONE_MORE_PHOTO")}
    {/if}
  </div>

  <div class="flex items-center justify-center gap-4">
    <ButtonComponent isOutline disabled={selectedImages.length < 1} on:click={onReset}>
      {translate("RESET")}
    </ButtonComponent>
    <ButtonComponent
      disabled={selectedImages.length < 2}
      isLoading={isDownloading}
      on:click={onDownload}
    >
      {translate("DOWNLOAD")}
    </ButtonComponent>
  </div>
</div>
