<script lang="ts">
  import {translate} from "lib";
  import {dialogData} from "stores";
  import {ButtonComponent} from "ui";

  let data: any;

  const onClose = (): void => {
    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onCreateCopy = async (): Promise<void> => {
    await data.executeFunction();
    onClose();
  };

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {#if data.type === "ingredient"}
      {translate("TEMPLATE_INGREDIENT")}
    {:else if data.type === "recipe"}
      {translate("TEMPLATE_RECIPE")}
    {/if}
  </div>

  <div class="text-justify">{translate("TEMPLATE_NOTE")}</div>

  <div class="flex justify-center gap-4">
    <ButtonComponent isOutline on:click="{onClose}">
      {translate("CLOSE")}
    </ButtonComponent>
    <ButtonComponent on:click="{onCreateCopy}">
      {translate("CREATE_COPY")}
    </ButtonComponent>
  </div>
</div>
