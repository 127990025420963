import { getWithJwt } from "../requests";

interface CloudflareStreamsGenerateResponse {
  uploadUrl: string;
  uid: string;
}

const generateCloudflareStreamsUploadUrl =
  async (requireSignedURLs: boolean): Promise<CloudflareStreamsGenerateResponse | null> => {
    const generateUrl = `https://streams.trainme-api.workers.dev/api/generate?requireSignedURLs=${requireSignedURLs}`;
    try {
      const res = await getWithJwt(generateUrl);
      const uploadUrl = res.result.uploadURL;
      const uid = res.result.uid;

      return { uploadUrl, uid };
    } catch (err) {
      return null;
    }
  };

const fetchCloudflareStreamsVideo = async (uid: string) => {
  const fetchUrl = `https://streams.trainme-api.workers.dev/api/video?videoId=${uid}`;
  try {
    const res = await getWithJwt(fetchUrl);
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const uploadVideoToCloudflareStreams = async (file: File, requireSignedURLs: boolean) => {
  const cloudfrontStreamsGenerateResponse = await generateCloudflareStreamsUploadUrl(requireSignedURLs);

  if (!cloudfrontStreamsGenerateResponse) {
    return {
      error: "Došlo je do greške prilikom slanja videa",
      videoUrl: null,
    };
  }

  const { uploadUrl, uid } = cloudfrontStreamsGenerateResponse;
  const formData = new FormData();
  formData.append("file", file);
  const uploadResult = await fetch(uploadUrl, {
    method: "POST",
    body: formData,
  });
  if (uploadResult.ok) {
    return {
      error: null,
      videoUrl: uid,
    };
  } else {
    return {
      error: "Došlo je do greške prilikom slanja videa",
      videoUrl: null,
    };
  }
};

export const isVideoReady = async (uid: string) => {
  const fetchVideo = await fetchCloudflareStreamsVideo(uid);
  if (fetchVideo.result.status.state !== "ready") return false;
  return true;
};

export const getVideoStatus = async (uid: string) => {
  const fetchVideo = await fetchCloudflareStreamsVideo(uid);
  if (fetchVideo.result.status.state !== "ready") return null;
  return fetchVideo.result;
};
