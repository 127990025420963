<script lang="ts">
  import {onMount} from "svelte";
  import {patchWithJwt, postWithJwt, serverlessRoutes, translate} from "lib";

  import {
    alertStore,
    journalMealsStore,
    dialogData,
    ingredientsStore,
    mealPlanStore,
    recipesStore
  } from "stores";

  import {
    ButtonComponent,
    InputField,
    SelectField,
    TextareaField,
    ToggleField,
  } from "ui";

  import {SetEditTypeComponent} from "components";

  let form = {
    name: {
      value: "",
      error: "",
    },
    foodType: {
      value: [],
      error: "",
    },
    measurementUnit: {
      value: "",
      error: "",
    },
    amountOnUnit: {
      value: 1,
      error: "",
    },
    carbs: {
      value: 0,
      error: "",
    },
    protein: {
      value: 0,
      error: "",
    },
    fats: {
      value: 0,
      error: "",
    },
    calculateCaloriesAutomatically: {
      value: false,
      error: "",
    },
    calories: {
      value: 0,
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
  };

  let data: any;
  let disabled = true;
  let isLoading = false

  const validateField = (fieldName: keyof typeof form): void => {
    if (fieldName === "name") {
      const fieldValue = form[fieldName].value;

      if (fieldValue.length === 0) {
        form[fieldName].error = "FIELD_REQUIRED";
      } else if (fieldValue.length < 2) {
        form[fieldName].error = "FIELD_MINIMUM_2";
      } else if (fieldValue.length > 320) {
        form[fieldName].error = "FIELD_MAXIMUM_320";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "amountOnUnit") {
      const fieldValue = form[fieldName].value;

      if (fieldValue < 1) {
        form[fieldName].error = "FIELD_MINIMUM_AMOUNT_1";
      } else if (fieldValue > 1000) {
        form[fieldName].error = "FIELD_MAXIMUM_AMOUNT_1000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "carbs") {
      const fieldValue = form[fieldName].value;

      if (fieldValue < 0) {
        form[fieldName].error = "FIELD_MINIMUM_AMOUNT_0";
      } else if (fieldValue > 1000) {
        form[fieldName].error = "FIELD_MAXIMUM_AMOUNT_1000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "protein") {
      const fieldValue = form[fieldName].value;

      if (fieldValue < 0) {
        form[fieldName].error = "FIELD_MINIMUM_AMOUNT_0";
      } else if (fieldValue > 1000) {
        form[fieldName].error = "FIELD_MAXIMUM_AMOUNT_1000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "fats") {
      const fieldValue = form[fieldName].value;

      if (fieldValue < 0) {
        form[fieldName].error = "FIELD_MINIMUM_AMOUNT_0";
      } else if (fieldValue > 1000) {
        form[fieldName].error = "FIELD_MAXIMUM_AMOUNT_1000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "calories") {
      const fieldValue = form[fieldName].value;

      if (fieldValue < 0) {
        form[fieldName].error = "FIELD_MINIMUM_AMOUNT_0";
      } else if (fieldValue > 17000) {
        form[fieldName].error = "FIELD_MAXIMUM_AMOUNT_17000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "description") {
      const fieldValue = form[fieldName].value;

      if (fieldValue.length > 5000) {
        form[fieldName].error = "FIELD_MAXIMUM_5000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (form.calculateCaloriesAutomatically.value) {
      const carbs = form.carbs.value || 0;
      const protein = form.protein.value || 0;
      const fats = form.fats.value || 0;

      form.calories.value = carbs * 4 + protein * 4 + fats * 9;

      if (form.calories.value < 0) {
        form.calories.error = "FIELD_MINIMUM_AMOUNT_0";
      } else if (form.calories.value > 17000) {
        form.calories.error = "FIELD_MAXIMUM_AMOUNT_17000";
      } else {
        form.calories.error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  let items = [
    {
      value: "g",
      name: translate("G"),
    },
    {
      value: "glass",
      name: translate("GLASS").toLowerCase(),
    },
    {
      value: "handfull",
      name: translate("HANDFULL"),
    },
    {
      value: "ml",
      name: translate("ML"),
    },
    {
      value: "oneof",
      name: translate("ONEOF"),
    },
    {
      value: "pinch",
      name: translate("PINCH"),
    },
    {
      value: "tablespoon",
      name: translate("TABLESPOON"),
    },
    {
      value: "teaspoon",
      name: translate("TEASPOON"),
    },
  ];

  let defaultMeasurementAmounts = new Map([
    ["g", 100],
    ["glass", 1],
    ["handfull", 1],
    ["ml", 100],
    ["oneof", 1],
    ["pinch", 1],
    ["tablespoon", 1],
    ["teaspoon", 1],
  ]);

  const isFormButtonDisabled = () => {
    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onMealPlanTypes = (event: CustomEvent<any>): void => {
    form.foodType.value = event.detail;
  };

  const onAutoCalories = (): void => {
    const carbs = form.carbs.value || 0;
    const protein = form.protein.value || 0;
    const fats = form.fats.value || 0;

    form.calories.value = carbs * 4 + protein * 4 + fats * 9;

    if (form.calories.value < 0) {
      form.calories.error = "FIELD_MINIMUM_AMOUNT_0";
    } else if (form.calories.value > 17000) {
      form.calories.error = "FIELD_MAXIMUM_AMOUNT_17000";
    } else {
      form.calories.error = "";
    }
  };

  const onMeasurementUnitChange = (): void => {
    form.amountOnUnit.value = defaultMeasurementAmounts.get(
      form.measurementUnit.value
    );

    if (form.amountOnUnit.value < 1) {
      form.amountOnUnit.error = "Najmanje 1";
    } else if (form.amountOnUnit.value > 1000) {
      form.amountOnUnit.error = "Najvise 1000";
    } else {
      form.amountOnUnit.error = "";
    }

    isFormButtonDisabled();
  };

  const createIngredient = async (): Promise<void> => {
    const partialIngredient = {
      name: form.name.value,
      amount: form.amountOnUnit.value || 0,
      carbs: form.carbs.value || 0,
      protein: form.protein.value || 0,
      fats: form.fats.value || 0,
      calories: form.calories.value || 0,
      foodType: form.foodType.value,
      description: form.description.value,
      measurementUnit: form.measurementUnit.value.toUpperCase(),
      originalAmount: form.amountOnUnit.value || 0,
      originalCarbs: form.carbs.value || 0,
      originalProtein: form.protein.value || 0,
      originalFats: form.fats.value || 0,
      originalCalories: form.calories.value || 0,
      isTemplate: 0,
      isDefault: 1,
      mealId: null,
      recipeId: null
    };

    const response = await postWithJwt(
      serverlessRoutes.INGREDIENT,
      {partialIngredient}
    );

    if (response.error && !response.data) {
      isLoading = false;
      alertStore.show(translate("ERROR_CREATING_INGREDIENT"), "error");
      return console.error(response.error);
    }

    ingredientsStore.add([response.data.ingredient]);
    alertStore.show(translate("SUCCESSFULLY_CREATED_INGREDIENT"));

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const editIngredient = async (): Promise<void> => {
    const partialIngredientMap = {
      [data.ingredient.id]: {
        name: form.name.value,
        amount: form.amountOnUnit.value || 0,
        carbs: form.carbs.value || 0,
        protein: form.protein.value || 0,
        fats: form.fats.value || 0,
        calories: form.calories.value || 0,
        foodType: form.foodType.value,
        description: form.description.value,
        measurementUnit: form.measurementUnit.value.toUpperCase()
      }
    };

    const response = await patchWithJwt(
      serverlessRoutes.INGREDIENT,
      {partialIngredientMap}
    );

    if (response.error && !response.data) {
      isLoading = false;
      alertStore.show(translate("ERROR_EDITING_INGREDIENT"), "error");
      return console.error(response.error);
    }

    const {ingredients} = response.data;
    const {id, isDefault, mealId, recipeId} = ingredients[0];

    if (isDefault) {
      ingredientsStore.replace(ingredients);
    } else if (recipeId && $mealPlanStore) {
      const mealId = mealPlanStore.getMealIdFromRecipeId(recipeId);

      if (mealId) {
        mealPlanStore.replaceRecipeIngredients(mealId, recipeId, ingredients);
      }
    } else if (recipeId && !$mealPlanStore) {
      const mealId = journalMealsStore.getMealIdFromRecipeId(recipeId);

      if (mealId) {
        journalMealsStore.replaceRecipeIngredients(mealId, recipeId, ingredients);
      } else {
        recipesStore.replaceIngredients(recipeId, ingredients);
      }
    } else if (mealId) {
      mealPlanStore.replaceMealIngredients(mealId, ingredients);
      journalMealsStore.replaceMealIngredients(mealId, ingredients);
    }

    alertStore.show(translate("SUCCESSFULLY_EDITED_INGREDIENT"));

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.ingredient) {
      await editIngredient();
    } else {
      await createIngredient();
    }
  };

  onMount((): void => {
    if (data.ingredient) {
      form.name.value = data.ingredient.name;
      form.foodType.value = data.ingredient.foodType;
      form.measurementUnit.value =
        data.ingredient.measurementUnit.toLowerCase();
      form.amountOnUnit.value = data.ingredient.amount;
      form.carbs.value = data.ingredient.carbs;
      form.protein.value = data.ingredient.protein;
      form.fats.value = data.ingredient.fats;
      form.calories.value = data.ingredient.calories;
      form.description.value = data.ingredient.description;
    } else {
      onMeasurementUnitChange();

      const g = defaultMeasurementAmounts.get("g");

      if (g) {
        form.measurementUnit.value = "g"
        form.amountOnUnit.value = g;
      }
    }
  });

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {data.ingredient ? translate("EDIT") : translate("CREATE")}
    {translate("INGREDIENT_U").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault="{onSubmit}">
    <InputField
      label={translate("NAME")}
      error={translate(form.name.error)}
      bind:value={form.name.value}
      on:input={() => validateField("name")}
    />

    <!-- <div>
      <div class="text-xs">{translate("INGREDIENT_TYPE")}:</div> -->
      <SetEditTypeComponent
        entity="ingredient"
        selectedTypes={data.ingredient ? data.ingredient.foodType.split(",") : []}
        on:types={onMealPlanTypes}
      />
    <!-- </div> -->

    <div class="flex gap-4">
      <div class="basis-2/5">
        <SelectField
          label={translate("MEASUREMENT_UNIT")}
          {items}
          bind:value={form.measurementUnit.value}
          on:change={onMeasurementUnitChange}
        />
      </div>

      <div class="basis-3/5">
        <InputField
          type="number"
          label={`${translate("AMOUNT_PER_UNIT")} / ${form.measurementUnit.value ? translate(form.measurementUnit.value.toUpperCase()).toLowerCase() : ""}`}
          error={translate(form.amountOnUnit.error)}
          bind:value={form.amountOnUnit.value}
          on:input={() => validateField("amountOnUnit")}
        />
      </div>
    </div>

    <div class="flex gap-4">
      <InputField
        type="number"
        label={translate("CARBS")}
        error={translate(form.carbs.error)}
        bind:value={form.carbs.value}
        on:input={() => validateField("carbs")}
      />

      <InputField
        type="number"
        label={translate("PROTEIN")}
        error={translate(form.protein.error)}
        bind:value={form.protein.value}
        on:input={() => validateField("protein")}
      />

      <InputField
        type="number"
        label={translate("FATS")}
        error={translate(form.fats.error)}
        bind:value={form.fats.value}
        on:input={() => validateField("fats")}
      />
    </div>

    <ToggleField
      label={translate("CALCULATE_CALORIES_AUTOMATICALLY")}
      bind:checked={form.calculateCaloriesAutomatically.value}
      on:change={() => {
        onAutoCalories();
        validateField("calories");
      }}
    />

    <InputField
      type="number"
      label={translate("CALORIES")}
      error={translate(form.calories.error)}
      disabled={form.calculateCaloriesAutomatically.value}
      bind:value={form.calories.value}
      on:input={() => validateField("calories")}
    />

    <TextareaField
      label={translate("DESCRIPTION")}
      error={translate(form.description.error)}
      bind:value={form.description.value}
      on:input={() => validateField("description")}
    />

    <div class="text-xxs text-justify">
      <span class="text-primary-500">{translate("NOTE")}:</span>
      {translate("CREATE_EDIT_INGREDIENT_NOTE")}
    </div>

    <div class="flex justify-center">
      <ButtonComponent type="submit" {disabled} {isLoading}>
        {data.ingredient ? translate("EDIT") : translate("CREATE")}
      </ButtonComponent>
    </div>
  </form>
</div>
