import {
  PushNotifications,
  type ActionPerformed,
  type PushNotificationSchema,
  type Token,
} from "@capacitor/push-notifications";
import { api } from "./constants";
import { postWithJwt } from "./requests";
import { Device } from "@capacitor/device";
import { push } from "svelte-spa-router";

const initNativePushNotifications = () => {
  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === "granted") {
      PushNotifications.register();
    } else {
    }
  });

  PushNotifications.addListener("registration", async (token: Token) => {
    const url = `${api}/notification/web_push`;
    try {
      const deviceId = await Device.getId();
      localStorage.setItem("pushToken", token.value);
      const res = await postWithJwt(url, {
        token: token.value,
        platform: "PUSH_NOTIFICATION_ID",
      });
    } catch (err) {}
  });
  PushNotifications.addListener("registrationError", (error: any) => {});

  PushNotifications.addListener(
    "pushNotificationReceived",
    (notification: PushNotificationSchema) => {
      // alert("Push received: " + JSON.stringify(notification));
    }
  );
  // Method called when tapping on a notification
  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification: ActionPerformed) => {
      if (notification.notification.data.url.includes("/chat")) {
        push("/chat");
      }
    }
  );
};

export default initNativePushNotifications;
