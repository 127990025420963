<script lang="ts">
  let type: "number" = "number";
  let value: number;

  export { type, value };
</script>

{#if type === "number"}
  <input
    class="
      h-4
      w-12
      p-1
      text-center
      font-bold
      border-x-0
      border-t-0
      border-b-1
      dark:bg-zinc-800
      focus:border-primary-500
      dark:focus:border-primary-500
    "
    type="number"
    bind:value
    on:input
    on:focusout
  />
{/if}

<style>
  input:focus {
    -webkit-box-shadow: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
