import {
  clientAvatarUrl,
  clientTrainerAvatarUrl,
  clientTrainerId,
} from "../stores/clientStores";
import { user } from "../stores/userStore";
import { get } from "svelte/store";
import { Roles, serverlessRoutes } from "./constants";
import { getWithJwt } from "./requests";
import { isClient } from "./roles";

export const setupAvatars = async () => {
  const userRef = get(user);
  if (userRef.avatarUrl) {
    clientAvatarUrl.set(userRef.avatarUrl);
  }
  if (userRef && isClient(userRef)) {
    if (userRef.trainer.avatarUrl) {
      clientTrainerAvatarUrl.set(userRef.trainer.avatarUrl);
    }
    if (userRef.trainer.id) clientTrainerId.set(userRef.trainer.id);
  }
};
