<script lang="ts">
  import {createEventDispatcher, afterUpdate} from "svelte";
  import {dragHandle} from "svelte-dnd-action";

  import {
    ButtonGroup,
    InputAddon,
    NumberInput,
  } from "flowbite-svelte";

  import {
    deleteWithJwt,
    dialogTypes,
    isClient,
    MealEntityType,
    postWithJwt,
    serverlessRoutes,
    translate
  } from "lib";

  import {
    alertStore,
    journalMealsStore,
    dialogData,
    ingredientsStore,
    selectedIdsStore,
    mealPlanStore,
    recipesStore,
    user,
    mealPlansStore,
    activeMealsStore
  } from "stores";

  import {CheckboxComponent, More, Svg, TemplateTagComponent} from "ui";
  import InputMini from "../UI/InputMini.svelte";
  import Macros from "./Macros.svelte";
  import type {Ingredient, MenuItem} from "interfaces";

  const dispatch = createEventDispatcher();

  export let ingredient: Ingredient;
  export let isSelectable = false;
  export let isAddMode = false;
  export let isRecipeTemplate = false;
  export let isModifiable = false;
  export let isModifiable2 = false;
  export let isForDelete = false;

  let isSelected = false;
  let menuItems: Array<MenuItem> = [];

  const deleteIngredient = async (): Promise<void> => {
    const {id, isDefault, mealId, recipeId} = ingredient;

    const {error, data} = await deleteWithJwt(serverlessRoutes.INGREDIENT, {
      ids: [id]
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_DELETING_INGREDIENT"), "error");
      return console.error(error);
    }

    const {ids} = data;

    if (isDefault) {
      ingredientsStore.remove(ids);
    } else if (recipeId && $mealPlanStore) {
      const mealId = mealPlanStore.getMealIdFromRecipeId(recipeId);

      if (mealId) {
        mealPlanStore.removeRecipeIngredients(mealId, recipeId, ids);
      }
    } else if (recipeId && !$mealPlanStore) {
      const mealId = journalMealsStore.getMealIdFromRecipeId(recipeId);

      if (mealId) {
        journalMealsStore.removeRecipeIngredients(mealId, recipeId, ids);
      } else {
        recipesStore.removeIngredients(recipeId, ids);
      }
    } else if (mealId) {
      mealPlanStore.removeMealIngredients(mealId, ids);
      journalMealsStore.removeMealIngredients(mealId, ids);
    }

    alertStore.show(translate("SUCCESSFULLY_DELETED_INGREDIENT"));
  };

  const deleteIngredientDialog = (): void => {
    $dialogData.data = {
      executeFunction: deleteIngredient,
      title: ingredient.name,
    };
    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const copyIngredient = async (): Promise<void> => {
    const {id, isDefault, mealId, recipeId} = ingredient;

    const partialIngredientMap = {
      [id]: {
        isTemplate: 0
      }
    };

    const {error, data} = await postWithJwt(
      `${serverlessRoutes.INGREDIENT}/copy`,
      {partialIngredientMap}
    );

    if (error && !data) {
      alertStore.show(translate("ERROR_COPYING_INGREDIENT"), "error");
      return console.error(error);
    }

    const {ingredients} = data;

    if (isDefault) {
      ingredientsStore.add(ingredients);
    } else if (recipeId && $mealPlanStore) {
      const mealId = mealPlanStore.getMealIdFromRecipeId(recipeId);

      if (mealId) {
        mealPlanStore.addRecipeIngredients(mealId, recipeId, ingredients);
      }
    } else if (recipeId && !$mealPlanStore) {
      const mealId = journalMealsStore.getMealIdFromRecipeId(recipeId);

      if (mealId) {
        journalMealsStore.addRecipeIngredients(mealId, recipeId, ingredients);
      } else {
        recipesStore.addIngredients(recipeId, ingredients);
      }
    } else if (mealId) {
      mealPlanStore.addMealIngredients(mealId, ingredients);
      journalMealsStore.addMealIngredients(mealId, ingredients);
    }

    alertStore.show(translate("SUCCESSFULLY_COPIED_INGREDIENT"));
  };

  const toggleSelect = (): void => {
    const {id} = ingredient;

    if (isSelected) {
      selectedIdsStore.add(id);
    } else {
      selectedIdsStore.remove(id);
    }
  };

  const onMinus = (): void => {
    if (ingredient.amount <= 1) {
      return;
    }

    ingredient.amount -= 1;
    dispatch("ingredientAmount");
  };

  const onPlus = (): void => {
    ingredient.amount += 1;
    dispatch("ingredientAmount");
  };

  const onTrash = (): void => {
    dispatch("ingredientTrash", ingredient.id);
  };

  const onUndo = (): void => {
    dispatch("ingredientTrash", ingredient.id);
  };

  const onOpenIngredientDialog = (): void => {
    if (isAddMode || isSelectable || isModifiable || isModifiable2) {
      if (isSelectable) {
        isSelected = !isSelected;
        return toggleSelect();
      }
      return;
    }

    $dialogData.data = {ingredient};
    $dialogData.type = dialogTypes.INGREDIENT_INFO;
  };

  const createEditIngredientDialog = (): void => {
    $dialogData.data = {ingredient};
    $dialogData.type = dialogTypes.CREATE_EDIT_INGREDIENT;
  };

  const templateDialog = (): void => {
    $dialogData.data = {
      type: "ingredient",
      executeFunction: copyIngredient
    };

    $dialogData.type = dialogTypes.TEMPLATE;
  };

  const saveToDatabase = async (): Promise<void> => {
    const partialIngredientMap: Partial<Ingredient> = {
      [ingredient.id]: {
        recipeId: null,
        mealId: null,
        isTemplate: 0,
        isDefault: 1
      }
    };

    const {error, data} = await postWithJwt(
      `${serverlessRoutes.INGREDIENT}/copy`,
      {partialIngredientMap}
    );

    if (error && !data) {
      return console.error(error);
    }

    alertStore.show(`
      ${translate("INGREDIENT")}
      ${translate("SAVED_TO_DATABASE").toLowerCase()}
    `);
  };

  const saveToJournal = async (): Promise<void> => {
    const trainerMeal = $mealPlanStore?.meals?.find(
      ({id}): boolean => id === ingredient.mealId
    );

    const clientMeal = $activeMealsStore.items.find(
      ({id}): boolean => id === ingredient.mealId
    );

    const meal = trainerMeal || clientMeal;

    if (!meal || meal.entityType !== MealEntityType.NORMAL) {
      return;
    }

    if (!$mealPlansStore.items.length) {
      await mealPlansStore.fetchData();
    }

    const mealPlanId = $mealPlansStore.items[0].id;
    const date = new Date().toISOString();

    const journalMeal = $journalMealsStore.items.find(
      ({name, finishedAt}): boolean => {
        return finishedAt?.slice(0, 10) === date.slice(0, 10) && name === meal.name
      }
    );

    if (!journalMeal) {
      const {error, data} = await postWithJwt(serverlessRoutes.MEAL, {
        partialMeal: {
          name: meal.name,
          description: meal.description,
          foodType: meal.foodType,
          position: meal.position,
          entityType: meal.entityType,
          showMacros: meal.showMacros,
          mealPlanId: mealPlanId,
          commentableId: meal.commentableId,
          createdAt: date,
          updatedAt: date,
          calories: meal.calories,
          protein: meal.protein,
          carbs: meal.carbs,
          fats: meal.fats,
          finishedAt: date,
          fileableId: meal.fileableId,
        }
      });
      const response = await postWithJwt(`${serverlessRoutes.INGREDIENT}/copy`, {
        partialIngredientMap: {
          [ingredient.id]: {
            isDefault: 0,
            isTemplate: 0,
            mealId: data.meal.id,
            recipeId: null,
            trainerId: null
          }
        }
      });
    } else {
      const response = await postWithJwt(`${serverlessRoutes.INGREDIENT}/copy`, {
        partialIngredientMap: {
          [ingredient.id]: {
            isDefault: 0,
            isTemplate: 0,
            mealId: journalMeal.id,
            recipeId: null,
            trainerId: null
          }
        }
      });
    }

    alertStore.show(translate("SUCCESSFULLY_SAVED_TO_JOURNAL"));
  };

  const unfilteredMenuItems: Array<MenuItem> = [{
    icon: "save",
    title: "SAVE_TO_DATABASE",
    executeFunction: saveToDatabase
  },{
    icon: "save",
    title: "SAVE_TO_JOURNAL",
    executeFunction: saveToJournal
  }, {
    icon: "edit",
    title: "EDIT",
    executeFunction: createEditIngredientDialog
  }, {
    icon: "copy",
    title: "CREATE_COPY",
    executeFunction: copyIngredient
  }, {
    icon: "delete",
    title: "DELETE",
    executeFunction: deleteIngredientDialog
  }, {
    icon: "chart-bar",
    title: "DETAILS",
    executeFunction: templateDialog
  }];

  const finalMenuIs = (items: Array<string>): void => {
    menuItems = unfilteredMenuItems.filter(
      ({title}): boolean => items.includes(title)
    );
  };

  const generateMenu = (): void => {
    const {isDefault, isTemplate, mealId, recipeId} = ingredient;

    if (isClient($user)) {
      if (mealId) {
        if (window.location.href.includes("/meal-plan")) {
          finalMenuIs(["SAVE_TO_JOURNAL"]);
        } else if (window.location.href.includes("/journal")) {
          finalMenuIs(["DELETE"]);
        }
      }
    } else {
      if (isRecipeTemplate) {
        finalMenuIs(["SAVE_TO_DATABASE"]);
      } else if (isDefault && !isTemplate) {
        finalMenuIs(["EDIT", "CREATE_COPY", "DELETE"]);
      } else if (isTemplate) {
        finalMenuIs(["CREATE_COPY", "DETAILS"]);
      } else if (recipeId) {
        finalMenuIs(["SAVE_TO_DATABASE", "EDIT", "CREATE_COPY", "DELETE"]);
      } else if (mealId) {
        if (
          window.location.href.includes("/profile") &&
          $mealPlanStore
        ) {
          finalMenuIs([
            "SAVE_TO_DATABASE",
            "SAVE_TO_JOURNAL",
            "EDIT",
            "CREATE_COPY",
            "DELETE"
          ]);
        } else {
          finalMenuIs(["SAVE_TO_DATABASE", "EDIT", "CREATE_COPY", "DELETE"]);
        }
      }
    }
  };

  afterUpdate(generateMenu);
</script>

<style>
  .isSelected {
    border-color: rgb(var(--primary));
  }

  .isForDelete {
    opacity: 0.5;
  }
</style>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  id="ingredient-{ingredient.id}"
  class="
    relative
    p-2
    flex
    flex-col
    gap-2
    bg-white
    border
    border-slate-200
    rounded-md
    cursor-pointer
    transition-[border-color, opacity]
    duration-[333ms]
    linear
    dark:bg-zinc-800
    dark:border-zinc-600
  "
  class:isSelected
  class:isForDelete
  on:click={onOpenIngredientDialog}
>

  {#if ingredient.isTemplate}
    <TemplateTagComponent/>
  {/if}

  <div class="flex items-start gap-4">
    {#if !ingredient.isDefault && !isClient($user)}
      <div use:dragHandle>
        <Svg name="drag" size={16} />
      </div>
    {/if}

    <div class="flex flex-col items-center grow leading-tight text-xs text-center" class:ml-4={ingredient.isTemplate}>
      <div class="">{ingredient.name}</div>
      {#if ingredient.measurementUnit && !isModifiable}
        <div class="shrink text-xxs">({ingredient.amount} {translate(ingredient.measurementUnit)})</div>
      {/if}
    </div>

    {#if isSelectable}
      <CheckboxComponent bind:value="{isSelected}" on:change="{toggleSelect}"/>
    {:else if isAddMode}
      <Svg
        name="plus"
        size={16}
        myClass="bg-green-600"
        on:click={() => dispatch("add", ingredient)}
      />
    {:else if isModifiable}
      <div class="flex items-center gap-2" style="height: 24px">
        <Svg name="minus" size={16} myClass="bg-green-600" on:click={onMinus} />
        <InputMini
          type="number"
          bind:value={ingredient.amount}
          on:input={() => dispatch("ingredientAmount")}
        />
        <Svg name="plus" size={16} myClass="bg-green-600" on:click={onPlus} />
        {#if isForDelete}
          <Svg name="undo" size={16} myClass="bg-red-600" on:click={onUndo} />
        {:else}
          <Svg
            name="delete"
            size={16}
            myClass="bg-red-600"
            on:click={onTrash}
          />
        {/if}
      </div>
    {:else if isModifiable2}
      <div class="flex items-center gap-2" style="height: 24px">
        <Svg name="minus" size={16} myClass="bg-green-600" on:click={onMinus} />
        <ButtonGroup class="p-0 w-20" size="sm">
          <NumberInput
            class="p-0"
            id="ingredientAmount"
            placeholder={translate("AMOUNT")}
            bind:value={ingredient.amount}
            on:input={() => ""}
          />
          <InputAddon class="p-0">
            {translate(ingredient.measurementUnit)?.toLowerCase()}
          </InputAddon>
        </ButtonGroup>
        <Svg name="plus" size={16} myClass="bg-green-600" on:click={onPlus} />
      </div>
    {:else}
      <More {menuItems} />
    {/if}
  </div>

  {#if isModifiable || isModifiable2}
    <Macros
      carbs={(ingredient.originalCarbs / ingredient.originalAmount) *
        ingredient.amount}
      protein={(ingredient.originalProtein / ingredient.originalAmount) *
        ingredient.amount}
      fats={(ingredient.originalFats / ingredient.originalAmount) *
        ingredient.amount}
      calories={(ingredient.originalCalories / ingredient.originalAmount) *
        ingredient.amount}
      isCaloriesVisible
      isBarVisible
    />
  {:else}
    <Macros
      carbs={ingredient.carbs}
      protein={ingredient.protein}
      fats={ingredient.fats}
      calories={ingredient.calories}
      isCaloriesVisible
    />
  {/if}
</div>
