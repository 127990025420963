<script lang="ts">
  import { onMount } from "svelte";
  import {
    Button,
    Input,
    Label,
    Textarea,
    Helper,
    Heading,
    Spinner,
  } from "flowbite-svelte";
  import { api, postFormDataWithJwt, translate } from "lib";
  import {
    dialogData,
    showAlert,
    trainerPrograms,
    trainerWorkouts,
  } from "stores";
  import { updateTrainerPrograms } from "../../../lib/updateTrainerPrograms";
  import { ButtonComponent } from "ui";

  let data: any;
  let disabled = true;
  let isLoading = false;

  const form = {
    name: { value: "", error: "" },
    description: { value: "", error: "" },
  };
  const dialogType = data.workout ? translate("EDIT") : translate("CREATE");

  const onInput = (field: keyof typeof form): void => {
    const { value } = form[field];

    if (field === "name") {
      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onSubmit = async (): Promise<void> => {
    const { workout, programId } = data;
    const { name, description } = form;

    isLoading = true;

    try {
      let response: any;
      const formData = new FormData();

      if (workout) {
        // Izmeni text workout
        const url = `${api}/workout/${workout.id}`;

        formData.append(
          "workout",
          JSON.stringify({
            name: name.value,
            description: description.value,
          })
        );

        response = await postFormDataWithJwt(url, formData, "PUT");

        if (workout.default) {
          // Workout u workouts
          const _workout = $trainerWorkouts.find(
            (_workout): boolean => _workout.id === workout.id
          );

          const index = $trainerWorkouts.indexOf(_workout);

          $trainerWorkouts.splice(index, 1, response);
          $trainerWorkouts = $trainerWorkouts;
        } else {
          // Workout u program
          updateTrainerPrograms(workout.programId, (p) => {
            const index = p.workouts.indexOf(workout);
            p.workouts.splice(index, 1, response);
          });
        }
      } else {
        // Kreiraj text workout
        formData.append("programId", programId);
        formData.append(
          "workout",
          JSON.stringify({
            name: name.value,
            description: description.value,
            entityType: 2,
            programId,
          })
        );

        response = await postFormDataWithJwt(`${api}/workout`, formData);

        updateTrainerPrograms(programId, (p) => {
          p.workouts.push(response);
        });
      }

      $showAlert.color = "black";

      if (workout) {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_EDITED")} ${translate("WORKOUT")}
        `;
      } else {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_CREATED")} ${translate("WORKOUT")}
        `;
      }

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";

      if (workout) {
        $showAlert.message = translate("ERROR_EDITING_WORKOUT");
      } else {
        $showAlert.message = translate("ERROR_CREATING_WORKOUT");
      }
    }
  };

  onMount((): void => {
    if (data.workout) {
      const { name, description } = data.workout;

      if (name) {
        form.name.value = name;
        onInput("name");
      }

      if (description) {
        form.description.value = description;
        onInput("description");
      }
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {dialogType}
    {translate("WORKOUT").toLowerCase()}
  </Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <div>
      <Label for="name">{translate("NAME")}:</Label>
      <Input
        id="name"
        placeholder={translate("NAME")}
        bind:value={form.name.value}
        on:input={() => onInput("name")}
      />
      <Helper color="red">
        {#if form.name.error}
          {form.name.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div>
      <Label for="description">
        {translate("DESCRIPTION")}:
      </Label>
      <Textarea
        id="description"
        placeholder={translate("DESCRIPTION")}
        rows="4"
        bind:value={form.description.value}
        on:input={() => onInput("description")}
      />
      <Helper color="red">
        {#if form.description.error}
          {form.description.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent type="submit" {disabled}>{dialogType}</ButtonComponent>
      {/if}
    </div>
  </form>
</div>
