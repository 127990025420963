<script>
  export let width = "92%";
</script>

<div
  class="divider border-slate-200 dark:border-zinc-600"
  style={`width: ${width}`}
/>

<style>
  .divider {
    border-width: 0.5px;
  }
</style>
