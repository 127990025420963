<script lang="ts">
  import { onDestroy, onMount } from "svelte";

  import {
    Button,
    Dropdown,
    DropdownItem,
    Label,
    Input,
    ButtonGroup,
    InputAddon,
    Textarea,
    Heading,
    NumberInput,
    Select,
    Spinner,
    Helper,
  } from "flowbite-svelte";

  import { push } from "svelte-spa-router";
  import {
    api,
    getWithJwt,
    postWithJwt,
    putWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";
  import { currentClient, dialogData, showAlert, user } from "stores";
  import { clientGoals, clientMeasurements } from "../../stores/clientStores";
  import { isClient } from "../../lib/roles";
  import { ButtonComponent } from "ui";

  export let data: any;

  let selectedMeasurement;
  let startDate;
  let startValue;
  let finishDate;
  let finishValue;
  let description = "";
  let dropdownOpen = false;

  const form = {
    measurementName: {
      value: {
        id: 0,
        isDefault: false,
        measurementUnit: "",
        name: "",
        position: null,
        userId: 0,
      },
      error: "",
    },
    startingValue: { value: 1, error: "" },
    createdAt: { value: "", error: "" },
    goalValue: { value: 1, error: "" },
    expireAt: { value: "", error: "" },
    description: { value: "", error: "" },
  };

  let items: any[] = [];
  let isLoading = false;
  let disabled = false;
  let dialogType = data.startDate ? translate("EDIT") : translate("CREATE");

  if ($clientMeasurements.length === 0) {
    if (isClient($user)) {
      push(`/progress/measurements`);
    }
  }

  const onInput = (field: keyof typeof form): void => {
    if (field === "startingValue") {
      const { value } = form[field];

      if (value < 1) {
        form[field].error = translate("FIELD_MINIMUM_AMOUNT_1");
      } else if (value > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_AMOUNT_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "goalValue") {
      const { value } = form[field];

      if (value < 1) {
        form[field].error = translate("FIELD_MINIMUM_AMOUNT_1");
      } else if (value > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_AMOUNT_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      const { value } = form[field];

      if (value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const fetchMeasurements = async (): Promise<void> => {
    // if ($user && $clientMeasurements.length === 0) {
    try {
      const clientId = $currentClient.id || $user.id;

      const response = await getWithJwt(
        `${serverlessRoutes.MEASUREMENT}?clientId=${clientId}`
      );

      $clientMeasurements = response.data.measurements;

      items = response.data.measurements.map((measurement) => ({
        value: measurement,
        name: measurement.name,
      }));

      form.measurementName.value = items[0].value;
    } catch (error) {
      console.error(error);
    }
    // }
  };

  const editGoal = async (): Promise<void> => {
    try {
      const response = await putWithJwt(`${api}/client/goals/${data.goal.id}`, {
        clientId: data.client.id,
        clientGoal: {
          description: form.description.value,
          currentValue: form.startingValue.value,
          goalValue: form.goalValue.value,
          createdAt: new Date(form.createdAt.value).toISOString(),
          expireAt: new Date(form.expireAt.value).toISOString(),
          measurementName: form.measurementName.value.name,
          startingValue: form.startingValue.value,
        },
        expireAt: new Date(form.expireAt.value).toISOString(),
      });

      const index = $clientGoals.findIndex((goal) => goal.id === data.goal.id);
      // const index = $clientGoals.indexOf(goal);
      $clientGoals.with(index, response);

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")}
        ${translate("GOAL").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      isLoading = false;
      console.error(error);
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_EDITING_GOAL");
    }
  };

  const createGoal = async (): Promise<void> => {
    try {
      const response = await postWithJwt(`${api}/client/goals`, {
        clientId: data.client.id,
        measurementId: form.measurementName.value.id,
        goal: {
          description: form.description.value,
          currentValue: form.startingValue.value,
          goalValue: form.goalValue.value,
          createdAt: new Date(form.createdAt.value).toISOString(),
          expireAt: new Date(form.expireAt.value).toISOString(),
          measurementName: form.measurementName.value.name,
          startingValue: form.startingValue.value,
        },
        createdAt: new Date(form.createdAt.value).toISOString(),
        expireAt: new Date(form.expireAt.value).toISOString(),
      });

      $clientGoals = [...$clientGoals, response];

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("GOAL").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      isLoading = false;
      console.error(error);
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_GOAL");
    }
  };

  const onSubmit = async () => {
    isLoading = true;
    if (data.goal) {
      await editGoal();
    } else {
      await createGoal();
    }
  };

  onMount(async () => {
    await fetchMeasurements();

    if (data.goal) {
      form.measurementName.value = items.find(
        (item) => item.value.name === data.goal.measurement.name
      ).value;
      form.startingValue.value = data.goal.startingValue;
      form.createdAt.value = data.goal.createdAt.slice(0, 10);
      form.goalValue.value = data.goal.goalValue;
      form.expireAt.value = data.goal.expireAt.slice(0, 10);
      form.description.value = data.goal.description;
      // startDate = data.startDate;
      // startValue = data.startingValue;
      // finishDate = data.finishDate;
      // finishValue = data.goalValue;
      // description = data.description;
      // selectedMeasurement = {
      //   name: data.measurement,
      //   measurementUnit: data.measurementUnit,
      // };
    }
  });

  const unsubscribe = clientMeasurements.subscribe((res) => {
    if (res.length > 0) {
      // selectedMeasurement = $clientMeasurements[0];
    }
  });

  onDestroy(unsubscribe);
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {dialogType}
    {translate("GOAL").toLowerCase()}
  </Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <div>
      <Label for="measurement">{translate("MEASUREMENT")}:</Label>
      <!-- placeholder="{translate("MEASUREMENT")}" -->
      <Select
        id="measurement"
        disabled={data.goal}
        {items}
        bind:value={form.measurementName.value}
      />
    </div>

    {#if !data.goal}
      <div class="flex gap-4">
        <div>
          <Label for="startValue">{translate("CURRENTLY")}:</Label>
          <ButtonGroup>
            <NumberInput
              id="startValue"
              placeholder={translate("CURRENTLY")}
              bind:value={form.startingValue.value}
              on:input={() => onInput("startingValue")}
            />
            <InputAddon>{form.measurementName.value.measurementUnit}</InputAddon
            >
          </ButtonGroup>
          <Helper color="red">
            {#if form.startingValue.error}
              {form.startingValue.error}
            {:else}
              ㅤ
            {/if}
          </Helper>
        </div>

        <div>
          <Label for="createdAt">{translate("START_DATE")}:</Label>
          <Input id="createdAt" type="date" bind:value={form.createdAt.value} />
        </div>
      </div>
    {/if}

    <div class="flex gap-4">
      <div>
        <Label for="goalValue">{translate("GOAL")}:</Label>
        <ButtonGroup>
          <NumberInput
            id="goalValue"
            placeholder={translate("GOAL")}
            bind:value={form.goalValue.value}
            on:input={() => onInput("goalValue")}
          />
          <InputAddon>{form.measurementName.value.measurementUnit}</InputAddon>
        </ButtonGroup>
        <Helper color="red">
          {#if form.goalValue.error}
            {form.goalValue.error}
          {:else}
            ㅤ
          {/if}
        </Helper>
      </div>

      <div>
        <Label for="expireAt">{translate("END_DATE")}:</Label>
        <Input id="expireAt" type="date" bind:value={form.expireAt.value} />
      </div>
    </div>

    <div>
      <Label for="description">{translate("DESCRIPTION")}:</Label>
      <Textarea
        id="description"
        placeholder={translate("DESCRIPTION")}
        rows="4"
        bind:value={form.description.value}
        on:input={() => onInput("description")}
      />
      <Helper color="red">
        {#if form.description.error}
          {form.description.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent type="submit" {disabled}>{dialogType}</ButtonComponent>
      {/if}
    </div>
  </form>

  <!-- <Button
    on:click={() =>
      data.executeFunction
        ? data.executeFunction({
            clientGoalChanges: {
              description,
              currentValue: startValue,
              goalValue: finishValue,
              createdAt: startDate,
              expireAt: finishDate,
              measurementName: selectedMeasurement.name,
              startingValue: startValue,
            },
            clientId: $user.id,
            expireAt: finishDate,
          })
        : submit()}
    color="primary"
    size="sm">{translate("SUBMIT")}</Button
  > -->
</div>
