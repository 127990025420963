import { get } from "svelte/store";
import {
  notificationCount,
  notificationQuery,
  notifications,
  unreadNotifications,
} from "../stores/notificationStore";
import { serverlessRoutes } from "./constants";
import { getWithJwt, postWithJwt } from "./requests";
import { isLoadingMore } from "../stores/isLoadingMoreStore";

export const fetchNotifications = async () => {
  isLoadingMore.set(true);
  try {
    const notificationsRef = get(notifications);
    const skip = notificationsRef.length;
    if (skip !== 0 && skip === get(notificationCount)) return;
    const url = `${
      serverlessRoutes.NOTIFICATIONS
    }/list?take=15&skip=${skip}${get(notificationQuery)}`;
    const data = await getWithJwt(url);
    const tmpNotifications: Array<any> = [];
    data.data.data.forEach((newNotification) => {
      if (
        !notificationsRef.find(
          (notification) => notification.id === newNotification.id
        )
      ) {
        tmpNotifications.push(newNotification);
      }
    });
    notifications.set([...notificationsRef, ...tmpNotifications]);


    let fetchCommentsStatus: {[key: string]: Array<number>} = {
      exerciseIds: [],
      workoutIds: [],
      mealIds: []
    };

    get(notifications).forEach(({text, relativeLink}: any): void => {
      if (text.includes("COMMENTED_WORKOUT")) { //clients/33121/workout-plans/123504#workout-693022#journal
        const idSplit = relativeLink.split("#")[1].split("-");
        const workoutId = parseInt(idSplit[1]);
        fetchCommentsStatus.workoutIds.push(workoutId);
      } else if (text.includes("COMMENTED_EXERCISE")) { //clients/33121/workout-plans/123504#exercise-693023-5245759#active
        const idSplit = relativeLink.split("#")[1].split("-");
        const exerciseId = parseInt(idSplit[2]);
        fetchCommentsStatus.exerciseIds.push(exerciseId);
      } else if (text.includes("COMMENTED_MEAL")) { //clients/33121/meal-plans/113546#meal-806357#journal
        const idSplit = relativeLink.split("#")[1].split("-");
        const mealId = parseInt(idSplit[1]);
        fetchCommentsStatus.mealIds.push(mealId);
      }
    });

    const response = await postWithJwt(
      `${serverlessRoutes.NOTIFICATIONS}/fetch-comments-status`,
      {...fetchCommentsStatus}
    );
    const {status} = response.data;

    notifications.update((store) => {
      store.forEach((ntf: any): void => {
        const {text, relativeLink} = ntf;

        if (text.includes("COMMENTED_WORKOUT")) { //clients/33121/workout-plans/123504#workout-693022#journal
          if (!relativeLink.includes("#active") && !relativeLink.includes("#journal")) {
            const idSplit = relativeLink.split("#")[1].split("-");
            const workoutId = parseInt(idSplit[1]);
            ntf.relativeLink = `${ntf.relativeLink}#${status.workoutIds[workoutId] ? "journal" : "active"}`
          }
        } else if (text.includes("COMMENTED_EXERCISE")) { //clients/33121/workout-plans/123504#exercise-693023-5245759#active
          if (!relativeLink.includes("#active") && !relativeLink.includes("#journal")) {
            const idSplit = relativeLink.split("#")[1].split("-");
            const exerciseId = parseInt(idSplit[2]);
            ntf.relativeLink = `${ntf.relativeLink}#${status.exerciseIds[exerciseId] ? "journal" : "active"}`
          }
        } else if (text.includes("COMMENTED_MEAL")) { //clients/33121/meal-plans/113546#meal-806357#journal
          if (!relativeLink.includes("#active") && !relativeLink.includes("#journal")) {
            const idSplit = relativeLink.split("#")[1].split("-");
            const mealId = parseInt(idSplit[1]);
            ntf.relativeLink = `${ntf.relativeLink}#${status.mealIds[mealId] ? "journal" : "active"}`
          }
        }
      });
      return store;
    });


    notificationCount.set(data.data.count);
    unreadNotifications.set(data.notSeenCount);
    localStorage.setItem(
      "notificationCache",
      JSON.stringify({
        notificationCount: data.data.count,
        notifications: [...notificationsRef, ...tmpNotifications],
        unreadNotifications: data.notSeenCount,
      })
    );
    isLoadingMore.set(false);
  } catch (err) {
    isLoadingMore.set(false);
  }
};
