<script lang="ts">
  import {onMount} from "svelte";

  import {
    api,
    postFormDataWithJwt,
    postWithJwt,
    putWithJwt,
    translate,
  } from "lib";

  import {
    clientPayments,
    countersStore,
    currentClient,
    dialogData,
    showAlert,
    trainerClients,
  } from "stores";

  import Input from "../../UI/Input.svelte";
  import MiniSpinner from "../../UI/MiniSpinner.svelte";
  import Select from "../../UI/Select.svelte";
  import Textarea from "../../UI/Textarea.svelte";
  import { ButtonComponent, InputField } from "ui";

  let data: any;
  const type = data.payment ? translate("EDIT") : translate("CREATE");

  const form = {
    amount: { value: 0, error: "" },
    currency: { value: "EUR", error: "" },
    date: { value: "", error: "" },
    description: { value: "", error: "" },
    expireAt: { value: "", error: "" },
  };

  let disabled = false;
  let isLoading = false;

  const currencies = [
    { name: "EUR", selected: "EUR" },
    { name: "RSD", selected: "RSD" },
    { name: "USD", selected: "USD" },
  ];

  const onInput = (field: keyof typeof form): void => {};

  const editPayment = async (): Promise<void> => {
    try {
      const response = await putWithJwt(`${api}/payment/${data.payment.id}`, {
        payment: {
          amount: form.amount.value,
          currency: form.currency.value,
          date: new Date(form.date.value).toISOString(),
          expireAt: new Date(form.expireAt.value).toISOString(),
          description: form.description.value,
        },
        paymentId: data.payment.id,
      });

      const _payment = $clientPayments.find(
        (_payment): boolean => _payment.id === data.payment.id
      );
      const index = $clientPayments.indexOf(_payment);

      $clientPayments = $clientPayments.with(index, response);
      $clientPayments.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return bDate - aDate;
      });

      $showAlert.color = "black";
      $showAlert.message = `Izmenjena uplata.`;

      $dialogData.data = {};
      $dialogData.type = "";
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = `There was an error.`;
    }
  };

  const createPayment = async (): Promise<void> => {
    try {
      const response = await postWithJwt(`${api}/payment`, {
        payment: {
          amount: form.amount.value,
          currency: form.currency.value,
          // date: form.date.value,
          date: new Date(form.date.value).toISOString(),
          description: form.description.value,
        },
        clientId: data.client.id,
      });

      // expiry se samo menja ako je promenjen expireAt value
      if (form.expireAt.value !== data.client.expireAt?.slice(0, 10)) {
        const formData = new FormData();

        formData.append(
          "user",
          JSON.stringify({
            expireAt: new Date(form.expireAt.value).toISOString(),
          })
        );

        const response = await postFormDataWithJwt(
          `${api}/trainer/client/${data.client.id}`,
          formData,
          "PUT"
        );

        const client = $trainerClients.find(
          (client): boolean => client.id === data.client.id
        );

        if (client) {
          client.expireAt = response.expireAt;
        }
        if ($currentClient) {
          $currentClient.expireAt = response.expireAt;
        }

        // U ClientListItem, client je passovan kao prop pa se ne menja kad
        // se promeni store, moramo da ga promenimo rucno
        if (data.executeFunction) {
          data.executeFunction(response);
        }
      }

      $clientPayments = [response, ...$clientPayments];
      $clientPayments.sort((a, b) => {
        const aTime = new Date(a.date).getTime();
        const bTime = new Date(b.date).getTime();
        return bTime - aTime;
      });
      $clientPayments = $clientPayments;
      $countersStore.clientPayments += 1;

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("PAYMENT")}
      `;

      $dialogData.data = {};
      $dialogData.type = "";
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_PAYMENT");
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.payment) {
      await editPayment();
    } else {
      await createPayment();
    }
  };

  onMount((): void => {
    const defaultDate = new Date().toISOString().slice(0, 10);

    if (data.client) {
      form.expireAt.value = data.client.expireAt?.slice(0, 10) || defaultDate;
    }

    if (data.payment) {
      form.amount.value = data.payment.amount;
      form.currency.value = data.payment.currency;
      form.date.value = data.payment.date.slice(0, 10);
      form.description.value = data.payment.description;
    } else {
      form.date.value = defaultDate;
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <h3 class="text-center text-lg font-semibold">
    {type} {translate("PAYMENT_U").toLowerCase()}
  </h3>

  <div class="flex flex-col gap-4">

    <div class="flex gap-4">

      <div>
        <p class="mb-1">{translate("CURRENCY_AMOUNT")}:</p>
        <Input
          name="amount"
          placeholder={translate("CURRENCY_AMOUNT")}
          bind:value={form.amount.value}
          onInput={() => onInput("amount")}
        />
        {#if form.amount.error}
          <span class="text-red-500 text-xs">{form.amount.error}</span>
        {/if}
      </div>

      <div class="basis-2/5">
        <p class="mb-1">{translate("CURRENCY")}:</p>
        <Select
          bind:value={form.currency.value}
          selectItems={currencies}
          onOptionChange={() => onInput("currency")}
        />
        {#if form.currency.error}
          <span class="text-red-500 text-xs">{form.currency.error}</span>
        {/if}
      </div>

    </div>

    <div>
      <InputField
        label={translate("PAYMENT_DATE")}
        type="date"
        error={form.date.error}
        bind:value={form.date.value}
        on:input={() => onInput("date")}
      />
      <!-- <DatePicker label="PAYMENT_DATE" bind:date={form.date.value} /> -->
    </div>

    <div>
      <p class="mb-1">{translate("DESCRIPTION")}:</p>
      <Textarea
        placeholder={translate("DESCRIPTION")}
        bind:value={form.description.value}
        on:input={() => onInput("description")}
      />
      {#if form.currency.error}
        <span class="text-red-500 text-xs">{form.currency.error}</span>
      {/if}
    </div>

    <!-- <div> -->
      <InputField
        label={translate("ACCOUNT_EXPIRATION_DATE")}
        type="date"
        error={form.expireAt.error}
        bind:value={form.expireAt.value}
        on:input={() => onInput("expireAt")}
      />
      <!-- <DatePicker
        label="ACCOUNT_EXPIRATION_DATE"
        bind:date={form.expireAt.value}
      /> -->
    <!-- </div> -->

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <MiniSpinner />
      {:else}
        <ButtonComponent {disabled} on:click={onSubmit}>{type}</ButtonComponent>
      {/if}
    </div>

  </div>

</div>
