<script lang="ts">
    import { createEventDispatcher } from "svelte";
  import type MenuItem from "../../interfaces/MenuItem";
  import { api, dialogTypes } from "../../lib/constants";
  import { parseDate } from "../../lib/parseDate";
  import { deleteWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import { clientGalleryEntries } from "../../stores/clientStores";
  import { dialogData } from "../../stores/dialogDataStore";
  import { showAlert } from "../../stores/showAlertStore";
  import Divider from "../UI/Divider.svelte";
  import More from "../UI/More.svelte";

  export let entry;
  export let isSelectMode = false;

  const dispatch = createEventDispatcher();

  const imageUrl = (url: string) => {
    return url ? url : "/logo.png";
  };

  const openDialog = (src: string): void => {
    if (isSelectMode) {
      dispatch("select", {src, date: entry.date});
    } else {
      $dialogData.type = dialogTypes.IMAGE_ZOOM;
      $dialogData.data = { src };
    }
  };

  const deleteEntry = async () => {
    try {
      const url = `${api}/gallery/all/${entry.id}`;
      const res = await deleteWithJwt(url);
      if (res.message === "Successfully deleted") {
        $clientGalleryEntries = $clientGalleryEntries.filter(
          (e) => e.id !== entry.id
        );
        $showAlert.message = `${translate("SUCCESSFULLY_DELETED")} ${translate(
          "GALLERY_ENTRY"
        ).toLowerCase()}`;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const menuItems: MenuItem[] = [
    {
      title: "EDIT",
      executeFunction: () => {
        $dialogData.type = dialogTypes.CREATE_EDIT_GALLERY_ENTRY;
        $dialogData.data = {
          date: entry.date.slice(0, 10),
          // executeFunction: deleteEntry,
          // title: translate("GALLERY_ENTRY"),
        };
      },
      icon: "edit",
    },
    {
      title: "DELETE",
      executeFunction: () => {
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
        $dialogData.data = {
          executeFunction: deleteEntry,
          title: translate("GALLERY_ENTRY"),
        };
      },
      icon: "delete",
    },
  ];

  const getImage = (entry, pose) => {
    return entry.gallery.files.find((i) => i.name === `${pose}.png`)?.url;
  };
</script>

<div>
  <div class="flex flex-row justify-between">
    <span class="mgl-24">{parseDate(entry.date)}</span>
    {#if !isSelectMode}
      <More myClasses="mr-4" {menuItems} />
    {/if}
  </div>
  <div class="flex-row center-space-around mg-8">
    <div
      class="gallery-entry-image"
      on:click={() => {
        openDialog(imageUrl(getImage(entry, "front")));
      }}
      on:keypress={() => {
        openDialog(imageUrl(getImage(entry, "front")));
      }}
    >
      <img
        src={imageUrl(getImage(entry, "front"))}
        on:error={(el) => {
          el.target.src = "/logo.png";
        }}
        alt="Front Pose"
        class="gallery-entry-image pl-1 pr-1"
      />
    </div>
    <div
      class="gallery-entry-image"
      on:click={() => {
        openDialog(imageUrl(getImage(entry, "back")));
      }}
      on:keypress={() => {
        openDialog(imageUrl(getImage(entry, "back")));
      }}
    >
      <img
        src={imageUrl(getImage(entry, "back"))}
        on:error={(el) => {
          el.target.src = "/logo.png";
        }}
        alt="Back Pose"
        class="gallery-entry-image pl-1 pr-1"
      />
    </div>
    <div
      class="gallery-entry-image"
      on:click={() => {
        openDialog(imageUrl(getImage(entry, "side")));
      }}
      on:keypress={() => {
        openDialog(imageUrl(getImage(entry, "side")));
      }}
    >
      <img
        src={imageUrl(getImage(entry, "side"))}
        on:error={(el) => {
          el.target.src = "/logo.png";
        }}
        alt="Side Pose"
        class="gallery-entry-image pl-1 pr-1"
      />
    </div>
  </div>
  <div class="flex-col center-center mt-2 mgb-16">
    <Divider />
  </div>
</div>

<style>
  .gallery-entry-image {
    width: 100%;
  }
</style>
