<script lang="ts">
  import { translate } from "lib";

  export let name = "";
  export let label = "";
  export let value = "";
  export let placeholder = "";
  export let type = "text";
  export let onInput = (str: string) => {};
</script>

<div>
  {#if label && label !== ""}
    <label
      for={name}
      class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
      >{translate(label)}:</label
    >
  {/if}
  <div class={`relative rounded-md shadow-sm ${label !== "" ? "mt-2" : ""}`}>
    {#if type === "password"}
      <input
        bind:value
        on:input={() => onInput(label.toLowerCase())}
        type="password"
        {name}
        id={name}
        autocomplete="on"
        class="block w-full dark:bg-zinc-700 rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500 sm:text-sm sm:leading-6"
        {placeholder}
      />
    {:else if type === "email"}
      <input
        bind:value
        on:input={() => onInput(label.toLowerCase())}
        type="email"
        {name}
        id={name}
        autocomplete="email"
        class="block w-full dark:bg-zinc-700 rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500 sm:text-sm sm:leading-6"
        {placeholder}
      />
    {:else}
      <input
        bind:value
        on:input={() => onInput(label.toLowerCase())}
        type="text"
        {name}
        id={name}
        autocomplete="off"
        class="block w-full dark:bg-zinc-700 rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500 sm:text-sm sm:leading-6"
        {placeholder}
      />
    {/if}
  </div>
</div>
