import {get, writable} from "svelte/store";
import {getWithJwt, IngredientTemplate, serverlessRoutes, sortItems} from "lib";
import {storeUtil} from "../lib/createItemsStore";
import type {Ingredient, ItemsStore, Recipe} from "interfaces";

interface RecipesStore extends ItemsStore<Recipe> {
  filter: {
    search: string;
    template: IngredientTemplate | undefined;
    macroRatio: {
      carbs: number;
      protein: number;
      fats: number;
    } | undefined;
    tags: Array<string>;
  };
}

const recipesStoreCreate = () => {
  const {set, subscribe, update} = writable<RecipesStore>({
    items: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    skip: 0,
    searchTimeout: undefined,
    filter: {
      search: "",
      template: IngredientTemplate.ALL,
      macroRatio: undefined,
      tags: []
    }
  });

  const createUrl = (): string => {
    const {
      skip,
      filter: {search, template, macroRatio, tags}
    } = get({subscribe});

    const params = new URLSearchParams();

    params.append("take", "8");
    params.append("skip", `${skip}`);

    if (template === IngredientTemplate.ALL) {
      params.append("default", "1");
      params.append("template", "1");
    } else {
      params.append("default", "1");
      params.append("template", "0");
    }

    if (macroRatio) {
      params.append("macroRatio", Object.values(macroRatio).join());
    }

    if (tags.length) {
      params.append("foodType", tags.map((tag): string => tag).join());
    }

    if (search) {
      params.append("name", search);
    }

    return `${serverlessRoutes.RECIPE}/list/v2?${params.toString()}`;
  }

  const {
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache
  } = storeUtil<Recipe, RecipesStore>("recipesCache", update, createUrl);

  const calculateRecipeMacros = (recipe: Recipe): void => {
    if (!recipe.ingredients) {
      return;
    }

    const ingredientMacros = recipe.ingredients.reduce((macro, ingredient) => {
      const {protein, carbs, fats, calories} = ingredient;

      macro.protein += protein || 0;
      macro.carbs += carbs || 0;
      macro.fats += fats || 0;
      macro.calories += calories || 0;

      return macro;
    }, {
      protein: 0,
      carbs: 0,
      fats: 0,
      calories: 0
    });

    recipe.protein = ingredientMacros.protein;
    recipe.carbs = ingredientMacros.carbs;
    recipe.fats = ingredientMacros.fats;
    recipe.calories = ingredientMacros.calories;
  };



  const setIngredients = (
    recipeId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      const recipe = store.items.find(({id}): boolean => id === recipeId);

      if (recipe) {
        recipe.ingredients = newIngredients;
      }

      return store;
    });
  };

  const addIngredients = (
    recipeId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      const recipe = store.items.find(({id}): boolean => id === recipeId);

      if (!recipe) {
        return store;
      }

      if (recipe.ingredients) {
        recipe.ingredients.push(...newIngredients);
      } else {
        recipe.ingredients = newIngredients;
      }

      recipe.ingredients.sort(sortItems);

      calculateRecipeMacros(recipe);

      return store;
    });
  };

  const replaceIngredients = (
    recipeId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      const recipe = store.items.find(({id}): boolean => id === recipeId);

      if (!recipe?.ingredients) {
        return store;
      }

      const {ingredients} = recipe;

      for (const newIngredient of newIngredients) {
        const ingredientIndex = ingredients.findIndex(
          ({id}): boolean => id === newIngredient.id
        );

        if (ingredientIndex > -1) {
          ingredients.splice(ingredientIndex, 1, newIngredient);
        }
      }

      recipe.ingredients.sort(sortItems);

      calculateRecipeMacros(recipe);

      return store;
    });
  };

  const removeIngredients = (
    recipeId: number,
    ingredientIds: Array<number>
  ): void => {
    update((store) => {
      const recipe = store.items.find(({id}): boolean => id === recipeId);

      if (!recipe?.ingredients) {
        return store;
      }

      recipe.ingredients = recipe.ingredients.filter(
        ({id}): boolean => !ingredientIds.includes(id)
      );

      recipe.ingredients.sort(sortItems);

      calculateRecipeMacros(recipe);

      return store;
    });
  };



  const fetchIngredients = async (recipeId: number): Promise<void> => {
    const {data, error} = await getWithJwt(
      `${serverlessRoutes.RECIPE}/${recipeId}/ingredients`
    );

    if (error && !data) {
      return console.error(error);
    }

    addIngredients(recipeId, data.ingredients);
  };

  return {
    set,
    subscribe,
    update,
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache,

    setIngredients,
    addIngredients,
    replaceIngredients,
    removeIngredients,

    fetchIngredients,
  };
};

const recipesStore = recipesStoreCreate();

export {recipesStore};
