<script lang="ts">
  import { Chart } from "chart.js/auto";
  import { onMount } from "svelte";

  const WEEKLY_LABELS = ["MONDAY", "TUESDAY"];
  const colors = [
    "#FF0000",
    "#FF0000",
    "#FF3300",
    "#FF6600",
    "#FF9900",
    "#FFCC00",
    "#CCFF00",
    "#99FF00",
    "#66FF00",
    "#33FF00",
    "#00FF00",
  ];

  let chart;
  export let complete;
  export let width;
  export let animate: boolean = true;

  const initChart = () => {
    const htmlEl = document.getElementById(`chart`);
    // if (chart) {
    //   chart.destroy();
    // }
    const color = colors[Math.floor(complete / 10)];
    chart = new Chart(htmlEl, {
      type: "doughnut",
      data: {
        labels: WEEKLY_LABELS,
        datasets: [
          {
            label: "Trežina",
            data: [complete, 100 - complete],
            backgroundColor: [color, "#eee"],
            borderColor: [color, "#eee"],
            borderDash: [9, 9],
            borderWidth: 0,
            tension: 0.4,
            circumference: 180,
            rotation: 270,
            cutout: "86%",
          },
        ],
      },
      options: {
        aspectRatio: 1.78,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });
  };

  const updateChartData = () => {
    if (!chart) {
      return;
    }
    chart.data.datasets.forEach((dataset) => {
      const color = colors[Math.floor(complete / 10)];
      dataset.backgroundColor = [color, "#eee"];
      dataset.borderColor = [color, "#eee"];
      dataset.data.pop();
      dataset.data.pop();
      dataset.data.push(complete);
      dataset.data.push(100 - complete);
    });
    if (animate) {
      chart.update();
    } else {
      chart.update("none");
    }
  };

  $: complete && updateChartData();

  onMount(() => {
    initChart();
  });
</script>

<div>
  <canvas style={`width: ${width}px;`} id="chart"/>
</div>
<!-- <div class="mt-2" /> -->
