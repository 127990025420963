import { writable } from "svelte/store";

interface DialogData {
  type: string;
  data: object;
  isCloseable: boolean;
  elem: HTMLDivElement | undefined;

  component: any;
}

export const dialogData = writable<DialogData>({
  type: "",
  data: {},
  isCloseable: true,
  elem: undefined,
  // za refactor
  component: undefined,
});
