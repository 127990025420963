<script lang="ts">
  import { api, patchWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import Input from "../UI/Input.svelte";
  import {ButtonComponent} from "ui";
  import { dialogData, showAlert, user } from "stores";
  import { onMount } from "svelte";

  export let data: any;

  let name = "";
  let measurementUnit = "";
  let id = 0;

  let disabled = true;
  let isLoading = false;

  const form = {
    name: {value: "", error: ""},
    measurementUnit: {value: "", error: ""},
  };

  const editDefaultMeasurement = async (): Promise<void> => {
    try {
      const response = await patchWithJwt(`${serverlessRoutes.MEASUREMENT}/${id}`, {
        measurement: {
          name: form.name.value,
          measurementUnit: form.measurementUnit.value
        }
      });

      // saljemo res u DefaultMeasurements posto defaultMeasurements nije store
      data.executeFunction(response);

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")}
        ${translate("MEASUREMENT").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
    }
  };

  const createDefaultMeasurement = async (): Promise<void> => {
    try {
      const response = await postWithJwt(
        `${serverlessRoutes.MEASUREMENT}`,
        {
          clientId: $user.id,
          measurement: {
            name: form.name.value,
            measurementUnit: form.measurementUnit.value,
            isDefault: true
          }
        }
      );
      // const response = await postWithJwt(`${api}/measurement/default`, {
      //   measurement: {
      //     name: form.name.value,
      //     measurementUnit: form.measurementUnit.value
      //   }
      // });

      // saljemo res u DefaultHabits posto defaultHabits nije store
      data.executeFunction(response.data);

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("MEASUREMENT").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.isDefaultMeasurement) {
      if (data.defaultMeasurement) {
        await editDefaultMeasurement();
      } else {
        await createDefaultMeasurement();
      }
    } else {
      data.executeFunction({
        id,
        name: form.name.value,
        measurementUnit: form.measurementUnit.value
      });
      $dialogData = { data: {}, type: "" };
    }
    // if (data.measurementToUpdate) {
    //   data.executeFunction({
    //     id,
    //     name: form.name.value,
    //     measurementUnit: form.measurementUnit.value
    //   });
    //   $dialogData = { data: {}, type: "" };
    // } else if (data.isDefaultMeasurement) {
    //   if (data.defaultMeasurement) {
    //     await editDefaultMeasurement();
    //   } else {
    //     await createDefaultMeasurement();
    //   }
    // }

    isLoading = false;
  };

  const onInput = (key: keyof typeof form): void => {
    // if (key === "name") {
      if (form.name.value === "") {
        form.name.error = "FIELD_REQUIRED";
      } else if (form.name.value.length < 2) {
        form.name.error = "FIELD_MINIMUM_2";
      } else if (form.name.value.length > 40) {
        form.name.error = "FIELD_MAXIMUM_40";
      } else {
        form.name.error = "";
      }
    // } else if (key === "steps") {
      if (form.measurementUnit.value === "") {
        form.measurementUnit.error = "FIELD_REQUIRED";
      } else if (form.measurementUnit.value.length > 40) {
        form.measurementUnit.error = "FIELD_MAXIMUM_40";
      } else {
        form.measurementUnit.error = "";
      }
    // }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  onMount(() => {
    // if (data.measurementToUpdate) {
    //   name = data.measurementToUpdate.title;
    //   id = data.measurementToUpdate.id;
    //   measurementUnit = data.measurementToUpdate.measurementUnit;
    // }
    if (data.measurementToUpdate) {
      form.name.value = data.measurementToUpdate.title;
      form.measurementUnit.value = data.measurementToUpdate.measurementUnit;

      id = data.measurementToUpdate.id;
    } else if (data.isDefaultMeasurement && data.defaultMeasurement) {
      form.name.value = data.defaultMeasurement.name;
      form.measurementUnit.value = data.defaultMeasurement.measurementUnit;

      id = data.defaultMeasurement.id;
    }
  });
</script>

<div class="p-4">
  <h3 class="text-xxl font-medium text-center mt-4">
    {#if data.measurementToUpdate}
      {translate("EDIT_MEASUREMENT")}
    {:else}
      {translate("CREATE_NEW_MEASUREMENT")}
    {/if}
  </h3>
  <Input
    label="TITLE"
    placeholder={translate("WAIST_CIRCUMFERENCE")}
    bind:value={form.name.value}
    onInput={() => onInput("name")}
  />
  {#if form.name.error}
    <div class="text-red-500 text-xs">{translate(form.name.error)}</div>
  {/if}
  <div class="h-3"></div>
  <Input
    label="MEASUREMENT_UNIT"
    placeholder="cm"
    bind:value={form.measurementUnit.value}
    onInput={() => onInput("measurementUnit")}
  />
  {#if form.measurementUnit.error}
    <div class="text-red-500 text-xs">{translate(form.measurementUnit.error)}</div>
  {/if}

  <div class="flex flex-col items-center mt-6 mb-2">
    <ButtonComponent on:click={onSubmit} {isLoading} {disabled}>
      {translate("SUBMIT")}
    </ButtonComponent>
  </div>
</div>
