<script lang="ts">
  import { Button, Heading, Input, Label } from "flowbite-svelte";
  import { onMount } from "svelte";
  import { translate } from "../../../lib/translate";
  import DatePicker from "../../UI/DatePicker.svelte";
  import { ButtonComponent, InputField } from "ui";

  export let data: any;

  let name: string;
  let description: string;
  let date: string;

  onMount(() => {
    if (data.name) {
      name = data.name;
    }
    if (data.description) {
      description = data.description;
    }
  });
</script>

<div class="flex-col">
  <div class="text-center mt-3 mb-3">
    <Heading tag="h3" customSize="text-lg font-normal"
      >{data.name ? translate("EDIT") : translate("CREATE")}
      {translate("WORKOUT").toLowerCase()}</Heading
    >
  </div>
  <div class="p-4">
    <Label for="name" class="mb-2">{translate("TITLE")}:</Label>
    <Input
      type="text"
      id="name"
      placeholder={translate("LEGS_WORKOUT")}
      bind:value={name}
      required
    />
  </div>
  <div class="pl-4 pr-4">
    <Label for="description" class="mb-2">{translate("DESCRIPTION")}:</Label>
    <Input type="text" id="description" bind:value={description} />
  </div>
  <div class="pt-4 pl-4 pr-4">
    <InputField
      label={translate("DATE")}
      type="date"
      error={""}
      bind:value={date}
    />
    <!-- <DatePicker bind:date title="DATE" /> -->
  </div>
  <div class="flex justify-center mb-6">
    <ButtonComponent
      on:click={() =>
        data.executeFunction({ name, description, finishedAt: date })}
    >{data.name ? translate("EDIT") : translate("CREATE")}</ButtonComponent>
  </div>
</div>

<style>
</style>
