<script lang="ts">
  import MiniSpinner from "./MiniSpinner.svelte";

  export let myClasses: string = "";
  export let customColor: string = "";
  export let disabled: boolean = false;
  export let outline: boolean = false;
  export let pill: boolean = false;
  export let isActive = false;
  export let isLoading = false;
  export let execFunction: () => void;

  $: classes =
    disabled || outline || pill
      ? ""
      : `button text-white focus:ring-4 focus:outline-none ${customColor === "" ? "bg-primary-500 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-500 dark:hover:bg-primary-700 dark:focus:ring-primary-800" : customColor}  font-medium rounded-lg px-4 py-2.5`;
  $: outlineClasses =
    outline === true
      ? "button dark:bg-zinc-700 text-primary-500 border border-primary-500 dark:border-zinc-100 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 dark:text-slate-100 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
      : "";
  $: disabledClasses =
    disabled === true
      ? "button text-slate-200 bg-slate-500 font-medium rounded-lg px-5 py-2.5"
      : "";
  $: pillClasses =
    pill === true
      ? `${isActive ? "bg-primary-700 dark:bg-primary-700" : "bg-primary-500 hover:bg-primary-700 dark:bg-primary-500 dark:hover:bg-primary-700"} focus:ring-primary-300 dark:focus:ring-primary-800 focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-2.5 py-2.5 text-center me-2 mb-2`
      : "";
</script>

{#if isLoading}
  <div class={myClasses}>
    <MiniSpinner />
  </div>
{:else}
  <button
    on:click|stopPropagation|preventDefault={() => execFunction()}
    type="button"
    {disabled}
    class={`${myClasses} ${classes} ${disabledClasses} ${outlineClasses} ${pillClasses}`}
    ><slot /></button
  >
{/if}

<style>
  .button {
    font-size: 0.825rem; /* 12px */
    line-height: 1rem; /* 16px */
  }
  /* .button {
    height: 36px;
    white-space: nowrap;
    overflow: hidden;
    width: fit-content;
    padding-left: 24px;
    padding-right: 24px;
    font-size: small;
    background-color: #319795;
    border-radius: 0.375rem;
  }
  .outline-button {
    height: 36px;
    width: fit-content;
    padding-left: 12px;
    padding-right: 12px;
    font-size: small;
    background-color: #fff;
    border: 2px solid #319795;
    border-radius: 0.375rem;
  } */
</style>
