import { language } from "../stores/languageStore";
import { get } from "svelte/store";

const options: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const parseDate = (dateString: string) => {
  const date = new Date(dateString);
  const lang = get(language) === "en" ? "en" : "sr-Latn-RS";
  return date.toLocaleDateString(lang, options);
};

export { parseDate };
