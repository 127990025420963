import "./app.postcss";
import "./style.css";
import App from "./App.svelte";

import * as Sentry from "@sentry/svelte";

// Initialize the Sentry SDK here
Sentry.init({
  dsn: "https://5ea88e24a69fc79b042ba572c89286e9@o1134753.ingest.sentry.io/4507724422971392",
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // integrations: [new Sentry.Replay()],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
});

const app = new App({
  target: document.getElementById("app"),
});

export default app;
