<script lang="ts">
  import { Input, Label } from "flowbite-svelte";
  import { api, postFormDataWithJwt, translate } from "lib";
  import { dialogData, showAlert } from "stores";
  import {ButtonComponent, CheckboxComponent} from "ui";

  export let data: any;

  let phoneNumber: string;
  let isChecked = false;

  const close = () => {
    if (isChecked) {
      localStorage.setItem("showPhonenumberDialog", "false");
    }
    $dialogData.type = "";
  };

  const submit = async () => {
    localStorage.setItem("showPhonenumberDialog", "false");
    const url = `${api}/user`;
    const formData = new FormData();
    formData.append("user", JSON.stringify({ phoneNumber }));
    try {
      await postFormDataWithJwt(url, formData, "PUT");
      $showAlert.message = "Uspešno ažuriran broj telefona.";
      $dialogData.type = "";
    } catch (err: any) {
      $showAlert.message = err.data.message;
      $dialogData.type = "";
    }
  };
</script>

<div class="flex-col gap-5" style="align-items: center">
  <div class="mt-5 mr-5 ml-5">
    Nije loše da uneseš broj telefona za slučaj da zabaguje app. Evo ti moj broj
    da imaš <strong>0628352151</strong>.
  </div>
  <div class="w-5/6">
    <Label for="email" class="mb-2 text-red text-xs">Broj telefona:</Label>
    <Input
      type="text"
      size="sm"
      id="phoneNumber"
      placeholder="+381612345678"
      bind:value={phoneNumber}
      required
    />
  </div>
  <div
    class="ml-3 w-5/6"
    on:click={() => (isChecked = !isChecked)}
    on:keypress={() => (isChecked = !isChecked)}
  >
    <CheckboxComponent bind:value={isChecked} label="Ne prikazuj više" />
  </div>
  <div class="flex flex-row w-5/6 justify-between mb-5">
    <ButtonComponent isOutline on:click={close}>{translate("CLOSE")}</ButtonComponent>
    <ButtonComponent on:click={submit}>{translate("SUBMIT")}</ButtonComponent>
  </div>
</div>

<style>
</style>
