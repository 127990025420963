<script lang="ts">
  export let color: string;
  export let height = 24;
</script>

<svg {height} {color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"
  ><path
    fill="currentColor"
    d="M184 32H72a16 16 0 0 0-16 16v176a8 8 0 0 0 12.24 6.78L128 193.43l59.77 37.35A8 8 0 0 0 200 224V48a16 16 0 0 0-16-16"
  /></svg
>
