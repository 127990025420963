<script lang="ts">
  import { onMount } from "svelte";
  import { api, postWithJwt, putWithJwt, translate } from "lib";
  import { dialogData, showAlert, user } from "stores";
  import { ButtonComponent, Input } from "ui";

  export let data: any;

  // let name = "";
  // let steps = "0";
  // let stepsError = "";
  let id = 0;
  let disabled = true;
  let isLoading = false;

  const form = {
    name: { value: "", error: "" },
    steps: { value: "", error: "" },
  };

  const editDefaultHabit = async (): Promise<void> => {
    try {
      const response = await putWithJwt(
        `${api}/habit/put/${data.defaultHabit.id}`,
        {
          habit: {
            id,
            name: form.name.value,
            steps: parseInt(form.steps.value),
          },
          clientId: $user.id,
        }
      );

      // saljemo res u DefaultHabits posto defaultHabits nije store
      data.executeFunction(response);

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")}
        ${translate("HABIT").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
    }
  };

  const createDefaultHabit = async (): Promise<void> => {
    try {
      const response = await postWithJwt(`${api}/habit/default`, {
        habit: {
          name: form.name.value,
          steps: parseInt(form.steps.value),
        },
      });

      // saljemo res u DefaultHabits posto defaultHabits nije store
      data.executeFunction(response);

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("HABIT").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.habitToUpdate) {
      data.executeFunction({
        id,
        name: form.name.value,
        steps: form.steps.value,
      });
      $dialogData = { data: {}, type: "" };
    } else if (data.isDefaultHabit) {
      if (data.defaultHabit) {
        await editDefaultHabit();
      } else {
        await createDefaultHabit();
      }
    } else if (data.executeFunction) {
      data.executeFunction({ name: form.name.value, steps: form.steps.value });
      $dialogData = { data: {}, type: "" };
    }

    isLoading = false;
  };

  const onInput = (key: keyof typeof form): void => {
    // if (key === "name") {
    if (form.name.value === "") {
      form.name.error = "FIELD_REQUIRED";
    } else if (form.name.value.length < 2) {
      form.name.error = "FIELD_MINIMUM_2";
    } else if (form.name.value.length > 40) {
      form.name.error = "FIELD_MAXIMUM_40";
    } else {
      form.name.error = "";
    }
    // } else if (key === "steps") {
    if (form.steps.value === "") {
      form.steps.error = "FIELD_REQUIRED";
    } else if (parseInt(form.steps.value) < 1) {
      form.steps.error = "FIELD_MINIMUM_AMOUNT_1";
    } else if (parseInt(form.steps.value) > 5) {
      form.steps.error = "FIELD_MAXIMUM_AMOUNT_5";
    } else {
      form.steps.error = "";
    }
    // }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  onMount((): void => {
    if (data.habitToUpdate) {
      form.name.value = data.habitToUpdate.title;
      form.steps.value = data.habitToUpdate.steps;

      id = data.habitToUpdate.id;
    } else if (data.isDefaultHabit && data.defaultHabit) {
      form.name.value = data.defaultHabit.name;
      form.steps.value = data.defaultHabit.steps;

      id = data.defaultHabit.id;
    }
  });
</script>

<div class="p-4">
  <h3 class="text-xxl font-medium text-center mt-4">
    {#if data.habitToUpdate}
      {translate("EDIT_HABIT")}
    {:else}
      {translate("CREATE_NEW_HABIT")}
    {/if}
  </h3>
  <Input
    label="TITLE"
    placeholder={translate("TEN_MINUTE_WALK")}
    bind:value={form.name.value}
    onInput={() => onInput("name")}
  />
  {#if form.name.error}
    <div class="text-red-500 text-xs">{translate(form.name.error)}</div>
  {/if}
  <div class="h-3" />
  <Input
    label="STEPS"
    placeholder="3"
    bind:value={form.steps.value}
    onInput={() => onInput("steps")}
  />
  {#if form.steps.error}
    <div class="text-red-500 text-xs">{translate(form.steps.error)}</div>
  {/if}
  <div class="flex flex-col items-center mt-6 mb-2">
    <ButtonComponent on:click={onSubmit} {isLoading} {disabled}>
      {translate("SUBMIT")}
    </ButtonComponent>
  </div>
</div>
