<script lang="ts">
  import { onMount } from "svelte";
  import {
    Button,
    Checkbox,
    Heading,
    Label,
    Search,
    Spinner,
  } from "flowbite-svelte";
  import { api, postWithJwt, translate, getWithJwt } from "lib";
  import {
    dialogData,
    showAlert,
    trainerClientGroups,
    trainerPrograms,
    trainerProgramsCount,
    user,
  } from "stores";
  import InfiniteScroll from "../../../components/UI/InfiniteScroll.svelte";
  import { trainerClientPrograms } from "stores";
  import { ButtonComponent, ButtonGroup } from "ui";

  let data: any;
  let timeout: NodeJS.Timeout;
  let page = 0;
  let search: string;
  let templateType: "MY" | "LIBRARY" | "GROUPS" = "MY";
  let isLoading = false;
  let scrollElem: HTMLDivElement;
  let selectedIds: Array<number> = [];
  let disabled = true;

  const sortWorkouts = (): void => {
    $trainerPrograms.forEach((plan): void => {
      plan.workouts = plan.workouts.sort(
        (a: any, b: any): number => a.position - b.position
      );
    });
  };

  const createUrl = (): string => {
    let skip = `&skip=${page * 15}`;
    let name = "";
    let template = "";

    if (search) {
      name = `&name=${search}`;
    }

    if (templateType === "MY") {
      template = "&default=1&template=0&group=0";
    } else if (templateType === "LIBRARY") {
      template = "&default=0&template=1&group=0";
    } else {
      template = "&default=0&template=0&group=1";
    }

    return `${api}/program?take=15${skip}${template}${name}`;
  };

  const fetch = async (): Promise<void> => {
    if ($user) {
      page = 0;

      try {
        const response = await getWithJwt(createUrl());
        $trainerPrograms = response.data;
        $trainerProgramsCount = response.count;
        sortWorkouts();
      } catch (error) {
        console.error(error);
      }

      isLoading = false;
    }
  };

  const onSearch = async (): Promise<void> => {
    clearTimeout(timeout);
    timeout = setTimeout(async (): Promise<void> => {
      fetch();
    }, 1000);
  };

  const onMyPrograms = async (): Promise<void> => {
    templateType = "MY";
    await fetch();
  };

  const onLibrary = async (): Promise<void> => {
    templateType = "LIBRARY";
    await fetch();
  };

  const onGroups = async (): Promise<void> => {
    templateType = "GROUPS";
    await fetch();
  };

  const onChange = (id: number): void => {
    if (selectedIds.includes(id)) {
      selectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    } else {
      selectedIds.push(id);
    }

    disabled = selectedIds.length === 0;
  };

  const onLoadMore = async (): Promise<void> => {
    if ($user) {
      page += 1;

      try {
        const response = await getWithJwt(createUrl());
        $trainerPrograms = [...$trainerPrograms, ...response.data];
        sortWorkouts();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const importToGroup = async (): Promise<void> => {
    try {
      const response = await postWithJwt(
        `${api}/client_group/program/assign_many`,
        {
          clientGroupId: data.group.id,
          programIds: selectedIds,
        }
      );

      const group = $trainerClientGroups.find(
        (group): boolean => group.id === data.group.id
      );

      group.clientGroupPrograms = [...group.clientGroupPrograms, ...response];

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_ASSIGNED_TRAINING_PROGRAM");

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_ASSIGNING_TRAINING_PROGRAM");
      isLoading = false;
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.group) {
      await importToGroup();
    } else {
      try {
        const response = await postWithJwt(`${api}/program/assign_many`, {
          clientIds: [data.client.id],
          programIds: selectedIds,
        });
        $trainerClientPrograms = [...$trainerClientPrograms, ...response];

        $showAlert.color = "black";
        $showAlert.message = translate(
          "SUCCESSFULLY_ASSIGNED_TRAINING_PROGRAM"
        );

        $dialogData.type = "";
        $dialogData.data = {};
      } catch (error) {
        console.error(error);
        $showAlert.color = "red-400";
        $showAlert.message = translate("ERROR_ASSIGNING_TRAINING_PROGRAM");
        isLoading = false;
      }
    }
  };

  onMount(async (): Promise<void> => {
    await fetch();
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h3">
    {#if data.client}
      {translate("ASSIGN_WORKOUT_PLAN_TO_CLIENT")}: {data.client.name}
    {:else if data.group}
      {translate("ASSIGN_WORKOUT_PLAN_TO_GROUP")}: {data.group.name}
    {:else}
      {translate("ASSIGN_WORKOUT_PLAN")}
    {/if}
  </Heading>

  <Search
    size="md"
    placeholder={translate("SEARCH_PROGRAM")}
    bind:value={search}
    on:input={onSearch}
  />

  <div class="flex justify-center">
    <ButtonGroup
      buttons={[
        { title: translate("MY_PROGRAMS"), onClick: onMyPrograms },
        { title: translate("LIBRARY"), onClick: onLibrary },
        { title: translate("GROUPS"), onClick: onGroups },
      ]}
    />
  </div>

  <div
    class="h-80 pr-4 flex flex-col gap-4 overflow-y-scroll"
    bind:this={scrollElem}
  >
    {#each $trainerPrograms as trainerProgram}
      <Label class="p-2 flex items-center border rounded-md">
        <div class="h-16 w-16 mr-4 overflow-hidden flex items-center">
          <img
            class="rounded-full"
            src={trainerProgram.thumbnailUrl}
            alt="Avatar"
          />
        </div>
        <div class="grow">{trainerProgram.name}</div>
        <Checkbox
          checked={selectedIds.includes(trainerProgram.id)}
          on:change={() => onChange(trainerProgram.id)}
        />
      </Label>
    {/each}

    <InfiniteScroll
      hasMore={$trainerPrograms.length <= $trainerProgramsCount}
      on:loadMore={onLoadMore}
    />
  </div>

  <div class="h-10 flex justify-center">
    {#if isLoading}
      <Spinner size="10" color="green" />
    {:else}
      <ButtonComponent {disabled} on:click={onSubmit}
        >{translate("ASSIGN")}</ButtonComponent
      >
    {/if}
  </div>
</div>
