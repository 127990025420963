<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { translate } from "lib";
  import { trainerClientsStore } from "stores";
  import {ButtonComponent, CheckboxComponent, RadioField, Svg, InputField} from "ui";
  import { tags as tagStore } from "../../stores/trainerStores";
  import FilterTag from "../../components/FilterTag.svelte";

  let isExpanded = false;
  let isDateSortVisible = false;

  let form = {
    dateFrom: {value: "", error: ""},
    dateTo: {value: "", error: ""}
  };

  let tags: Array<string> = [];
  let templateGroup: string | undefined;
  let sortGroup: string | undefined;

  const dispatch = createEventDispatcher();

  const templateTags = [
    "SHOW_ARCHIVED",
    "SHOW_DELETED",
    "SHOW_ACTIVE",
    "SHOW_ALL",
  ];

  const sortTags = [
    "NAME_ASC",
    "NAME_DESC",
    "DATE_ASC",
    "DATE_DESC",
    "LAST_LOGIN_ASC",
    "LAST_LOGIN_DESC",
    // "NEVER_LOGIN_ASC",
    // "NEVER_LOGIN_DESC",
    "INACTIVE_CHAT_ASC",
    "INACTIVE_CHAT_DESC",
  ];

  const map = new Map([
    [0, "SHOW_ACTIVE"],
    [1, "SHOW_ARCHIVED"],
    [2, "SHOW_DELETED"],
    [4, "SHOW_ALL"],
  ]);

  const unsubscribeTagStore = tagStore.subscribe((store): void => {
    tags = store.map(({note}): string => note);
  });

  const onRemoveTemplateTag = (): void => {
    $trainerClientsStore.filter.template = undefined;
    templateGroup = undefined;
    dispatch("fetchData");
  };

  const onRemoveSortTag = (): void => {
    $trainerClientsStore.filter.sort = undefined;
    sortGroup = undefined;
    dispatch("fetchData");
  };

  const onRemoveTag = (toRemove: string): void => {
    $trainerClientsStore.filter.tags = $trainerClientsStore
      .filter
      .tags
      .filter((tag) => tag !== toRemove);

    dispatch("fetchData");
  };

  const onTemplateTagChange = (): void => {
    if (templateGroup === "SHOW_ARCHIVED") {
      $trainerClientsStore.filter.template = 1;
    } else if (templateGroup === "SHOW_ACTIVE") {
      $trainerClientsStore.filter.template = 0;
    } else if (templateGroup === "SHOW_ALL") {
      $trainerClientsStore.filter.template = 4;
    } else {
      $trainerClientsStore.filter.template = 2;
    }

    dispatch("fetchData");
  };

  const onSortTagChange = (): void => {
    $trainerClientsStore.filter.sort = sortGroup;

    if (sortGroup === "INACTIVE_CHAT_ASC" || sortGroup === "INACTIVE_CHAT_DESC") {
      isDateSortVisible = true;
    } else {
      isDateSortVisible = false;
      $trainerClientsStore.filter.dateRange = ["", ""];
      dispatch("fetchData");
    }
  };

  const onTagChange = (checkedTag: string): void => {
    if ($trainerClientsStore.filter.tags.includes(checkedTag)) {
      $trainerClientsStore.filter.tags = $trainerClientsStore.filter.tags.filter(
        (tag) => tag !== checkedTag
      );
    } else {
      $trainerClientsStore.filter.tags = [checkedTag, ...$trainerClientsStore.filter.tags];
    }

    dispatch("fetchData");
  };

  const onInput = async (key: keyof typeof form): Promise<void> => {
    if (form.dateFrom.value && form.dateTo.value) {
      $trainerClientsStore.filter.dateRange = [
        form.dateFrom.value,
        form.dateTo.value
      ];

      dispatch("fetchData");
    } else {
      $trainerClientsStore.filter.dateRange = ["", ""];
    }
  };

  const onToggle = (): void => { isExpanded = !isExpanded; };

  onDestroy((): void => {
    unsubscribeTagStore();
  });

  onMount((): void => {
    if ($trainerClientsStore.filter.sort !== undefined) {
      sortGroup = $trainerClientsStore.filter.sort;
    }

    if ($trainerClientsStore.filter.template !== undefined) {
      templateGroup = map.get($trainerClientsStore.filter.template);
    }
  });
</script>

<div class="flex flex-col ">

  <div class="p-2 flex items-start gap-2">

    <div class="flex flex-wrap items-center grow gap-2">
      {#if $trainerClientsStore.filter.template !== undefined}
        <FilterTag
          title="{translate(map.get($trainerClientsStore.filter.template))}"
          on:removeFilter="{onRemoveTemplateTag}"/>
      {/if}
      {#if $trainerClientsStore.filter.sort !== undefined}
        <FilterTag
          title="{translate($trainerClientsStore.filter.sort)}"
          on:removeFilter="{onRemoveSortTag}"/>
      {/if}
      {#each $trainerClientsStore.filter.tags as filter}
        <FilterTag
          title="{filter}"
          on:removeFilter="{() => onRemoveTag(filter)}"/>
      {/each}
    </div>

    <ButtonComponent isOutline on:click="{onToggle}">
      {translate("FILTER")}
      {#if isExpanded}
        <Svg
          name="up-caret"
          size="{16}"
          customColor="bg-slate-700 dark:bg-slate-200"/>
      {:else}
        <Svg
          name="down-caret"
          size="{16}"
          customColor="bg-slate-700 dark:bg-slate-200"/>
      {/if}
    </ButtonComponent>
  </div>

  <!-- <div class="border-b border-slate-200 dark:border-zinc-600"></div> -->

  {#if isExpanded}
    <div class="flex flex-col border-[1px] rounded-md border-slate-200 dark:border-zinc-600" in:slide out:slide>

      <div class="flex">
        <div class="basis-1/2 flex flex-col">
          <div class="p-2 flex flex-col gap-2">
            {#each sortTags as sortTag}
              <RadioField
                id="sortTags"
                fontSize="xxs"
                value={sortTag}
                bind:group={sortGroup}
                on:change={onSortTagChange}
              >
                {translate(sortTag)}
              </RadioField>
            {/each}
          </div>

          <div class="border-b border-slate-200 dark:border-zinc-600"></div>

          <div class="p-2 flex flex-col gap-2">
            {#each templateTags as templateTag}
              <RadioField
                id="templateTags"
                fontSize="xxs"
                value={templateTag}
                bind:group={templateGroup}
                on:change={onTemplateTagChange}
              >
                {translate(templateTag)}
              </RadioField>
            {/each}
          </div>
        </div>

        <div class="border-l border-slate-200 dark:border-zinc-600"></div>

        <div class="basis-1/2 p-2 flex flex-col gap-2 text-xxs">
          {#each tags as tag}
            <div class="flex flex-row">
              <div
                on:click={() => onTagChange(tag)}
                on:keypress={() => onTagChange(tag)}
                tabindex="0"
                role="button"
              >
                <CheckboxComponent value={$trainerClientsStore.filter.tags.includes(tag)} />
              </div>
              <span class="text-xs ml-1 dark:text-slate-300">{tag}</span>
            </div>
          {/each}
        </div>
      </div>

      <div class="border-b border-slate-200 dark:border-zinc-600"></div>

      {#if isDateSortVisible}
        <div class="mt-2 text-xs text-center font-bold">{translate(sortGroup)}</div>
        <div class="p-2 flex gap-2">
          <div class="basis-1/2">
            <InputField
              type="date"
              label="{translate("DATE_FROM")}"
              error="{form.dateFrom.error}"
              bind:value="{form.dateFrom.value}"
              on:input="{() => onInput("dateFrom")}"/>
          </div>
          <div class="basis-1/2">
            <InputField
              type="date"
              label="{translate("DATE_TO")}"
              error="{form.dateTo.error}"
              bind:value="{form.dateTo.value}"
              on:input="{() => onInput("dateTo")}"/>
          </div>
        </div>
      {/if}
    </div>

  {/if}

</div>
