<script lang="ts">
  import {Svg} from "ui";

  export let isExpanded: boolean;
</script>

<div class="rotator" class:rotate="{isExpanded}" on:click>
  <Svg name="down-caret" size="{16}"/>
</div>

<style>
  .rotator {
    transition: transform 333ms linear;
  }

  .rotate {
    transform: rotate(180deg);
  }
</style>
