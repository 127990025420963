<script lang="ts">
  export let color = "blue";
  export let customClass = "";
</script>

<div
  class="leading-none bg-primary-500 text-white text-xs font-medium px-2 py-1 rounded-xl dark:bg-primary-500 {customClass}"
>
  <slot />
</div>
