import { trainerClientPrograms, trainerPrograms } from "stores";
import { get } from "svelte/store";

export const updateTrainerPrograms = async (programId, updateFunction) => {
  let p = get(trainerPrograms).find(({ id }) => id === programId);
  if (p) {
    updateFunction(p);
    trainerPrograms.set(get(trainerPrograms));
  } else {
    p = get(trainerClientPrograms).find(({ id }) => id === programId);
    if (p) {
      updateFunction(p);
      trainerClientPrograms.set(get(trainerClientPrograms));
    }
  }
};
