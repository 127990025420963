<script lang="ts">
  export let value: string;
  export let placeholder = "";
</script>

<textarea
  class="w-full dark:bg-zinc-700 rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500 sm:text-sm sm:leading-6"
  {placeholder}
  rows={4}
  bind:value
  on:input
/>
