import {get, writable} from "svelte/store";
import {getWithJwt, isClient, MealPlanTemplate, serverlessRoutes} from "lib";
import {storeUtil} from "../lib/createItemsStore";
import {currentClient} from "./currentClient";
import type {ItemsStore, MealPlan} from "interfaces";
import { user } from "./userStore";

interface MealPlansStore extends ItemsStore<MealPlan> {
  filter: {
    search: string;
    template: MealPlanTemplate | undefined;
  };
}

const mealPlansStoreCreate = () => {
  const {set, subscribe, update} = writable<MealPlansStore>({
    items: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    skip: 0,
    searchTimeout: undefined,
    filter: {
      search: "",
      template: MealPlanTemplate.MY
    }
  });

  const createUrl = (): string => {
    const {
      skip,
      filter: {search, template}
    } = get({subscribe});

    const {MY, LIBRARY, GROUPS} = MealPlanTemplate;
    const params = new URLSearchParams();

    params.append("take", "8");
    params.append("skip", skip.toString());

    const trainerClient = get(currentClient);

    if (trainerClient.id || isClient(get(user))) {
      const id = (trainerClient.id || get(user).id);
      params.append("clientId", id.toString());
    } else {
      params.append("default", template === MY ? "1" : "0");
      params.append("template", template === LIBRARY ? "1" : "0");
      params.append("group", template === GROUPS ? "1" : "0");
    }

    if (search) {
      params.append("name", search);
    }

    if (trainerClient.id || isClient(get(user))) {
      return `${serverlessRoutes.MEAL_PLANS}/list/client?${params.toString()}`;
    } else {
      return `${serverlessRoutes.MEAL_PLANS}/list?${params.toString()}`;
    }
  };

  const {
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache
  } = storeUtil<MealPlan, MealPlansStore>("mealPlansCache", update, createUrl);

  const fetchMeals = async (id: any): Promise<void> => {
    const {error, data} = await getWithJwt(
      `${serverlessRoutes.MEAL_PLANS}/${id}`
    );

    if (error && !data) {
      console.error(error);
      return;
    }

    update((store) => {
      const mp = store.items.find((mealPlan) => mealPlan.id === id);
      if (mp) {
        mp.meals = data.mealPlan.meals.filter((meal: any) => meal.finishedAt === null);
      }
      return store;
    });
  };


  const createUrlAssign = (): string => {
    const {
      skip,
      filter: {search, template}
    } = get({subscribe});

    const {MY, LIBRARY, GROUPS} = MealPlanTemplate;
    const params = new URLSearchParams();

    params.append("take", "8");
    params.append("skip", skip.toString());

    params.append("default", template === MY ? "1" : "0");
    params.append("template", template === LIBRARY ? "1" : "0");
    params.append("group", template === GROUPS ? "1" : "0");

    if (search) {
      params.append("name", search);
    }

    return `${serverlessRoutes.MEAL_PLANS}/list?${params.toString()}`;
  };
  const fetchDataAssign = async () => {
    if (!get(user)) {
      return;
    }

    update((store) => {
      store.isFetching = true;
      store.skip = 0;
      return store;
    });

    try {
      const response = await getWithJwt(createUrlAssign());
      const {items, count} = response.data;

      update((store) => {
        store.items = items;
        store.count = count;
        store.hasMore = store.items.length < store.count;

        return store;
      });

      // localStorage.setItem(cacheName, JSON.stringify({items, count}));
    } catch (error) {
      console.error(error);
    } finally {
      update((store) => {
        store.isFetching = false;
        return store;
      });
    }
  };
  const fetchMoreDataAssign = async () => {
    if (!get(user)) {
      return;
    }

    update((store) => {
      store.isFetchingMore = true;
      store.skip += 8;
      return store;
    });

    try {
      const response = await getWithJwt(createUrlAssign());

      update((store) => {
        store.items.push(...response.data.items);
        store.isFetchingMore = false;
        return store;
      });
    } catch (error) {
      console.error(error);
    }
  };
  const searchAssign = () => {
    update((store) => {
      clearTimeout(store.searchTimeout);
      store.searchTimeout = setTimeout(fetchDataAssign, 1000);
      return store;
    });
  };



  return {
    set,
    subscribe,
    update,
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    loadCache,
    search,

    fetchMeals,

    fetchDataAssign,
    fetchMoreDataAssign,
    searchAssign
  };
};

const mealPlansStore = mealPlansStoreCreate();

export {mealPlansStore};
