<script lang="ts">
  import { onMount } from "svelte";
  import { api, postWithJwt, putWithJwt, translate } from "lib";
  import { dialogData, showAlert, tags } from "stores";
  import { ButtonComponent, ColorPicker, Input } from "ui";

  export let data;

  let disabled = true;
  let isLoading = false;
  const dialogTitle = data.tag ? translate("EDIT_TAG") : translate("CREATE_TAG");
  const buttonTitle = data.tag ? translate("EDIT") : translate("CREATE");

  const form = {
    note:   { value: "", error: "" },
    color:  { value: "", error: "" }
  };

  const onInput = (key: keyof typeof form): void => {
    if (key === "note") {
      if (form.note.value.length < 2) {
        form.note.error = "FIELD_MINIMUM_2";
      } else if (form.note.value.length > 40) {
        form.note.error = "FIELD_MAXIMUM_40";
      } else {
        form.note.error = "";
      }
    } else if (key === "color") {

    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const createTag = async (): Promise<void> => {
    try {
      const response = await postWithJwt(`${api}/client_tag`, {
        clientTag: {
          note: form.note.value,
          color: form.color.value
        }
      });

      $tags = [...$tags, response];

      $dialogData.type = "";
      $dialogData.data = {};

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("TAG").toLowerCase()}
      `;
    } catch (error) {
      console.error(error);
    }
  };

  const editTag = async (): Promise<void> => {
    try {
      const response = await putWithJwt(`${api}/client_tag/${data.tag.id}`, {
        note: form.note.value,
        color: form.color.value
      });

      const tag = $tags.find((tag) => tag.id === data.tag.id);
      const index = $tags.indexOf(tag);
      $tags = $tags.with(index, response);

      $dialogData.type = "";
      $dialogData.data = {};

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")}
        ${translate("TAG").toLowerCase()}
      `;
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.tag) {
      await editTag();
    } else {
      await createTag();
    }

    isLoading = false;
  };

  onMount((): void => {
    if (data.tag) {
      form.note.value = data.tag.note;
      form.color.value = data.tag.color;
    }
  });
</script>

<div class="p-4 flex flex-col gap-4">
  <h3 class="text-xxl font-medium text-center">{dialogTitle}</h3>

  <div>
    <p class="mb-1">{translate("NEW_TAG_NAME")}:</p>
    <Input
      name="amount"
      placeholder={translate("NEW_TAG_NAME")}
      bind:value={form.note.value}
      onInput={() => onInput("note")}
    />
    {#if form.note.error}
      <div class="text-red-500 text-xs">{translate(form.note.error)}</div>
    {/if}
  </div>

  <div>
    <ColorPicker
      id="color"
      label="{translate("NEW_TAG_COLOR")}"
      bind:value={form.color.value}
      on:input={() => onInput("color")}
    />
    <div class="text-slate-500 text-xs dark:text-slate-300">
      {translate("NEW_TAG_COLOR_HINT")}
    </div>
  </div>

  <div class="flex justify-center mb-2">
    <ButtonComponent on:click={onSubmit} {isLoading}>{buttonTitle}</ButtonComponent>
  </div>
</div>
