import {writable} from "svelte/store";

type AlertType = "primary" | "success" | "error";

interface Alert {
  id: number;
  message: string;
  type: AlertType;
  timeout: NodeJS.Timeout;
}

const alertStoreCreate = () => {
  const {set, subscribe, update} = writable<Array<Alert>>([]);

  const DURATION = 7000;

  const hide = (id: number): void => {
    update((store) => {
      const index = store.findIndex((alert): boolean => alert.id === id);

      if (index > -1) {
        clearTimeout(store[index].timeout);
        store.splice(index, 1);
      }

      return store;
    });
  };

  const show = (message: string, type: AlertType = "primary"): void => {
    update((store) => {
      const id = Math.random() * 1_000_000;

      const timeout = setTimeout((): void => {
        hide(id);
      }, DURATION);

      store.unshift({id, message, type, timeout});

      return store;
    });
  };

  return {set, subscribe, update, hide, show};
};

const alertStore = alertStoreCreate();

export {alertStore};
