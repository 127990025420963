<script lang="ts">
  import type { IButtonGroup } from "interfaces";

  export let buttons: IButtonGroup[] = [];
  export let selectedIndex = 0;
</script>

<div class="inline-flex ring-1 ring-slate-200 dark:ring-slate-500 rounded-lg" role="group">
  {#each buttons as button, index}
    <!-- border
        border-gray-200
        dark:border-gray-700
        dark:hover:bg-gray-600 -->
    <button
      on:click={() => {
        selectedIndex = index;
        button.onClick();
      }}
      type="button"
      class={`
        px-4
        py-2
        border-0
        text-xs
        font-medium
        text-gray-900

        ${selectedIndex === index ? "bg-slate-200 dark:bg-zinc-500" : "bg-white dark:bg-zinc-700"}

        ${index === 0 ? "rounded-s-lg" : ""}
        ${index === buttons.length - 1 ? "rounded-e-lg" : ""}

        hover:bg-slate-200
        hover:text-primary-700

        focus:text-primary-700

        dark:text-white
        dark:hover:text-white
        
      `}
    >
      {button.title}
    </button>
  {/each}
</div>
