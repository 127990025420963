<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { api, MealPlanTemplate, postWithJwt, serverlessRoutes, translate } from "lib";
  import {
    dialogData,
    selectedIdsStore,
    showAlert,
    trainerClientGroups,
    mealPlansStore,
    alertStore,
    currentClient,
  } from "stores";
  import { ButtonComponent, ButtonGroup, InfiniteScroll, Search } from "ui";
  import MealPlanComponent from "../../../components/Diet/MealPlan.svelte";
  import type { PartialItemMap, MealPlan, IButtonGroup } from "interfaces";
  import MealPlansToolbarComponent from "../../../components/Diet/MealPlansToolbarComponent.svelte";

  let data: any;
  let isLoading = false;
  let scrollElement: HTMLDivElement;

  $: disabled = $selectedIdsStore.length === 0;

  const sortMeals = (): void => {
    $mealPlansStore.items.forEach((plan): void => {
      if (plan.meals) {
        plan.meals = plan.meals.sort((a, b) => a.position - b.position);
      }
    });
  };

  const fetchData = async () => {
    await mealPlansStore.fetchDataAssign();
    sortMeals();
  };

  const fetchMoreData = async () => {
    await mealPlansStore.fetchMoreDataAssign();
    sortMeals();
  };

  const importToGroup = async (): Promise<void> => {
    try {
      const response = await postWithJwt(
        `${api}/client_group/meal_plan/assign_many`,
        {
          clientGroupId: data.group.id,
          programIds: $selectedIdsStore,
        }
      );

      const group = $trainerClientGroups.find(
        (group): boolean => group.id === data.group.id
      );

      group.clientGroupMealPlans = [...group.clientGroupMealPlans, ...response];

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_ASSIGNED_MEAL_PLANS");

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_ASSIGNING_MEAL_PLAN");
      isLoading = false;
    }
  };

  const assignToClient = async (): Promise<void> => {
    try {
      const partialMealPlanMap: PartialItemMap<MealPlan> = {};

      $selectedIdsStore.forEach((id): void => {
        partialMealPlanMap[id] = {
          isTemplate: 0,
          isDefault: 0,
          clientId: data.client.id
        };
      });

      const response = await postWithJwt(
        `${serverlessRoutes.MEAL_PLANS}/copy`,
        { partialMealPlanMap }
      );

      alertStore.show(translate("SUCCESSFULLY_ASSIGNED_MEAL_PLANS"));

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      alertStore.show(translate("ERROR_ASSIGNING_MEAL_PLAN"), "error");
      isLoading = false;
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.group) {
      await importToGroup();
    } else if (data.client) {
      await assignToClient();
    }
  };

  const mountScrollElement = (element: HTMLDivElement) => {
    scrollElement = element;
  };

  onMount(async (): Promise<void> => {
    await fetchData();
  });

  onDestroy(async () => {
    selectedIdsStore.clear();
    await mealPlansStore.fetchData();
    sortMeals();
  });



  let templateType: MealPlanTemplate = MealPlanTemplate.MY;
  const switchTemplate = async (type: MealPlanTemplate): Promise<void> => {
    if (templateType !== type) {
      templateType = type;
      $mealPlansStore.filter.template = type;
      await mealPlansStore.fetchDataAssign();
    }
  };
  const buttonGroup: IButtonGroup[] = [
    {
      title: `${translate("MY")} ${translate("PLANS").toLowerCase()}`,
      async onClick (): Promise<void> {
        await switchTemplate(MealPlanTemplate.MY);
      },
    },
    {
      title: translate("LIBRARY"),
      async onClick (): Promise<void> {
        await switchTemplate(MealPlanTemplate.LIBRARY);
      },
    },
    {
      title: translate("GROUPS"),
      async onClick (): Promise<void> {
        await switchTemplate(MealPlanTemplate.GROUPS);
      },
    },
  ];




  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {#if data.client}
      {translate("ASSIGN_MEAL_PLAN_TO_CLIENT")}: {data.client.name}
    {:else if data.group}
      {translate("ASSIGN_MEAL_PLAN_TO_GROUP")}: {data.group.name}
    {:else}
      {translate("ASSIGN_MEAL_PLAN")}
    {/if}
  </div>

  <!-- <MealPlansToolbarComponent/> -->
  <div class="flex gap-4">
    <Search
      placeholder={translate("SEARCH_MEAL_PLANS")}
      bind:value={$mealPlansStore.filter.search}
      on:input={mealPlansStore.searchAssign}
    />

    <!-- {#if isAddVisible}
      <ButtonComponent myClasses="whitespace-nowrap" on:click={onAddMealPlan}>
        {translate("ADD")}
        {translate("MEAL_PLAN").toLowerCase()}
      </ButtonComponent>
    {/if} -->
  </div>

  <div class="flex flex-col gap-4">
    <div class="flex justify-center">
      <ButtonGroup buttons={buttonGroup} />
    </div>

    <div class="text-center text-xs">
      {translate("NUMBER_OF_MEAL_PLANS")}:
      <span class="font-bold">{$mealPlansStore.count}</span>
    </div>
  </div>

  <div
    class="h-64 pr-2 flex flex-col gap-2 overflow-y-scroll"
    use:mountScrollElement
  >
    {#each $mealPlansStore.items as mealPlan}
      <MealPlanComponent isSelectMode {mealPlan}/>
    {/each}

    <InfiniteScroll
      {scrollElement}
      hasMore={$mealPlansStore.hasMore}
      isFetchingMore={$mealPlansStore.isFetchingMore}
      on:loadMore={fetchMoreData}
    />
  </div>

  <div class="h-10 flex justify-center">
    <ButtonComponent {disabled} {isLoading} on:click={onSubmit}>
      {translate("ASSIGN")}
    </ButtonComponent>
  </div>
</div>
