<script lang="ts">
  import { showAlert, user } from "stores";
  import { translate } from "../../lib/translate";
  import { dialogData } from "../../stores/dialogDataStore";
  import {ButtonComponent} from "ui";

  export let data: any;

  let confirmEmail = "";
  let isDisabled = false;
  $: isDisabled = confirmEmail !== $user.email;
</script>

<div class="text-center">
  <h1 class="mt-7" style="font-size: 1.25rem; font-weight: 400;">
    {translate("ARE_YOU_SURE")}?
  </h1>
  <h3 class="m-5" style="font-size: 0.9rem; font-weight: 400;">
    {translate("ARE_YOU_SURE_YOU_WANT_TO_DELETE_ACCOUNT")}
  </h3>
  <input bind:value={confirmEmail} type="email" style="color: black" />
  <ButtonComponent
    disabled={isDisabled}
    on:click={async () => {
      $showAlert.message = `${translate("SUCCESSFULLY_DELETED_ACCOUNT")}`;
      $dialogData = { data: {}, type: "" };
      await data.executeFunction();
    }}>{translate("DELETE")}</ButtonComponent>
  <div class="mb-7"></div>
</div>
