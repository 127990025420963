<script lang="ts">
  import {afterUpdate} from "svelte";
  import {push} from "svelte-spa-router";

  import {
    deleteWithJwt,
    dialogTypes,
    filterMenu,
    postWithJwt,
    serverlessRoutes,
    translate
  } from "lib";

  import {
    alertStore,
    currentClient,
    dialogData,
    selectedIdsStore,
    mealPlansStore
  } from "stores";

  import {
    ButtonComponent,
    CheckboxComponent,
    More,
    Svg,
    TemplateTagComponent
  } from "ui";

  import type {MealPlan, MenuItem, PartialItemMap} from "interfaces";

  export let mealPlan: MealPlan;
  export let isSelectMode = false;

  const src = mealPlan.fileHash || mealPlan.imageUrl || "logo.png";

  let isSelected = false;

  const defaultImage =
    "https://train-me-api.online/files/images/defaults/moj-trening/default-mealplans.png";

  const saveToDatabase = async (): Promise<void> => {
    const partialMealPlanMap: PartialItemMap<MealPlan> = {
      [mealPlan.id]: {
        isDefault: 1,
        isTemplate: 0,
        clientId: null
      }
    };

    await postWithJwt(
      `${serverlessRoutes.MEAL_PLANS}/copy`,
      {partialMealPlanMap}
    );

    alertStore.show(translate("SUCCESSFULLY_SAVED_TO_DATABASE"));
  };

  const assignToClientsDialog = (): void => {
    $dialogData.data = {mealPlan};
    $dialogData.type = dialogTypes.ASSIGN_TO_CLIENTS;
  };

  const editMealPlanDialog = (): void => {
    $dialogData.data = {mealPlan};
    $dialogData.type = dialogTypes.CREATE_EDIT_MEAL_PLAN;
  };

  const copyMealPlan = async () => {
    const partialMealPlanMap: PartialItemMap<MealPlan> = {
      [mealPlan.id]: {
        isTemplate: 0
      }
    };

    const {data, error} = await postWithJwt(
      `${serverlessRoutes.MEAL_PLANS}/copy`,
      {partialMealPlanMap}
    );

    if (error && !data) {
      alertStore.show(translate("ERROR_COPYING_MEAL_PLAN"), "error");
      return console.error(error);
    }

    mealPlansStore.add(data.mealPlans);
    alertStore.show(translate("SUCCESSFULLY_COPIED_MEAL_PLAN"));
  };

  const deleteMealPlan = async (): Promise<void> => {
    const {id, isDefault} = mealPlan;

    const {data, error} = await deleteWithJwt(serverlessRoutes.MEAL_PLANS, {
      ids: [id]
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_DELETING_MEAL_PLAN"), "error");
      return console.error(error);
    }

    // if (isDefault) {
      mealPlansStore.remove(data.ids);
    // }

    alertStore.show(translate("SUCCESSFULLY_DELETED_MEAL_PLAN"));
  };

  const deleteMealPlanDialog = (): void => {
    $dialogData.data = {
      title: mealPlan.name,
      executeFunction: deleteMealPlan
    };

    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const unfilteredMenuItems: Array<MenuItem> = [{
    title: "ASSIGN_TO_CLIENTS",
    icon: "assign",
    executeFunction: assignToClientsDialog
  }, {
    title: "ASSIGN_TO_ANOTHER_CLIENT",
    icon: "assign",
    executeFunction: assignToClientsDialog
  }, {
    title: "SAVE_TO_DATABASE",
    icon: "save",
    executeFunction: saveToDatabase
  }, {
    title: "EDIT",
    icon: "edit",
    executeFunction: editMealPlanDialog
  }, {
    title: "CREATE_COPY",
    icon: "copy",
    executeFunction: copyMealPlan
  }, {
    title: "DELETE",
    icon: "delete",
    executeFunction: deleteMealPlanDialog
  }];

  let menuItems: Array<MenuItem> = [];

  const onSelect = (): void => {
    const {id} = mealPlan;

    if (isSelected) {
      selectedIdsStore.add(id);
    } else {
      selectedIdsStore.remove(id);
    }
  };

  const onToggle = (): void => {
    isSelected = !isSelected;
    onSelect();
  };

  const onGotoPlanPage = async (): Promise<void> => {
    const {id} = mealPlan;

    if ($currentClient.id) {
      await push(`/profile/${$currentClient.id}/diet/meal-plans/${id}`);
    } else {
      await push(`/diet/meal-plans/${id}`);
    }
  };

  const onImport = async (): Promise<void> => {};

  afterUpdate(() => {
    const {isDefault, isTemplate, clientId} = mealPlan;

    if (isTemplate) {
      menuItems = filterMenu(
        unfilteredMenuItems,
        ["CREATE_COPY"]
      );
    } else if (isDefault) {
      menuItems = filterMenu(
        unfilteredMenuItems,
        ["ASSIGN_TO_CLIENTS", "EDIT", "CREATE_COPY", "DELETE"]
      );
    } else if (clientId) {
      menuItems = filterMenu(
        unfilteredMenuItems,
        ["ASSIGN_TO_CLIENTS", "SAVE_TO_DATABASE", "EDIT", "CREATE_COPY", "DELETE"]
      );
    }
  });
</script>

<style>
  .isSelected {
    border-color: rgb(var(--primary));
  }
</style>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
{#if isSelectMode}
  <div
    class="
      relative
      pr-4
      flex
      items-center
      justify-between
      border
      border-gray-200
      rounded-md
      bg-gray-100
      dark:bg-zinc-800
      dark:border-zinc-600
      transition-[border-color, opacity]
      linear
      duration-[333ms]
    "
    class:isSelected
    on:click={onToggle}
  >

    {#if mealPlan.isTemplate}
      <TemplateTagComponent/>
    {/if}

    <div class="h-16 aspect-video flex items-center justify-center overflow-hidden rounded-l-md">
      <img {src} alt="Meal plan {mealPlan.name}"/>
    </div>

    <div class="flex gap-4">
      <div class="text-xs leading-none">
        {mealPlan.name}
        {#if mealPlan.duration}
          <span>({mealPlan.duration} {translate("DAYS").toLowerCase()})</span>
        {/if}
      </div>
    </div>

    <CheckboxComponent bind:value={isSelected} on:change={onSelect}/>
  </div>
{:else}
      <!-- bg-gray-100
      dark:bg-zinc-800 -->
  <div
    class="
      relative
      flex
      flex-col
      border
      border-gray-200
      rounded-md
      dark:border-zinc-600
    "
    on:click={onGotoPlanPage}
  >
    {#if mealPlan.isTemplate}
      <TemplateTagComponent/>
    {/if}

    <div class="flex items-center justify-center">
      <img
        class="max-h-64 rounded-t-md"
        {src}
        alt="Meal plan {mealPlan.name}"
      />
    </div>

    <div class="p-4 flex justify-between gap-4">
      <div class="flex flex-col gap-4">
        <div>{mealPlan.name}</div>
        {#if mealPlan.duration}
          <div class="text-xs">
            {translate("DURATION")}: {mealPlan.duration}
            {translate("DAYS").toLowerCase()}
          </div>
        {/if}
        {#if mealPlan.description}
          <div class="text-xs text-justify line-clamp-3">
            {mealPlan.description}
          </div>
        {/if}
        {#if mealPlan.isTemplate}
          <div>
            <ButtonComponent on:click={onImport}>
              <div class="flex items-center gap-2">
                <Svg name="import" size={20} customColor="bg-white"/>
                {translate("IMPORT")}
              </div>
            </ButtonComponent>
          </div>
        {:else if mealPlan.isDefault}
          <div>
            <ButtonComponent on:click={assignToClientsDialog}>
              <div class="flex items-center gap-2">
                <Svg name="assign" size={20} customColor="bg-white"/>
                {translate("ASSIGN_TO_CLIENTS")}
              </div>
            </ButtonComponent>
          </div>
        {/if}
      </div>

      <div class="flex items-start justify-between">
        {#if isSelectMode}
          <CheckboxComponent bind:value={isSelected} on:change={onSelect} />
        {/if}
        <More {menuItems} />
      </div>
    </div>
  </div>
{/if}
