<script lang="ts">
  import { onMount } from "svelte";
  import {
    Button,
    Heading,
    Helper,
    Input,
    Label,
    Spinner,
    Textarea,
  } from "flowbite-svelte";
  import { api, postFormDataWithJwt, translate } from "lib";
  import {
    dialogData,
    showAlert,
    trainerWorkouts,
    trainerWorkoutsCount,
    trainerPrograms,
    trainerClientPrograms,
  } from "stores";
  import { updateTrainerPrograms } from "../../../lib/updateTrainerPrograms";
  import { ButtonComponent } from "ui";

  let data: any;
  let isLoading = false;
  let disabled = true;

  const form = {
    name: { value: "", error: "" },
    description: { value: "", error: "" },
    protocol: { value: "", error: "" },
  };
  const type = data.workout ? translate("EDIT") : translate("CREATE");

  const onInput = (field: keyof typeof form): void => {
    const { length } = form[field].value;

    if (field === "name") {
      if (!length) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "protocol") {
      if (length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some(({ error }) => error !== "");
  };

  const editWorkout = async (): Promise<void> => {
    const { workout } = data;

    try {
      isLoading = true;

      const formData = new FormData();

      formData.append(
        "workout",
        JSON.stringify({
          id: workout.id,
          name: form.name.value,
          description: form.description.value,
          protocol: form.protocol.value,
        })
      );

      const response = await postFormDataWithJwt(
        `${api}/workout/${workout.id}`,
        formData,
        "PUT"
      );

      if (workout.default) {
        // Workout u Workouts
        const w = $trainerWorkouts.find(({ id }) => id === workout.id);

        $trainerWorkouts = $trainerWorkouts.with($trainerWorkouts.indexOf(w), {
          ...response,
          exercises: workout.exercises,
        });
      } else {
        // Workout u Program

        updateTrainerPrograms(workout.programId, (p) => {
          const w = p.workouts.find((w: any) => w.id === workout.id);
          const i = p.workouts.indexOf(w);
          p.workouts.splice(i, 1, {
            ...response,
            exercises: workout.exercises,
          });
        });
      }

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")}
        ${translate("WORKOUT").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_EDITING_WORKOUT");
    }
  };

  const createWorkoutWithExercises = async (): Promise<void> => {
    try {
      isLoading = true;

      const formData = new FormData();

      formData.append("ownerType", "USER");
      formData.append("exercisesForImport", JSON.stringify(data.exerciseIds));
      formData.append(
        "workout",
        JSON.stringify({
          name: form.name.value,
          description: form.description.value,
          entityType: 0,
          protocol: form.protocol.value,
        })
      );

      const response = await postFormDataWithJwt(`${api}/workout`, formData);

      $trainerWorkouts = [response, ...$trainerWorkouts];

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("WORKOUT").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_WORKOUT");
    }
  };

  const createWorkoutInProgram = async (): Promise<void> => {
    try {
      isLoading = true;

      const formData = new FormData();

      formData.append("programId", data.programId);
      formData.append(
        "workout",
        JSON.stringify({
          name: form.name.value,
          description: form.description.value,
          entityType: 0,
          programId: data.programId,
          protocol: form.protocol.value,
        })
      );

      const response = await postFormDataWithJwt(`${api}/workout`, formData);

      updateTrainerPrograms(data.programId, (p) => {
        p.workouts = [...p.workouts, response];
      });

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("WORKOUT").toLowerCase()}
      `;

      $dialogData.data = {};
      $dialogData.type = "";
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_WORKOUT");
    }
  };

  const createWorkout = async (): Promise<void> => {
    try {
      isLoading = true;

      const formData = new FormData();

      formData.append(
        "workout",
        JSON.stringify({
          name: form.name.value,
          description: form.description.value,
          entityType: 0,
          protocol: form.protocol.value,
        })
      );
      formData.append("ownerType", "USER");

      const response = await postFormDataWithJwt(`${api}/workout`, formData);

      $trainerWorkouts = [response, ...$trainerWorkouts];
      $trainerWorkoutsCount += 1;

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("WORKOUT").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_WORKOUT");
    }
  };

  const onSubmit = async (): Promise<void> => {
    if (data.workout) {
      await editWorkout();
    } else if (data.exerciseIds) {
      await createWorkoutWithExercises();
    } else if (data.programId) {
      await createWorkoutInProgram();
    } else {
      await createWorkout();
    }
  };

  onMount((): void => {
    if (data.workout) {
      const { name, description, protocol } = data.workout;
      if (name) {
        form.name.value = name;
        onInput("name");
      }

      if (description) {
        form.description.value = description;
        onInput("description");
      }

      if (protocol) {
        form.protocol.value = protocol;
        onInput("protocol");
      }
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading tag="h6" align="center">
    {type}
    {translate("WORKOUT").toLowerCase()}
  </Heading>

  <div>
    <Label for="name">{translate("NAME")}:</Label>
    <Input
      id="name"
      placeholder={translate("NAME")}
      bind:value={form.name.value}
      on:input={() => onInput("name")}
    />
    <Helper color="red">
      {#if form.name.error}
        {form.name.error}
      {:else}
        ㅤ
      {/if}
    </Helper>
  </div>

  <div>
    <Label for="description">{translate("DESCRIPTION")}:</Label>
    <Textarea
      id="description"
      placeholder={translate("DESCRIPTION")}
      rows="4"
      bind:value={form.description.value}
      on:input={() => onInput("description")}
    />
    <Helper color={form.description.error ? "red" : "gray"}>
      {#if form.description.error}
        {form.description.error}
      {:else}
        * Opis treninga je vidljiv samo treneru
      {/if}
    </Helper>
  </div>

  <div>
    <Label for="protocol">{translate("PROTOCOL")}:</Label>
    <Textarea
      id="protocol"
      placeholder={translate("PROTOCOL")}
      rows="3"
      bind:value={form.protocol.value}
      on:input={() => onInput("protocol")}
    />
    <Helper color={form.protocol.error ? "red" : "gray"}>
      {#if form.protocol.error}
        {form.protocol.error}
      {:else}
        * Protokol treninga je vidljiv i klijentu
      {/if}
    </Helper>
  </div>

  <div class="h-10 flex justify-center">
    {#if isLoading}
      <Spinner size="10" color="green" />
    {:else}
      <ButtonComponent {disabled} on:click={onSubmit}>{type}</ButtonComponent>
    {/if}
  </div>
</div>
