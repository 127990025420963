<script lang="ts">
  import {onMount} from "svelte";
  import {slide} from "svelte/transition";
  import {api, dialogTypes, getWithJwt, translate} from "lib";
  import {dialogData, recipesStore} from "stores";
  import {ButtonComponent, CheckboxComponent, RadioField, Search, Svg} from "ui";
  import FilterTag from "../FilterTag.svelte";
  import DualSlider from "../UI/DualSlider.svelte";

  interface MacroBarChange {
    carbs: number;
    protein: number;
    fats: number;
  }

  let isExpanded = false;
  let isAddVisible = false;
  let tags: Array<string> = [];
  let templateGroup: "ALL" | "MY" | undefined = "ALL";
  let valuesTimeout: NodeJS.Timeout;
  const templateTags = ["ALL", "MY"];

  const fetchRecipeTags = async (): Promise<void> => {
    try {
      tags = await getWithJwt(`${api}/recipe/food_types`);
    } catch (error) {
      console.error(error);
    }
  };

  const onRemoveTemplateTag = async (): Promise<void> => {
    $recipesStore.filter.template = undefined;
    templateGroup = undefined;
    await recipesStore.fetchData();
  };

  const onRemoveMacroTag = async (): Promise<void> => {
    $recipesStore.filter.macroRatio = undefined;
    await recipesStore.fetchData();
  };

  const onRemoveTag = async (toRemove: string): Promise<void> => {
    $recipesStore.filter.tags = $recipesStore.filter.tags.filter(
      (tag): boolean => tag !== toRemove
    );

    await recipesStore.fetchData();
  };

  const onTemplateTagChange = async (): Promise<void> => {
    if (templateGroup === "ALL") {
      $recipesStore.filter.template = 1;
    } else {
      $recipesStore.filter.template = 0;
    }

    await recipesStore.fetchData();
  };

  const onTagChange = async (checkedTag: string): Promise<void> => {
    if ($recipesStore.filter.tags.includes(checkedTag)) {
      $recipesStore.filter.tags = $recipesStore.filter.tags.filter(
        (tag): boolean => tag !== checkedTag
      );
    } else {
      $recipesStore.filter.tags = [checkedTag, ...$recipesStore.filter.tags];
    }

    await recipesStore.fetchData();
  };

  const onMacroBarChange = (event: CustomEvent<MacroBarChange>): void => {
    $recipesStore.filter.macroRatio = event.detail;

    if (valuesTimeout) {
      clearTimeout(valuesTimeout);
    }

    valuesTimeout = setTimeout(recipesStore.fetchData, 1000);
  };

  const onAddRecipe = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.CREATE_EDIT_RECIPE;
  };

  onMount(fetchRecipeTags);

  export {isAddVisible};
</script>

<div class="flex gap-4">
  <Search
    placeholder={translate("SEARCH_RECIPES")}
    bind:value={$recipesStore.filter.search}
    on:input={recipesStore.search}/>
  {#if isAddVisible}
    <ButtonComponent myClasses="whitespace-nowrap" on:click={onAddRecipe}>
      {translate("ADD")}
      {translate("RECIPE").toLowerCase()}
    </ButtonComponent>
  {/if}
</div>

<div class="flex flex-col gap-4">

  <div class="flex items-start gap-2">
    <div class="flex flex-wrap items-center grow gap-2">
      {#if $recipesStore.filter.template !== undefined}
        <FilterTag
          on:removeFilter={onRemoveTemplateTag}
          title={translate($recipesStore.filter.template === 0 ? "MY" : "ALL")}
        />
      {/if}
      {#if $recipesStore.filter.macroRatio}
        <FilterTag
          on:removeFilter={onRemoveMacroTag}
          title={translate("BAR")}
        />
      {/if}
      {#each $recipesStore.filter.tags as filter}
        <FilterTag on:removeFilter={() => onRemoveTag(filter)} title={filter}/>
      {/each}
    </div>

    <ButtonComponent on:click={() => (isExpanded = !isExpanded)} isOutline>
      {translate("FILTER")}
      {#if isExpanded}
        <Svg name="up-caret" size={16} customColor="bg-slate-700 dark:bg-slate-200" />
      {:else}
        <Svg name="down-caret" size={16} customColor="bg-slate-700 dark:bg-slate-200" />
      {/if}
    </ButtonComponent>
  </div>

  {#if isExpanded}
    <div
      class="flex flex-col border-[1px] rounded-md border-slate-200 dark:border-zinc-700"
      in:slide
      out:slide
    >
      <DualSlider on:values={onMacroBarChange} />

      <div class="flex">
        <div class="p-2 basis-1/2 flex flex-col gap-2">
          {#each templateTags as templateTag}
            <RadioField
              id="templateTags"
              fontSize="xxs"
              value={templateTag}
              bind:group={templateGroup}
              on:change={onTemplateTagChange}
            >
              {translate(templateTag)}
            </RadioField>
          {/each}
        </div>

        <div class="border-l border-slate-200 dark:border-zinc-700"></div>

        <div class="p-2 basis-1/2 flex flex-col gap-2">
          {#each tags as tag}
            <CheckboxComponent
              label={tag}
              value={$recipesStore.filter.tags.includes(tag)}
              on:change={() => onTagChange(tag)}
            />
          {/each}
        </div>
      </div>
    </div>
  {/if}

  <div class="text-center text-xs">
    {translate("TOTAL_RECIPE_COUNT")}:
    <span class="font-bold">{$recipesStore.count}</span>
  </div>

</div>
