<script lang="ts">
  export let data: any;
</script>

<div class="flex-col" style="align-items: center">
  <video controls preload="none" playsinline class="video">
    <source src={data.src} type="video/mp4" />
    <source src={data.src} type="video/avi" />
    <source src={data.src} type="video/ogg" />
    <source src={data.src} type="video/webm" />
  </video>
</div>

<style>
</style>
