import {translate} from "./translate";
import {writable, type Writable} from "svelte/store";

const name = (value: string): string => {
  const {length} = value;

  if (length === 0) {
    return "FIELD_REQUIRED";
  } else if (length < 2) {
    return "FIELD_MINIMUM_2";
  } else if (length > 320) {
    return "FIELD_MAXIMUM_320";
  } else {
    return "";
  }
};

const description = (value: string): string => {
  const {length} = value;

  if (length > 5000) {
    return "FIELD_MAXIMUM_5000";
  } else {
    return "";
  }
};

const duration = (value: number): string => {
  if (value > 5000) {
    return "FIELD_MAXIMUM_5000";
  } else {
    return "";
  }
};

const validatorMap = {
  "NAME": name,
  "DESCRIPTION": description,
  "DURATION": duration
};

type Validator = keyof typeof validatorMap;

interface FormField {
  value: any;
  error: string;
  validator: Validator | "NONE";
}

type ObjectParam = [any, Validator | "NONE"];

// type X<K extends string> = Writable<{
//   field: Record<K, FormField>;
//   isDisabled: boolean;
//   isLoading: boolean;
// }>;

const formStoreCreate = <Key extends string>(obj: Record<Key, ObjectParam>) => {
  const keys = Object.keys(obj) as Array<keyof typeof obj>;

  const field = keys.reduce((acc: any, key) => {
    const [value, validator] = obj[key];
    const error = "";

    acc[key] = {value, error, validator};

    return acc;
  }, {}) as Record<Key, FormField>;

  const isDisabled = true;
  const isLoading = false;
  const {set, subscribe, update} = writable({field, isDisabled, isLoading});

  const validate = (key: Key): void => {
    update((store) => {
      const {value, validator} = store.field[key];

      if (validator === "NONE") {
        return store;
      }

      const error = validatorMap[validator](value);

      store.field[key].error = error ? translate(error) : error;

      store.isDisabled = keys.some(
        (key): boolean => store.field[key].error !== ""
      );

      return store;
    });
  };

  const setValue = (key: Key, value: any): void => {
    update((store) => {
      store.field[key].value = value;
      return store;
    });

    validate(key);
  };

  return {set, subscribe, update, validate, setValue}
};

export {formStoreCreate};
