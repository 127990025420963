<script lang="ts">
  import {onMount} from "svelte";
  import {formStoreCreate, patchWithJwt, postWithJwt, serverlessRoutes, translate} from "lib";
  import {dialogData, mealPlansStore, alertStore} from "stores";
  import {ButtonComponent, ImageInputComponent, InputField, TextareaField} from "ui";
  import {SetEditTypeComponent} from "components";
  import type {MealPlan, PartialItemMap} from "interfaces";

  let data: any;
  let imageUrl: string;
  let shouldDelete = false;
  let isUploading = false;

  const form = formStoreCreate({
    name: ["", "NAME"],
    foodType: ["", "NONE"],
    description: ["", "DESCRIPTION"],
    duration: [, "DURATION"],
    fileHash: ["", "NONE"]
  });

  const editMealPlan = async (): Promise<void> => {
    const partialMealPlanMap: PartialItemMap<MealPlan> = {
      [data.mealPlan.id]: {
        name: $form.field.name.value,
        foodType: $form.field.foodType.value,
        description: $form.field.description.value,
        duration: $form.field.duration.value
      }
    };

    const {data: rData, error} = await patchWithJwt(
      serverlessRoutes.MEAL_PLANS,
      {partialMealPlanMap}
    );

    if (error && !rData) {
      $form.isLoading = false;
      alertStore.show(translate("ERROR_EDITING_MEAL_PLAN"), "error");
      return console.error(error);
    }

    mealPlansStore.replace(rData.mealPlans);
    alertStore.show(translate("SUCCESSFULLY_EDITED_MEAL_PLAN"));

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const createMealPlan = async (): Promise<void> => {
    const partialMealPlan: Partial<MealPlan> = {
      name: $form.field.name.value,
      foodType: $form.field.foodType.value,
      description: $form.field.description.value,
      duration: $form.field.duration.value,
      fileHash: $form.field.fileHash.value || null,
      isDefault: data.client ? 0 : 1,
      isTemplate: 0,
      clientId: data.client ? data.client.id : null
    };

    const {data: rData, error} = await postWithJwt(
      serverlessRoutes.MEAL_PLANS,
      {partialMealPlan}
    );

    if (error && !rData) {
      $form.isLoading = false;
      alertStore.show(translate("ERROR_CREATING_MEAL_PLAN"), "error");
      return console.error(error);
    }

    mealPlansStore.add([rData.mealPlan]);
    alertStore.show(translate("SUCCESSFULLY_CREATED_MEAL_PLAN"));

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onSubmit = async (): Promise<void> => {
    $form.isLoading = true;

    if (data.mealPlan) {
      await editMealPlan();
    } else {
      await createMealPlan();
    }
  };

  onMount((): void => {
    if (data.mealPlan) {
      const {description, duration, name} = data.mealPlan;

      form.setValue("name", name);
      form.setValue("description", description);
      form.setValue("duration", duration);

      imageUrl = data.mealPlan.thumbnailUrl;
    }
  });

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">

  <div class="text-center font-semibold">
    {data.mealPlan ? translate("EDIT") : translate("CREATE")}
    {translate("MEAL_PLAN").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault="{onSubmit}">
    <InputField
      label="{translate("NAME")}"
      error="{$form.field.name.error}"
      bind:value="{$form.field.name.value}"
      on:input="{() => form.validate("name")}"
    />

    <ImageInputComponent
      requireSignedURLs={false}
      imageUrl={imageUrl}
      bind:value={$form.field.fileHash.value}
      bind:shouldDelete
      bind:isUploading
    />

    <SetEditTypeComponent
      entity="mealPlan"
      on:types={(e) => {$form.field.foodType.value = e.detail;}}
    />

    <TextareaField
      label="{translate("DESCRIPTION")}"
      error="{$form.field.description.error}"
      bind:value="{$form.field.description.value}"
      on:input="{() => form.validate("description")}"
    />

    <InputField
      type="number"
      label="{translate("DURATION_IN_DAYS")}"
      error="{$form.field.duration.error}"
      bind:value="{$form.field.duration.value}"
      on:input="{() => form.validate("duration")}"
    />

    <div class="text-xxs text-justify">
      <span class="text-primary-500">{translate("NOTE")}:</span>
      {translate("ADD_CREATE_MEAL_PLANS_NOTE")}
    </div>

    <div class="flex justify-center">
      <ButtonComponent
        type="submit"
        isLoading="{$form.isLoading}"
        disabled="{$form.isDisabled}"
      >
        {data.mealPlan ? translate("EDIT") : translate("CREATE")}
      </ButtonComponent>
    </div>
  </form>

</div>
